import { FC } from "react";

const DefaultAvatarIcon: FC = () => {
    return (
        <svg
            width="14"
            height="17"
            viewBox="0 0 14 17"
            fill="none"
            style={{marginTop: "auto", marginBottom:"auto"}}
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="7" cy="5" r="5" fill="#F6F6F6" />
            <path
                d="M2.5 13H11.5C12.3284 13 13 13.6716 13 14.5C13 15.3284 12.3284 16 11.5 16H2.5C1.67157 16 1 15.3284 1 14.5C1 13.6716 1.67157 13 2.5 13Z"
                fill="#F6F6F6"
                stroke="#F6F6F6"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default DefaultAvatarIcon;
