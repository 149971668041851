import {
    Box,
    Card,
    CircularProgress,
    Grid,
    MenuItem,
    Pagination,
    Select,
    SelectChangeEvent,
    Stack,
    Typography,
} from "@mui/material";
import moment, { Moment } from "moment-timezone";
import { CSSProperties, ReactNode, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { EventPublicInfoModel } from "../../data/Models/EventPublicInfoModel";
import { RegionModel } from "../../data/Models/RegionModel";
import { searchEvents } from "../../data/Repositories/EventRepository";
import { getRegions } from "../../data/Repositories/LocationsRepository";
import { theme } from "../../theme/theme";
import { FilterByDate, SortBy } from "../../types/models/enums";
import FilterByDropdown from "../Controls/FilterByDropdown/FilterByDropdown";
import SortByDropdown from "../Controls/SortByDropdown/SortByDropdown";
import DateFilter from "./DateFilter/DateFilter";
import EventCard from "./EventCard/EventCard";
import SearchBar from "./SearchBar/SearchBar";
import { useCookies } from "react-cookie";

const invertedCard: CSSProperties = {
    backgroundColor: "#080808",
    color: theme.palette.nrbyColors.primaryColor,
    overflow: "visible",
};

const invertedCardContent: CSSProperties = {
    maxWidth: "1100px",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#080808",
    color: theme.palette.nrbyColors.primaryColor,
    marginTop: "90px",
    marginBottom: "90px",
    overflow: "visible",
};

const BrowseEvents = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [regions, setRegions] = useState<RegionModel[]>([]);
    const [regionId, setRegionId] = useState("");
    const [
        defaultRegionIdCookie,
        setDefaultRegionIdCookie,
        removeDefaultRegionIdCookie,
    ] = useCookies(["defaultRegion"]);

    const [timeZoneFilter, setTimeZoneFilter] = useState(0);
    const [eventsData, setEventsData] = useState<EventPublicInfoModel[]>();
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [categoryId, setCategoryId] = useState<string>("");
    const [sortBy, setSortBy] = useState<SortBy>(SortBy.Date);
    const [filterByDate, setFilterByDate] = useState<FilterByDate>(
        FilterByDate.NoFilter
    );
    const [selectedDate, setSelectedDate] = useState<Moment>(moment());
    const [pagesCount, setPagesCount] = useState(1);
    const [currentPage, setCurrentPage] = useState<number>(0);

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        let startRegionId = searchParams.get("regionId") ?? "";
        if (!startRegionId || startRegionId === "") {
            let defaultRegionId = defaultRegionIdCookie.defaultRegion;
            if (defaultRegionId) startRegionId = defaultRegionId;
        }
        setRegionId(startRegionId);

        let startSearchTerm = searchParams.get("searchTerm") ?? "";
        setSearchTerm(startSearchTerm);
        let startCategoryId = searchParams.get("categoryId") ?? "";
        setCategoryId(startCategoryId);

        let startSortBy: SortBy =
            SortBy[searchParams.get("sortBy") as keyof typeof SortBy] ??
            SortBy.Date;
        setSortBy(startSortBy);

        let startFilterByDate: FilterByDate =
            FilterByDate[
                searchParams.get("date") as keyof typeof FilterByDate
            ] ?? FilterByDate.NoFilter;
        setFilterByDate(startFilterByDate);
        if (startFilterByDate === FilterByDate.ExactDay) {
            let startSelectedDate = moment(searchParams.get("exactDate"));
            setSelectedDate(startSelectedDate);
        } else {
            let now = moment();
            let today = moment([now.year(), now.month(), now.date()]);
            setSelectedDate(today);
        }

        let startPage: number = searchParams.get("page")
            ? Number(searchParams.get("page"))
            : 1;
        setCurrentPage(startPage);

        getRegions().then((response) => {
            if (response && response.data) {
                setRegions(response.data);
                TriggerRegionChange(startRegionId ?? "");
            }
        });
    }, []);

    useEffect(() => {
        if (regions.length > 0) {
            LoadEvents(
                regionId,
                searchTerm,
                categoryId,
                sortBy,
                filterByDate,
                timeZoneFilter,
                currentPage
            );
        }
    }, [
        regions,
        regionId,
        searchTerm,
        categoryId,
        sortBy,
        filterByDate,
        timeZoneFilter,
        currentPage,
        selectedDate,
    ]);

    const HandleRegionChange = (
        event: SelectChangeEvent<unknown>,
        child: ReactNode
    ) => {
        var newRegionId = event.target.value as string;
        TriggerRegionChange(newRegionId);
        if (newRegionId && newRegionId !== "")
            setDefaultRegionIdCookie("defaultRegion", newRegionId);
        else removeDefaultRegionIdCookie("defaultRegion");
    };

    const TriggerRegionChange = (newRegionId: string) => {
        var region = regions.filter((r) => r.id === newRegionId)[0];

        setRegionId(newRegionId);
        searchParams.set("regionId", newRegionId);
        setSearchParams(searchParams);

        var timeZoneOffset = region?.timezoneOffset;
        if (!timeZoneOffset) {
            //timeZoneOffset = - (new Date()).getTimezoneOffset() / 60;
            timeZoneOffset = moment().utcOffset() / 60;
        }
        setTimeZoneFilter(timeZoneOffset);

        setCurrentPage(1);
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const handleFilterChange = (selectedValue: string) => {
        setCategoryId(selectedValue);
        searchParams.set("categoryId", selectedValue);
        setSearchParams(searchParams);
    };

    const HandleSortByChange = (
        event: SelectChangeEvent<unknown>,
        child: ReactNode
    ) => {
        setSortBy(event.target.value as SortBy);
        searchParams.set("sortBy", (event.target.value as SortBy).toString());
        setSearchParams(searchParams);
    };

    const HandleSearchTermChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setSearchTerm(event.target.value as string);
        searchParams.set("searchTerm", event.target.value as string);
        setSearchParams(searchParams);
    };

    const HandleFilterByDateChange = (
        selectedFilter: FilterByDate,
        exactDate: Date | undefined
    ) => {
        setFilterByDate(selectedFilter);
        searchParams.set("date", selectedFilter.toString());
        if (exactDate) {
            setSelectedDate(moment(exactDate));
            searchParams.set(
                "exactDate",
                moment(exactDate).format("YYYY-MM-DD")
            );
        }
        setSearchParams(searchParams);

        setCurrentPage(1);
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const HandlePageChange = (
        event: React.ChangeEvent<unknown>,
        page: number
    ) => {
        setCurrentPage(page);
        searchParams.set("page", page.toString());
        setSearchParams(searchParams);
    };

    const LoadEvents = (
        regionId: string,
        searchTerm: string,
        categoryId: string,
        sortBy: SortBy,
        filterByDate: FilterByDate,
        timeZoneFilter: number,
        page: number
    ) => {
        let dateRange = GetDateRange(filterByDate, timeZoneFilter);
        setIsLoading(true);
        searchEvents(
            regionId,
            searchTerm,
            categoryId,
            sortBy,
            dateRange[0],
            dateRange[1],
            page
        ).then((response) => {
            if (response && response.data && response.data.success) {
                setEventsData(response.data.events);
                setPagesCount(response.data.totalPages);
                setCurrentPage(response.data.page);
                setIsLoading(false);
            }
        });
    };

    const GetDateRange = (filter: FilterByDate, timeZoneFilter: number) => {
        let now = moment();
        let minDate = moment([now.year(), now.month(), now.date()]);
        minDate.utcOffset(timeZoneFilter, true);
        let maxDate: Moment | undefined = undefined;

        let today = moment([now.year(), now.month(), now.date()]);
        today.utcOffset(timeZoneFilter, true);

        switch (filter) {
            case FilterByDate.ExactDay:
                minDate = moment(selectedDate);
                minDate.utcOffset(timeZoneFilter, true);
                maxDate = moment(selectedDate);
                maxDate.utcOffset(timeZoneFilter, true);
                maxDate.add(1, "d");
                break;

            case FilterByDate.NoFilter:
                minDate = moment(today);
                break;

            case FilterByDate.Today:
                minDate = moment(today);

                maxDate = moment(today);
                maxDate.add(1, "d");
                break;

            case FilterByDate.Tomorrow:
                minDate = moment(today);
                minDate.add(1, "d");

                maxDate = moment(today);
                maxDate.add(2, "d");
                break;

            case FilterByDate.NextWeek:
                minDate = moment(today);
                minDate.day(7);

                maxDate = moment(today);
                maxDate.day(14);
                break;

            case FilterByDate.ThisWeekend:
                if (today.day() === 0 || today.day() === 6) {
                    minDate = moment(today);

                    maxDate = moment(today);
                    maxDate.day(1);
                } else {
                    minDate = moment(today);
                    minDate.day(5);

                    maxDate = moment(today);
                    maxDate.day(8);
                }
                break;
        }

        var minDateStr = minDate ? moment(minDate).utc().format() : "";
        var maxDateStr = maxDate ? moment(maxDate).utc().format() : "";
        return [minDateStr, maxDateStr];
    };

    return (
        <Card style={invertedCard} elevation={0} sx={{ padding: { xs: 2 } }}>
            <Card style={invertedCardContent} elevation={0}>
                <Typography variant="header02" color="primary" align="center">
                    Browse All Events
                </Typography>

                <Typography
                    variant="body01"
                    ml={4}
                    mt={4}
                    mb={4}
                    component="div"
                    style={{
                        maxWidth: "80%",
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}
                >
                    You can search by event name, location, address, date or
                    time to easily filter through the extensive list of events
                    we offer. Simply type in your search criteria and our system
                    will display a list of results that match your search,
                    allowing you to quickly and easily find events that meet
                    your preferences.
                </Typography>
                <Grid
                    container
                    spacing={2}
                    sx={{
                        mt: { sm: "40px" },
                        mb: { sm: "40px" },
                        padding: { xs: 2 },
                    }}
                    alignItems="center"
                >
                    <Grid item xs={12} md={3}>
                        <Select
                            displayEmpty
                            value={regionId}
                            id="select-location-dd"
                            style={{
                                marginTop: "auto",
                                marginBottom: "auto",
                                paddingRight: "0",
                            }}
                            variant="standard"
                            onChange={HandleRegionChange}
                        >
                            <MenuItem value="">Select Location</MenuItem>
                            {regions.map((item: RegionModel) => (
                                <MenuItem value={item.id} key={item.id}>
                                    {item.value}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <SearchBar
                            fullWidth
                            value={searchTerm}
                            onChange={HandleSearchTermChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <SortByDropdown onChange={HandleSortByChange} />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <FilterByDropdown onFilterChange={handleFilterChange} />
                    </Grid>
                </Grid>
                <Typography
                    variant="subheader01"
                    component="div"
                    sx={{
                        mb: { xs: 4 },
                    }}
                >
                    Events
                </Typography>
                <Box mb={3}>
                    <DateFilter
                        onFilterChange={HandleFilterByDateChange}
                        value={filterByDate}
                        initialDate={selectedDate}
                    />
                </Box>
                <Grid container spacing={2} justifyContent="center">
                    {!isLoading &&
                        eventsData &&
                        eventsData.map((event: EventPublicInfoModel) => (
                            <EventCard event={event} key={event.id} />
                        ))}
                    {isLoading && (
                        <Grid item xs={12} textAlign="center">
                            <CircularProgress color="primary" />
                        </Grid>
                    )}
                </Grid>
                {pagesCount > 0 && (
                    <Stack
                        mt={2}
                        justifyContent="right"
                        direction="row"
                        alignItems="center"
                    >
                        <Typography
                            variant="subheader02"
                            style={{
                                color: theme.palette.nrbyColors.tangerine,
                            }}
                        >
                            Page
                        </Typography>

                        <Pagination
                            count={pagesCount}
                            variant="text"
                            color="primary"
                            page={currentPage}
                            onChange={HandlePageChange}
                        />
                    </Stack>
                )}
            </Card>
        </Card>
    );
};

export default BrowseEvents;
