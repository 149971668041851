import { Components } from '@mui/material/styles/components';

export const MuiCard: Components = {
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: "0",
        backgroundImage: 'none',
      },
    },
  },
};