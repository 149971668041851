import { BaseTextFieldProps, TextField, TextFieldProps } from "@mui/material";

const SearchBar = (props: TextFieldProps) => {
    return (
        <TextField
            fullWidth
            id="outlined-basic"
            placeholder="Search for Events"
            variant="standard"
            {...props}
        />
    );
};

export default SearchBar;
