import {
    Box, Card, CardMedia, Typography
} from "@mui/material";
import { CSSProperties } from "react";
import TopImage from "../assets/images/homepage-top.jpg";
import { MainLayout } from "../components/Layouts/MainLayout";


const mainContent: CSSProperties = {
    marginTop: "80px",
    maxWidth: "1150px",
    marginLeft: "auto",
    marginRight: "auto",
};

const PrivacyPage = () => {
    return (
        <MainLayout>
            <Card>
                <Box style={{ position: "relative" }}>
                    {/* <CardMedia style={{}} component="img" image={TopImage} /> */}
                    <Box style={mainContent}>
                        <div>
                    Nrby.io Privacy Policy
 
 Last Updated: October 25, 2022
 
 This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
 
 We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.
 
 Interpretation and Definitions
 
 Interpretation
 
 The words of which the initial letter is capitalized have meanings defined under the following conditions.
 
 The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
 
 Definitions
 
 For the purposes of this Privacy Policy:
 
  
 
 ·    You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable. Under GDPR (General Data Protection Regulation), You can be referred to as the Data Subject or as the User as you are the individual using the Service.
 
 ·    Company (referred to as either “the Company”, “We”, “Us” or “Our” in this Agreement) refers to NRBY, INC., 1161 Wayzata BLVD E. #172, Wayzata, MN 55391. For the purpose of the GDPR, the Company is the Data Controller.
 
 ·    Application means the software program provided by the Company downloaded by You on any electronic device, named NRBY
 
 ·    Affiliate means an entity that controls, is controlled by or is under common control with a party, where “control” means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.
 
 ·    Account means a unique account created for You to access our Service or parts of our Service.
 
 ·    Website refers to nrby.io, accessible from 
 
 ·    Service refers to the Application or the Website or both.
 
 ·    Country refers to: Minnesota, United States
 
 ·    Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used. For the purpose of the GDPR, Service Providers are considered Data Processors.
 
 ·    Third-party Social Media Service refers to any website or any social network website through which a User can log in or create an account to use the Service.
 
 ·    Personal Data is any information that relates to an identified or identifiable individual. For the purposes for GDPR, Personal Data means any information relating to You such as a name, an identification number, location data, online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity. For the purposes of the CCPA, Personal Data means any information that identifies, relates to, describes or is capable of being associated with, or could reasonably be linked, directly or indirectly, with You.
 
 ·    Cookies are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.
 
 ·    Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.
 
 ·    Usage Data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
 
 ·    Data Controller, for the purposes of the GDPR (General Data Protection Regulation), refers to the Company as the legal person which alone or jointly with others determines the purposes and means of the processing of Personal Data.
 
 ·    Do Not Track (DNT) is a concept that has been promoted by US regulatory authorities, in particular the U.S. Federal Trade Commission (FTC), for the Internet industry to develop and implement a mechanism for allowing internet users to control the tracking of their online activities across websites.
 
 ·    Business, for the purpose of the CCPA (California Consumer Privacy Act), refers to the Company as the legal entity that collects Consumers’ personal information and determines the purposes and means of the processing of Consumers’ personal information, or on behalf of which such information is collected and that alone, or jointly with others, determines the purposes and means of the processing of consumers’ personal information, that does business in the State of California.
 
 ·    Consumer, for the purpose of the CCPA (California Consumer Privacy Act), means a natural person who is a California resident. A resident, as defined in the law, includes (1) every individual who is in the USA for other than a temporary or transitory purpose, and (2) every individual who is domiciled in the USA who is outside the USA for a temporary or transitory purpose.
 
 ·    Sale, for the purpose of the CCPA (California Consumer Privacy Act), means selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means, a Consumer’s Personal information to another business or a third party for monetary or other valuable consideration.
 
  
 
 Collecting and Using Your Personal Data
 
  
 
 Types of Data Collected
 
  
 
 Personal Data
 
 While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. We use information you provide us to help identify you regardless of login method. Personally identifiable information may include, but is not limited to:
 
 ·    Email address
 
 ·    First name and last name
 
 ·    Phone number
 
 ·    Address, State, Province, ZIP/Postal code, City
 
 ·    Usage Data
 
 ·    E-mail communications you send to us
 
 ·    Information you provide to us when creating an account, such as Facebook name and profile picture
 
 ·    Information you provide while Using the Application, such as location information, or credit card information if making a purchase
 
 Phone Number
 
 Nrby.io collects, transmits, syncs, and stores phone number to enable basic app features including account syncing users’ personal event preferences so that custom recommendations are provided regardless of external login method. Your phone number can be removed by deleting your account. You may also change your phone number in app at any time. 
 
 Phone number and other primary account information is shared to the Nrby.io server hosted on Microsoft Azure. We collect and use this information to help identify friends using the app and to facilitate interactions around events.
 
  
 
 Usage Data
 
 Usage Data is collected automatically when using the Service.
 
  
 
 Usage Data may include information such as Your Device’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
 
  
 
 When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.
 
  
 
 We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.
 
  
 
 Information Collected while Using the Application
 
 While using Our Application, in order to provide features of Our Application, We may collect, with your prior permission:
 
 ·    Information regarding your location
 
  
 
 If you turn on location tracking for our Services, we collect and use your device location information as you use our Application and Service, as well as when you are not directly interacting with the Services. The location information helps your usage of the Services in relation to events happening near you. Our Services include finding events, attending events, and creating groups with your friends to attend events which can require your location. Your location is required to find nearby events to you. Your background location data is used in the “Groups” feature, which allows you to attend events with friends. Your background location data is used in the “Groups” feature to inform your friends in that specific group when you arrive at the event. We identify your location using a variety of technologies, including GPS, WiFi points you are accessing the Service through, and mobile/cell tower triangulation.
 
  
 
 We also collect certain information about your mobile device (including device identifiers, device OS, model, configuration, and settings), to operate the Services for you and to personalize your user experience.
 
  
 
 We further use the information above in order to provide technical and customer support to you.
 
  
 
 Tracking Technologies and Cookies
 
 We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service.
 
  
 
 You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service.
 
  
 
 Cookies can be “Persistent” or “Session” Cookies. Persistent Cookies remain on your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web browser.
 
  
 
 We use both session and persistent Cookies for the purposes set out below:
 
  
 
 ·    Necessary / Essential Cookies
 
 Type: Session Cookies
 
 Administered by: Us
 
 Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.
 
 ·    Cookies Policy / Notice Acceptance Cookies
 
 Type: Persistent Cookies
 
 Administered by: Us
 
 Purpose: These Cookies identify if users have accepted the use of cookies on the Website.
 
 ·    Functionality Cookies
 
 Type: Persistent Cookies
 
 Administered by: Us
 
 Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.
 
 ·    Tracking and Performance Cookies
 
 Type: Persistent Cookies
 
 Administered by: Third-Parties
 
 Purpose: These Cookies are used to track information about traffic to the Website and how users use the Website. The information gathered via these Cookies may directly or indirectly identify you as an individual visitor. This is because the information collected is typically linked to a pseudonymous identifier associated with the device you use to access the Website. We may also use these Cookies to test new advertisements, pages, features or new functionality of the Website to see how our users react to them.
 
 ·    Targeting and Advertising Cookies
 
 Type: Persistent Cookies
 
 Administered by: Third-Parties
 
 Purpose: These Cookies track your browsing habits to enable Us to show advertising which is more likely to be of interest to You. These Cookies use information about your browsing history to group You with other users who have similar interests. Based on that information, and with Our permission, third party advertisers can place Cookies to enable them to show adverts which We think will be relevant to your interests while You are on third party websites.
 
  
 
 For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy.
 
  
 
 Use of Your Personal Data
 
  
 
 The Company may use Personal Data for the following purposes:
 
 ·    To provide and maintain our Service, including to monitor the usage of our Service.
 
 ·    To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.
 
 ·    For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.
 
 ·    To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application’s push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.
 
 ·    To provide You with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.
 
 ·    To manage Your requests: To attend and manage Your requests to Us.
 
 We may share your personal information in the following situations:
 
 ·    With Service Providers: We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to show advertisements to You to help support and maintain Our Service, to contact You, to advertise on third party websites to You after You visited our Service or for payment processing.
 
 ·    For Business transfers: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of our business to another company.
 
 ·    With Affiliates: We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.
 
 ·    With Business partners: We may share Your information with Our business partners to offer You certain products, services or promotions.
 
 ·    With other users: when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see You name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.
 
  
 
 Retention of Your Personal Data
 
 The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
 
  
 
 The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
 
  
 
 Transfer of Your Personal Data
 
 Your information, including Personal Data, is processed at the Company’s operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.
 
  
 
 Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.
 
  
 
 The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.
 
  
 
 Disclosure of Your Personal Data
 
  
 
 Business Transactions
 
 If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.
 
  
 
 Law enforcement
 
 Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
 
  
 
 Other legal requirements
 
 The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
 
 ·    Comply with a legal obligation
 
 ·    Protect and defend the rights or property of the Company
 
 ·    Prevent or investigate possible wrongdoing in connection with the Service
 
 ·    Protect the personal safety of Users of the Service or the public
 
 ·    Protect against legal liability
 
  
 
 Security of Your Personal Data
 
 The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.
 
  
 
 Detailed Information on the Processing of Your Personal Data
 
 Service Providers have access to Your Personal Data only to perform their tasks on Our behalf and are obligated not to disclose or use it for any other purpose.
 
  
 
 Analytics
 
 We may use third-party Service providers to monitor and analyze the use of our Service.
 
 ·    Google Analytics
 
 Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network.
 
 For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: https://policies.google.com/privacy?hl=en
 
  
 
 Advertising
 
  
 
 We may use Service providers to show advertisements to You to help support and maintain Our Service.
 
 ·    Google AdSense & DoubleClick Cookie
 
 Google, as a third party vendor, uses cookies to serve ads on our Service. Google’s use of the DoubleClick cookie enables it and its partners to serve ads to our users based on their visit to our Service or other websites on the Internet.
 
 You may opt out of the use of the DoubleClick Cookie for interest-based advertising by visiting the Google Ads Settings web page: http://www.google.com/ads/preferences/
 
 ·    AdMob by Google
 
 AdMob by Google is provided by Google Inc.
 
 You can opt-out from the AdMob by Google service by following the instructions described by Google: https://support.google.com/ads/answer/2662922?hl=en
 
 For more information on how Google uses the collected information, please visit the “How Google uses data when you use our partners’ sites or app” page: https://policies.google.com/technologies/partner-sites or visit the Privacy Policy of Google: https://policies.google.com/privacy
 
  
 
 Email Marketing
 
 We may use Your Personal Data to contact You with newsletters, marketing or promotional materials and other information that may be of interest to You. You may opt-out of receiving any, or all, of these communications from Us by following the unsubscribe link or instructions provided in any email We send or by contacting Us.
 
 We may use Email Marketing Service Providers to manage and send emails to You.
 
  
 
 Behavioral Remarketing
 
 The Company uses remarketing services to advertise on third party websites to You after You visited our Service. We and Our third-party vendors use cookies to inform, optimize and serve ads based on Your past visits to our Service.
 
 ·    Twitter
 
 Twitter remarketing service is provided by Twitter Inc.
 
 You can opt-out from Twitter’s interest-based ads by following their instructions: https://support.twitter.com/articles/20170405
 
 You can learn more about the privacy practices and policies of Twitter by visiting their Privacy Policy page: https://twitter.com/privacy
 
 ·    Facebook
 
 Facebook remarketing service is provided by Facebook Inc.
 
 You can learn more about interest-based advertising from Facebook by visiting this page: https://www.facebook.com/help/164968693837950
 
 To opt-out from Facebook’s interest-based ads, follow these instructions from Facebook: https://www.facebook.com/help/568137493302217
 
 Facebook adheres to the Self-Regulatory Principles for Online Behavioural Advertising established by the Digital Advertising Alliance. You can also opt-out from Facebook and other participating companies through the Digital Advertising Alliance in the USA http://www.aboutads.info/choices/, the Digital Advertising Alliance of Canada in Canada http://youradchoices.ca/ or the European Interactive Digital Advertising Alliance in Europe http://www.youronlinechoices.eu/, or opt-out using your mobile device settings.
 
 For more information on the privacy practices of Facebook, please visit Facebook’s Data Policy: https://www.facebook.com/privacy/explanation
 
  
 
 Payments
 
 We may provide paid products and/or services within the Service. In that case, we may use third-party services for payment processing (e.g. payment processors).
 
 We will not store or collect Your payment card details. That information is provided directly to Our third-party payment processors whose use of Your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.
 
 ·    Stripe
 
 Their Privacy Policy can be viewed at https://stripe.com/us/privacy
 
  
 
 Usage, Performance and Miscellaneous
 
 We may use third-party Service Providers to provide better improvement of our Service.
 
 ·    Google Places
 
 Google Places is a service that returns information about places using HTTP requests. It is operated by Google.
 
 Google Places service may collect information from You and from Your Device for security purposes.
 
 The information gathered by Google Places is held in accordance with the Privacy Policy of Google: https://www.google.com/intl/en/policies/privacy/
 
  
 
 GDPR Privacy
 
  
 
 Legal Basis for Processing Personal Data under GDPR
 
 We may process Personal Data under the following conditions:
 
 ·    Consent: You have given Your consent for processing Personal Data for one or more specific purposes.
 
 ·    Performance of a contract: Provision of Personal Data is necessary for the performance of an agreement with You and/or for any pre-contractual obligations thereof.
 
 ·    Legal obligations: Processing Personal Data is necessary for compliance with a legal obligation to which the Company is subject.
 
 ·    Vital interests: Processing Personal Data is necessary in order to protect Your vital interests or of another natural person.
 
 ·    Public interests: Processing Personal Data is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Company.
 
 ·    Legitimate interests: Processing Personal Data is necessary for the purposes of the legitimate interests pursued by the Company.
 
  
 
 In any case, the Company will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.
 
  
 
 Your Rights under the GDPR
 
 The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can exercise Your rights.
 
 You have the right under this Privacy Policy, and by law if You are within the EU, to:
 
 ·    Request access to Your Personal Data. The right to access, update or delete the information We have on You. Whenever made possible, you can access, update or request deletion of Your Personal Data directly within Your account settings section. If you are unable to perform these actions yourself, please contact Us to assist You. This also enables You to receive a copy of the Personal Data We hold about You.
 
 ·    Request correction of the Personal Data that We hold about You. You have the right to to have any incomplete or inaccurate information We hold about You corrected.
 
 ·    Object to processing of Your Personal Data. This right exists where We are relying on a legitimate interest as the legal basis for Our processing and there is something about Your particular situation, which makes You want to object to our processing of Your Personal Data on this ground. You also have the right to object where We are processing Your Personal Data for direct marketing purposes.
 
 ·    Request erasure of Your Personal Data. You have the right to ask Us to delete or remove Personal Data when there is no good reason for Us to continue processing it.
 
 ·    Request the transfer of Your Personal Data. We will provide to You, or to a third-party You have chosen, Your Personal Data in a structured, commonly used, machine-readable format. Please note that this right only applies to automated information which You initially provided consent for Us to use or where We used the information to perform a contract with You.
 
 ·    Withdraw Your consent. You have the right to withdraw Your consent on using your Personal Data. If You withdraw Your consent, We may not be able to provide You with access to certain specific functionalities of the Service.
 
  
 
 Exercising of Your GDPR Data Protection Rights
 
 You may exercise Your rights of access, rectification, cancellation and opposition by contacting Us. Please note that we may ask You to verify Your identity before responding to such requests. If You make a request, We will try our best to respond to You as soon as possible.
 
  
 
 You have the right to complain to a Data Protection Authority about Our collection and use of Your Personal Data. For more information, if You are in the European Economic Area (EEA), please contact Your local data protection authority in the EEA.
 
  
 
 CCPA Privacy
 
  
 
 Your Rights under the CCPA
 
  
 
 Under this Privacy Policy, and by law if You are a resident of California, You have the following rights:
 
 ·    The right to notice. You must be properly notified which categories of Personal Data are being collected and the purposes for which the Personal Data is being used.
 
 ·    The right to access / the right to request. The CCPA permits You to request and obtain from the Company information regarding the disclosure of Your Personal Data that has been collected in the past 12 months by the Company or its subsidiaries to a third-party for the third party’s direct marketing purposes.
 
 ·    The right to say no to the sale of Personal Data. You also have the right to ask the Company not to sell Your Personal Data to third parties. You can submit such a request by visiting our “Do Not Sell My Personal Information” section or web page.
 
 ·    The right to know about Your Personal Data. You have the right to request and obtain from the Company information regarding the disclosure of the following:
 
 oThe categories of Personal Data collected
 
 oThe sources from which the Personal Data was collected
 
 oThe business or commercial purpose for collecting or selling the Personal Data
 
 oCategories of third parties with whom We share Personal Data
 
 oThe specific pieces of Personal Data we collected about You
 
 ·    The right to delete Personal Data. You also have the right to request the deletion of Your Personal Data that have been collected in the past 12 months.
 
 ·    The right not to be discriminated against. You have the right not to be discriminated against for exercising any of Your Consumer’s rights, including by:
 
 oDenying goods or services to You
 
 oCharging different prices or rates for goods or services, including the use of discounts or other benefits or imposing penalties
 
 oProviding a different level or quality of goods or services to You
 
 oSuggesting that You will receive a different price or rate for goods or services or a different level or quality of goods or services.
 
  
 
 Exercising Your CCPA Data Protection Rights
 
 In order to exercise any of Your rights under the CCPA, and if you are a California resident, You can email or call us or visit our “Do Not Sell My Personal Information” section or web page.
 
  
 
 The Company will disclose and deliver the required information free of charge within 45 days of receiving Your verifiable request. The time period to provide the required information may be extended once by an additional 45 days when reasonable necessary and with prior notice.
 
  
 
 Do Not Sell My Personal Information
 
 We do not sell personal information. However, the Service Providers we partner with (for example, our advertising partners) may use technology on the Service that “sells” personal information as defined by the CCPA law.
 
  
 
 If you wish to opt out of the use of your personal information for interest-based advertising purposes and these potential sales as defined under CCPA law, you may do so by following the instructions below.
 
  
 
 Please note that any opt out is specific to the browser You use. You may need to opt out on every browser that you use.
 
  
 
 Website
 
 You can opt out of receiving ads that are personalized as served by our Service Providers by following our instructions presented on the Service:
 
 ·    From Our “Cookie Consent” notice banner
 
 ·    Or from Our “CCPA Opt-out” notice banner
 
 ·    Or from Our “Do Not Sell My Personal Information” notice banner
 
 ·    Or from Our “Do Not Sell My Personal Information” link
 
  
 
 The opt out will place a cookie on Your computer that is unique to the browser You use to opt out. If you change browsers or delete the cookies saved by your browser, you will need to opt out again.
 
 Mobile Devices
 
 Your mobile device may give you the ability to opt out of the use of information about the apps you use in order to serve you ads that are targeted to your interests:
 
 ·    “Opt out of Interest-Based Ads” or “Opt out of Ads Personalization” on Android devices
 
 ·    “Limit Ad Tracking” on iOS devices
 
  
 
 You can also stop the collection of location information from Your mobile device by changing the preferences on your mobile device.
 
  
 
 “Do Not Track” Policy as Required by California Online Privacy Protection Act (CalOPPA)
 
 Our Service does not respond to Do Not Track signals.
 
  
 
 However, some third party websites do keep track of You browsing activities. If You are visiting such websites, You can set Your preferences in Your web browser to inform websites that You do not want to be tracked. You can enable or disable DNT by visiting the preferences or settings page of Your web browser.
 
  
 
 Children’s Privacy
 
 Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.
 
  
 
 We also may limit how We collect, use, and store some of the information of Users between 13 and 18 years old. In some cases, this means We will be unable to provide certain functionality of the Service to these users.
 
  
 
 If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent’s consent before 
 
 We collect and use that information.
 
  
 
 Links to Other Websites
 
 Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party’s site. We strongly advise You to review the Privacy Policy of every site You visit.
 
  
 
 We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
 
  
 
 Changes to this Privacy Policy
 
 We may update our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.
 
  
 
 We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the “Last updated” date at the top of this Privacy Policy.
 
  
 
 You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
 
  
 
 Contact Us
 
 If you have any questions about this Privacy & Cookie Policy, You can contact us:
 
 ·    By email: support@nrby.io
 
 ·    By mail: 1161 Wayzata Blvd E., Suite 172, Wayzata, MN 5539
 </div>
                    </Box>
                </Box>
            </Card>
        </MainLayout>
    );
};

export default PrivacyPage;
