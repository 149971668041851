import { createTheme, Theme } from "@mui/material";
import {
    CustomPalette,
    CustomTypographyProps,
    CustomTypographyVariants,
} from "../types/mui";
import { MuiCard } from "./MuiCard";
import { MuiAppBar } from "./MuiAppBar";
import { MuiSelect } from "./MuiSelect";
import { typographyOverrides } from "./typography";
import { darkColors } from "./colors";
import { MuiButton } from "./MuiButton";
import { MuiTextField } from "./MuiTextField";
import { MuiPagination } from "./MuiPagination";
import { MuiChip } from "./MuiChip";

export const theme: Theme = createTheme({
    palette: {
        mode: "dark",
        background: {
            default: "#181818",
        },
        text: {
            primary: "#ffffff",
        },
        primary: {
            main: darkColors.nrbyColors.white,
            dark: darkColors.nrbyColors.white,
        },
        secondary: {
            main: darkColors.nrbyColors.black,
            dark: darkColors.nrbyColors.black,
        },
        tangerine: {
            main: darkColors.nrbyColors.tangerine,
            dark: darkColors.nrbyColors.tangerine,
            light: darkColors.nrbyColors.tangerine,
            contrastText: "#fff",
        },
        ...darkColors,
    },
    components: {
        ...MuiCard,
        ...MuiAppBar,
        ...MuiButton,
        ...MuiSelect,
        ...MuiTextField,
        ...MuiPagination,
        ...MuiChip,
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    bigHeader: "h1",
                    header01: "h1",
                    header02: "h2",
                    headliner: "div",
                    subheader01: "h3",
                    subheader02: "h4",
                    subheader03: "h5",
                    largeBody01: "span",
                    body01: "span",
                    body02: "span",
                    smallCaption: "div",
                },
            },
        },
    },
    typography: typographyOverrides(),
});

declare module "@mui/material/styles" {
    interface TypographyVariants extends CustomTypographyVariants {}

    interface TypographyVariantsOptions extends CustomTypographyVariants {}

    interface Palette extends CustomPalette {
        tangerine: Palette["primary"];
    }
    interface PaletteOptions extends CustomPalette {
        tangerine?: Palette["primary"];
    }
}

declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides extends CustomTypographyProps {}
}

declare module "@mui/material/Button" {
    interface ButtonPropsVariantOverrides {
        bordered: true;
        inverted: true;
        large: true;
    }
    interface ButtonPropsColorOverrides {
        tangerine: true;
    }
}

declare module "@mui/material/Pagination" {
    interface PaginationPropsVariantOverrides {
        inverted: true;
    }
}
