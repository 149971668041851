import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { CSSProperties } from "react";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import { Container, CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "../../theme/theme";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import styles from "./MainLayout.module.scss";

interface Props {
    children: React.ReactNode;
}

const mainContainerStyles: CSSProperties = {
    backgroundColor: theme.palette.nrbyColors.primaryBgColor,
};

const queryClient = new QueryClient({});
const MainLayout = (props: Props) => (
    <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Container
                    maxWidth="xl"
                    disableGutters
                    className={styles.mainContainer}
                    style={mainContainerStyles}
                >
                    <Header />
                    {props.children}
                    <Footer />
                </Container>
            </ThemeProvider>
        </QueryClientProvider>
    </ErrorBoundary>
);

export { MainLayout };
