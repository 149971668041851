import { Box, Stack, Typography } from "@mui/material";
import React, { CSSProperties, useEffect, useState } from "react";
import logoImage from "../../../assets/images/logo-nrby.png";
import { EventPublicDetailsInfoModel } from "../../../data/Models/EventPublicDetailsInfoModel";
import { PreviewOrderEventTicketsRequest } from "../../../data/Models/Requests/PreviewOrderEventTicketsRequest";
import { GetTicketOptionsResponse } from "../../../data/Models/Responses/GetTicketOptionsResponse";
import { OrderEventTicketsResponse } from "../../../data/Models/Responses/OrderEventTicketsResponse";
import { theme } from "../../../theme/theme";
import CountdownIconInverted from "../../Icons/Countdown/CountdownIconInvertedIcon";

interface TicketSelectionStepProps {
    event: EventPublicDetailsInfoModel | undefined;
    ticketOptions: GetTicketOptionsResponse | undefined;
    order: PreviewOrderEventTicketsRequest | undefined;
    orderPreview: OrderEventTicketsResponse | undefined;
    showCountdownTimer: boolean;
}

const checkoutTextStyle: CSSProperties = {
    color: theme.palette.nrbyColors.invertedColor,
    marginTop: "16px",
    marginLeft: "16px",
};

const BasicCheckoutInfo = ({
    event,
    ticketOptions,
    order,
    orderPreview,
    showCountdownTimer,
}: TicketSelectionStepProps) => {
    const [timeLeft, setTimeLeft] = useState<string | undefined>();

    const updateTimer = () => {
        if (orderPreview && orderPreview.deletePendingOrderDateTimeUTC) {
            var newDateTime = new Date(
                orderPreview!.deletePendingOrderDateTimeUTC!
            );
            var expiratationTime = Date.parse(newDateTime.toString());
            var timeDiff = expiratationTime - Date.now();
            if (timeDiff > 0) {
                const remainingTime = timeDiff / 1000;
                const timeMinutes = Math.floor(remainingTime / 60);
                const timeSeconds = Number(
                    Math.round(remainingTime % 60).toFixed(0)
                );
                setTimeLeft(
                    `${timeMinutes}:${timeSeconds.toString().padStart(2, "0")}`
                );
            } else setTimeLeft(undefined);
        }
    };

    useEffect(() => {
        const timer = setInterval(function () {
            updateTimer();
        }, 500);
        return () => clearInterval(timer);
    });
    return (
        <Box>
            <Stack direction="column" style={{ padding: "16px" }}>
                <Stack direction="row">
                    <img
                        src={logoImage}
                        alt="Nrby"
                        style={{
                            height: "50px",
                            marginTop: "16px",
                            marginLeft: "16px",
                        }}
                    />
                    <Stack
                        direction="column"
                        style={checkoutTextStyle}
                        spacing={1}
                    >
                        <Typography variant="subheader01">Checkout</Typography>
                        {showCountdownTimer && timeLeft && (
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                            >
                                <CountdownIconInverted />
                                <Typography variant="body01">
                                    {timeLeft}
                                </Typography>
                            </Stack>
                        )}
                    </Stack>
                </Stack>

                <Box>
                    <Stack
                        style={{
                            color: theme.palette.nrbyColors.invertedColor,
                            marginTop: "16px",
                            paddingBottom: "16px",
                            borderBottomColor:
                                theme.palette.nrbyColors.darkGray,
                            borderBottomStyle: "solid",
                            borderBottomWidth: "1px",
                        }}
                        spacing={2}
                    >
                        <Typography variant="subheader03">
                            Contact Information
                        </Typography>
                        <Stack spacing={2} style={{ marginLeft: "16px" }}>
                            <Typography variant="body02">
                                {order?.firstName} {order?.lastName}
                            </Typography>
                            <Typography variant="body02">
                                {order?.email}
                            </Typography>
                            <Typography variant="body02">
                                {order?.phoneNumber}
                            </Typography>
                        </Stack>
                        {order?.couponCode && (
                            <React.Fragment>
                                <Typography variant="subheader03">
                                    Coupon Code
                                </Typography>
                                <Stack
                                    spacing={2}
                                    style={{ marginLeft: "16px" }}
                                >
                                    <Typography variant="body02">
                                        {order?.couponCode}
                                    </Typography>
                                </Stack>
                            </React.Fragment>
                        )}
                    </Stack>
                </Box>
            </Stack>
        </Box>
    );
};

export default BasicCheckoutInfo;
