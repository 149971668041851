import { FC } from "react";

const CountdownIconInverted: FC = () => {
    return (
        <svg
            width="23"
            height="26"
            viewBox="0 0 23 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23 14.4616C23 20.8341 17.8487 26 11.4943 26C6.77258 26 2.71517 23.1477 0.941832 19.0676H3.8288C4.38108 19.0676 4.8288 18.6199 4.8288 18.0676C4.8288 17.5153 4.38108 17.0676 3.8288 17.0676H0.283201C0.154098 16.5068 0.06576 15.9303 0.0214844 15.3415H1.90879C2.46108 15.3415 2.90879 14.8938 2.90879 14.3415C2.90879 13.7892 2.46108 13.3415 1.90879 13.3415H0.0420355C0.0986524 12.7521 0.199492 12.1756 0.341186 11.6155H3.8288C4.38108 11.6155 4.8288 11.1678 4.8288 10.6155C4.8288 10.0632 4.38108 9.61548 3.8288 9.61548H1.04945C2.87553 5.66393 6.86573 2.9231 11.4943 2.9231C17.8487 2.9231 23 8.08904 23 14.4616ZM11.4943 5.76932C12.0466 5.76932 12.4943 6.21703 12.4943 6.76932V13.4616H17.2472C17.7994 13.4616 18.2472 13.9093 18.2472 14.4616C18.2472 15.0139 17.7994 15.4616 17.2472 15.4616H11.4943C10.942 15.4616 10.4943 15.0139 10.4943 14.4616V6.76932C10.4943 6.21703 10.942 5.76932 11.4943 5.76932Z"
                fill="black"
            />
            <path
                d="M15.3228 1H7.65234"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M11.4863 4.20513V1"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default CountdownIconInverted;
