import {
    Box,
    Button, Grid,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import React, { CSSProperties, useState } from "react";
import { EventPublicDetailsInfoModel } from "../../data/Models/EventPublicDetailsInfoModel";
import { GeneralAdmissionTicketOption } from "../../data/Models/GeneralAdmissionTicketOption";
import { GetTicketOptionsResponse } from "../../data/Models/Responses/GetTicketOptionsResponse";
import { TicketOption } from "../../data/Models/TicketOption";
import { theme } from "../../theme/theme";
import TicketsCountBox from "./TicketsCount/TicketsCountBox";
var Enumerable = require("linq");

const ticketSelectionStyles: CSSProperties = {
    marginTop: "30px",
    paddingBottom: "30px",
    justifyContent: "center",
    display: "flex",
    color: theme.palette.nrbyColors.invertedColor,
};

const ticketOptionStyle: CSSProperties = {};

const ticketOptionStyleDisabled: CSSProperties = {
    opacity: "25%",
};

interface TicketSelectionStepProps {
    event: EventPublicDetailsInfoModel | undefined;
    ticketOptions: GetTicketOptionsResponse | undefined;
    gotoCardStep: (promoCode: string, ticketOptions: TicketOption[]) => void;
}

const TicketSelectionStep = ({
    event,
    ticketOptions,
    gotoCardStep,
}: TicketSelectionStepProps) => {
    const [canContinue, setCanContinue] = useState(false);

    const [selectedTicketOptions, setSelectedTicketOptions] = useState<
        TicketOption[]
    >([]);
    const [promoCode, setPromoCode] = useState("");

    const HandleSubmit = () => {
        if (canContinue) gotoCardStep(promoCode, selectedTicketOptions);
    };

    const HandlePromoCodeChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        let newValue = event.target.value as string;
        setPromoCode(newValue);
    };

    const HandleTicketsCountChange = (
        optionId: string | undefined,
        ticketsCount: number
    ) => {
        let options = selectedTicketOptions;
        if (selectedTicketOptions.length === 0 && ticketOptions) {
            options = Enumerable.from(
                ticketOptions.generalAdmissionTicketOptions
            )
                .select((option: GeneralAdmissionTicketOption) => ({
                    id: option.id,
                    qty: 0,
                }))
                .toArray();
            setSelectedTicketOptions(options);
        }
        let changedOption: any = options.find(
            (option: any) => option.id == optionId
        );
        if (changedOption) changedOption.qty = ticketsCount;

        let c: any = options.find((option: any) => option.qty > 0);
        setCanContinue(c);
    };

    return (
        <Box style={ticketSelectionStyles}>
            <Grid container spacing={2}>
                {/* <Grid item xs={12} justifyContent="center">
                    <Stack direction="row" justifyContent="center" spacing={1}>
                        <TicketIcon />
                        <Typography variant="subheader01">
                            Ticket Selection
                        </Typography>
                    </Stack>
                </Grid> */}
                <Grid item xs={12}>
                    <Typography variant="subheader03" ml={2}>
                        Select Tickets
                    </Typography>
                </Grid>
                {/* <Grid item xs={12}>
                    <Typography variant="smallCaption" ml={2}>
                        Ticket Information:
                    </Typography>
                </Grid> */}
                <Grid item xs={12}>
                    <Grid container spacing={2} justifyContent="center">
                        {ticketOptions &&
                            ticketOptions.generalAdmissionTicketOptions &&
                            ticketOptions.generalAdmissionTicketOptions.map(
                                (option: GeneralAdmissionTicketOption) => {
                                    let comingSoon =
                                        option.saleStartsDateTimeUTC &&
                                        new Date(
                                            option.saleStartsDateTimeUTC
                                        ).getTime() > new Date().getTime();
                                    return (
                                        <Grid
                                            key={option.id}
                                            item
                                            xs={12}
                                            style={{ maxWidth: "280px" }}
                                        >
                                            <Stack
                                                direction="column"
                                                justifyContent="flex-end"
                                                style={{ height: "100%" }}
                                            >
                                                {comingSoon && (
                                                    <Typography variant="smallCaption">
                                                        COMING SOON
                                                    </Typography>
                                                )}
                                                <Typography
                                                    variant="largeBody01"
                                                    style={
                                                        comingSoon
                                                            ? ticketOptionStyleDisabled
                                                            : ticketOptionStyle
                                                    }
                                                >
                                                    {option.name}: $
                                                    {option.price}
                                                </Typography>
                                                <TicketsCountBox
                                                    maxTickets={option.qty}
                                                    disabledEditor={
                                                        option.qty === 0
                                                    }
                                                    onChange={(
                                                        ticketsCount: number
                                                    ) => {
                                                        HandleTicketsCountChange(
                                                            option.id,
                                                            ticketsCount
                                                        );
                                                    }}
                                                />
                                                {option.saleEndsDateTime && (
                                                    <Typography
                                                        variant="smallCaption"
                                                        style={
                                                            comingSoon
                                                                ? ticketOptionStyleDisabled
                                                                : ticketOptionStyle
                                                        }
                                                    >
                                                        Sale Ends:{" "}
                                                        {
                                                            option.saleEndsDateTime
                                                        }
                                                    </Typography>
                                                )}
                                            </Stack>
                                        </Grid>
                                    );
                                }
                            )}
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Stack direction="column">
                        <Typography
                            variant="smallCaption"
                            style={{
                                color: theme.palette.nrbyColors.invertedColor,
                                marginTop: "16px",
                                marginLeft: "15px",
                                marginRight: "15px",
                                paddingBottom: "16px",
                                borderBottomColor:
                                    theme.palette.nrbyColors.darkGray,
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1px",
                            }}
                        >
                            Ticket Information
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                        {canContinue && (
                            <Stack
                                direction="column"
                                alignItems="center"
                                spacing={2}
                            >
                                <Box>
                                    <Typography variant="subheader03">
                                        Promo Code
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        placeholder="Enter your code here"
                                        variant="standard"
                                        onChange={HandlePromoCodeChange}
                                        style={{
                                            borderColor:
                                                theme.palette.nrbyColors.black,
                                            borderWidth: "1px",
                                            borderStyle: "solid",
                                        }}
                                    />
                                </Box>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    style={{
                                        height: "55px",
                                        width: "80%",
                                        marginTop: "30px"
                                    }}
                                    disabled={!canContinue}
                                    onClick={HandleSubmit}
                                >
                                    <Typography variant="subheader02">
                                        Checkout
                                    </Typography>
                                </Button>
                            </Stack>
                        )}
                        {!canContinue && (
                            <Typography variant="subheader03">
                                Make a selection to continue
                            </Typography>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default TicketSelectionStep;
