import {
    Backdrop,
    Box,
    Card,
    CardMedia,
    CircularProgress,
    Grid,
    Stack,
    Typography,
} from "@mui/material";
import React, { CSSProperties, useLayoutEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate, useSearchParams } from "react-router-dom";
import TopImage from "../assets/images/homepage-top.jpg";
import { MainLayout } from "../components/Layouts/MainLayout";
import BasicCheckoutInfo from "../components/Purchase/CheckoutCard/BasicCheckoutInfo";
import ContactInformationStep from "../components/Purchase/ContactInformationStep";
import EventInfo from "../components/Purchase/EventInfo";
import PaymentMethodStep from "../components/Purchase/PaymentMethodStep";
import TicketSelectionStep from "../components/Purchase/TicketSelectionStep";
import { EventPublicDetailsInfoModel } from "../data/Models/EventPublicDetailsInfoModel";
import { PreviewOrderEventTicketsRequest } from "../data/Models/Requests/PreviewOrderEventTicketsRequest";
import { GetTicketOptionsResponse } from "../data/Models/Responses/GetTicketOptionsResponse";
import { OrderEventTicketsResponse } from "../data/Models/Responses/OrderEventTicketsResponse";
import { TicketOption } from "../data/Models/TicketOption";
import { getEventDetails } from "../data/Repositories/EventRepository";
import { getTicketOptions } from "../data/Repositories/TicketOptionsRepository";
import { previewTicketsOrder } from "../data/Repositories/UserEventTicketRepository";
import { theme } from "../theme/theme";
import { PurchaseTicketStep } from "../types/models/enums";
import scrollWithOffset from "../types/scrollWithOffset";

const rightPanelStyle: CSSProperties = {
    marginTop: "60px",
    backgroundColor: theme.palette.nrbyColors.faintGray,
    zIndex: 3,
};

const eventNotFoundContentStyle: CSSProperties = {
    position: "absolute",
    color: "white",
    top: "40%",
    left: "50%",
    transform: "translateX(-50%)",
    width: "100%",
    textAlign: "center",
    verticalAlign: "middle",
    marginTop: "auto",
    marginBottom: "auto",
};

const PurchaseTicketPage = () => {
    const navigate = useNavigate();
    const [pageParams, setPageParams] = useSearchParams();
    const [eventCompleted, setEventCompleted] = useState<boolean | undefined>();
    const [showEventNotFound, setShowEventNotFound] = useState(false);

    const [eventData, setEventData] = useState<
        EventPublicDetailsInfoModel | undefined
    >();
    const [activeStep, setActiveStep] = useState<PurchaseTicketStep>(
        PurchaseTicketStep.ContactInfo
    );

    const [ticketOptions, setTicketOptions] =
        useState<GetTicketOptionsResponse>();
    const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
    const [showCountdownTimer, setShowCountdownTimer] = useState(false);
    const [serverError, setServerError] = useState<string | undefined>();

    const [orderData, setOrderData] = useState<PreviewOrderEventTicketsRequest>(
        {
            firstName: "",
            lastName: "",
            orderId: undefined,
            email: "",
            couponCode: "",
            phoneNumber: "",
            generalAdmissionTickets: undefined,
            assignedSeatingTickets: undefined,
        }
    );
    const [orderPreviewData, setOrderPreviewData] =
        useState<OrderEventTicketsResponse>();

    const HandleGotoTicketSelectionStep = (
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string
    ) => {
        orderData.firstName = firstName;
        orderData.lastName = lastName;
        orderData.email = email;
        orderData.phoneNumber = phoneNumber;
        setActiveStep(PurchaseTicketStep.SelectTickets);
        scrollWithOffset("purchaseHeader");
    };

    const HandleGotoCardStep = (
        promoCode: string,
        ticketOptions: TicketOption[]
    ) => {
        orderData.couponCode = promoCode;
        orderData.generalAdmissionTickets = ticketOptions;
        setShowLoadingIndicator(true);
        CreateTicketOrderPreview();
    };

    const CreateTicketOrderPreview = () => {
        previewTicketsOrder(
            orderData.email,
            orderData.firstName,
            orderData.lastName,
            orderData.phoneNumber,
            orderData.couponCode,
            orderData.generalAdmissionTickets
        ).then((response) => {
            if (response && response.data && response.data.success) {
                if (
                    response.data.totalPrice === 0 &&
                    !response.data.paymentIntentClientSecret
                ) {
                    let orderPreview = response.data;
                    let currentDateTime = new Date();

                    const searchParams = new URLSearchParams();
                    searchParams.append("orderId", orderPreview.orderId);
                    searchParams.append("firstName", orderData.firstName);
                    searchParams.append("lastName", orderData.lastName);
                    searchParams.append("email", orderData.email);
                    searchParams.append("phone", orderData.phoneNumber);
                    searchParams.append(
                        "dateTimeSubmitted",
                        currentDateTime.toString()
                    );

                    navigate(`/orderconfirmation?${searchParams.toString()}`);
                } else {
                    setOrderPreviewData(response.data);
                    setActiveStep(PurchaseTicketStep.PaymentInfo);
                    setShowCountdownTimer(true);
                    scrollWithOffset("purchaseHeader");
                }
            } else {
                setServerError(response.data.errorMsg);
            }
            setShowLoadingIndicator(false);
        });
    };

    useLayoutEffect(() => {
        let id = pageParams.get("id");
        if (!id) navigate("/notFound");
        if (!eventData && !showEventNotFound) {
            setShowLoadingIndicator(true);
            getEventDetails(id!).then((response) => {
                if (response && response.data && response.data.success) {
                    setEventData(response.data.event);
                    let event = response.data.event;
                    if (
                        event.finishDateTimeUTC &&
                        event.finishDateTimeUTC < new Date()
                    ) {
                        setEventCompleted(true);
                    } else {
                        setEventCompleted(false);
                        getTicketOptions(id!, "").then((ticketsResponse) => {
                            if (
                                ticketsResponse &&
                                ticketsResponse.data &&
                                ticketsResponse.data.success
                            ) {
                                setTicketOptions(ticketsResponse.data);
                            } else setShowEventNotFound(true);
                        });
                        //alert("Hello world");

                        scrollWithOffset("contactInfoSection");
                    }
                } else setShowEventNotFound(true);
                setShowLoadingIndicator(false);

                scrollWithOffset("contactInfoSection");
            });
        }
    });

    let showSideBar =
        activeStep === PurchaseTicketStep.PaymentInfo ||
        activeStep === PurchaseTicketStep.SelectTickets;
    return (
        <MainLayout>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={!eventData && !showEventNotFound}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {showEventNotFound && (
                <Card>
                    <Box style={{ position: "relative" }}>
                        <CardMedia
                            style={{}}
                            component="img"
                            image={TopImage}
                        />
                        <Box style={eventNotFoundContentStyle}>
                            <Typography
                                variant="header01"
                                style={{ fontSize: "10vw" }}
                            >
                                Event Not Found
                            </Typography>
                        </Box>
                    </Box>
                </Card>
            )}

            {eventData && (
                <Box style={{ position: "relative", paddingBottom: "20px" }}>
                    <Grid container>
                        <Grid item xs>
                            <Stack
                                style={{
                                    position: "relative",
                                    paddingBottom: "20px",
                                }}
                            >
                                {eventData && (
                                    <EventInfo
                                        event={eventData}
                                        briefDescription={
                                            isMobile ||
                                            activeStep ===
                                                PurchaseTicketStep.ContactInfo
                                        }
                                    />
                                )}
                                {!eventData && (
                                    <React.Fragment>
                                        <Stack
                                            direction="column"
                                            textAlign="center"
                                            style={{
                                                marginTop: "80px",
                                            }}
                                        >
                                            <Box>
                                                <CircularProgress color="primary" />
                                            </Box>

                                            <Typography variant="header02">
                                                Loading
                                            </Typography>
                                        </Stack>
                                    </React.Fragment>
                                )}
                                <div id="contactInfoSection">
                                    {activeStep ===
                                        PurchaseTicketStep.ContactInfo &&
                                        eventCompleted == false && (
                                            <ContactInformationStep
                                                event={eventData}
                                                gotoTicketSelectionStep={
                                                    HandleGotoTicketSelectionStep
                                                }
                                            />
                                        )}
                                </div>
                                {eventCompleted == true && (
                                    <Typography
                                        variant="header01"
                                        style={{
                                            textAlign: "center",
                                            marginTop: "50px",
                                        }}
                                    >
                                        The Event has been completed
                                    </Typography>
                                )}
                            </Stack>
                        </Grid>
                        {showSideBar && eventCompleted == false && (
                            <React.Fragment>
                                <Backdrop
                                    sx={{
                                        color: "#fff",
                                        zIndex: (theme) =>
                                            theme.zIndex.drawer + 1,
                                    }}
                                    open={showLoadingIndicator}
                                    onClick={() => {
                                        setShowLoadingIndicator(false);
                                    }}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                                <Grid
                                    item
                                    xs={12}
                                    sm={3}
                                    style={rightPanelStyle}
                                    id="purchaseHeader"
                                >
                                    <Stack direction="column">
                                        <BasicCheckoutInfo
                                            event={undefined}
                                            ticketOptions={undefined}
                                            order={orderData}
                                            orderPreview={orderPreviewData}
                                            showCountdownTimer={
                                                showCountdownTimer
                                            }
                                        />
                                        {serverError && (
                                            <Box style={{}}>
                                                <Typography
                                                    variant="header02"
                                                    style={{
                                                        color: theme.palette
                                                            .nrbyColors
                                                            .tangerine,
                                                        textAlign: "center",
                                                        paddingTop: "0px",
                                                    }}
                                                >
                                                    {serverError}
                                                </Typography>
                                            </Box>
                                        )}
                                        {activeStep ===
                                            PurchaseTicketStep.SelectTickets && (
                                            <TicketSelectionStep
                                                event={eventData}
                                                ticketOptions={ticketOptions}
                                                gotoCardStep={
                                                    HandleGotoCardStep
                                                }
                                            />
                                        )}
                                        {activeStep ===
                                            PurchaseTicketStep.PaymentInfo && (
                                            <PaymentMethodStep
                                                event={eventData}
                                                order={orderData}
                                                orderPreview={orderPreviewData!}
                                            />
                                        )}
                                    </Stack>
                                </Grid>
                            </React.Fragment>
                        )}
                    </Grid>
                </Box>
            )}
        </MainLayout>
    );
};

export default PurchaseTicketPage;
