import {
    Box,
    Button,
    Card,
    CardContent, Grid, Link, Stack,
    Typography
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { MainLayout } from "../components/Layouts/MainLayout";
import styles from "./HomePage.module.scss";

const HomePage = () => {
    return (
        <MainLayout>
            <Card sx={{ padding: { xs: 0, marginTop: "30px" } }}>
                <Box style={{ position: "relative" }}>
                    <Box
                        component="div"
                        style={{
                            height: "100%",
                            position: "absolute",
                            backgroundImage: `url('/images/homepage-bg.png')`,
                            backgroundSize: "cover",
                            filter: "brightness(60%)",
                            width: "100%",
                            zIndex: 0,
                            overflow: "hidden",
                        }}
                    >
                        &nbsp;
                    </Box>
                    <CardContent
                        className={styles.mainContent}
                        sx={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: { xl: "1250px", xs: "100%" },
                            maxWidth: { lg: "1250px" },
                            padding: { xs: "20px", lg: "0" },
                        }}
                    >
                        <Grid
                            container
                            justifyContent="space-between"
                            spacing={3}
                        >
                            <Grid item lg={1} xs={12} alignItems="center">
                                <Stack
                                    sx={{
                                        flexDirection: {
                                            lg: "column",
                                            xs: "row",
                                        },
                                        justifyContent: {
                                            xs: "space-between",
                                            md: "center"
                                        }
                                    }}
                                    style={{ height: "100%" }}
                                    justifyContent="center"
                                    className={styles.iconsStack}
                                >
                                    <Link
                                        component={RouterLink}
                                        to="https://www.facebook.com/nrby.io/"
                                        target="_blank"
                                    >
                                        <img
                                            src="/images/social/facebook.png"
                                            style={{
                                                width: 40,
                                                height: 40,
                                            }}
                                            alt="Facebook"
                                        />
                                    </Link>
                                    <Link
                                        component={RouterLink}
                                        to="https://www.instagram.com/nrby.io/?hl=en"
                                        target="_blank"
                                    >
                                        <img
                                            src="/images/social/instagram.png"
                                            style={{
                                                width: 40,
                                                height: 40,
                                            }}
                                            alt="Instagram"
                                        />
                                    </Link>
                                    <Link
                                        component={RouterLink}
                                        to="https://www.linkedin.com/company/nrby-io"
                                        target="_blank"
                                    >
                                        <img
                                            src="/images/social/linkedin.png"
                                            style={{
                                                width: 40,
                                                height: 40,
                                            }}
                                            alt="LinkedIn"
                                        />
                                    </Link>
                                    <Link
                                        component={RouterLink}
                                        to="https://www.tiktok.com/@nrby.io"
                                        target="_blank"
                                    >
                                        <img
                                            src="/images/social/tiktok.png"
                                            style={{
                                                width: 40,
                                                height: 40,
                                            }}
                                            alt="Tiktok"
                                        />
                                    </Link>
                                    <Link
                                        component={RouterLink}
                                        to="https://twitter.com/nrby_io"
                                        target="_blank"
                                    >
                                        <img
                                            src="/images/social/twitter.png"
                                            style={{
                                                width: 40,
                                                height: 40,
                                            }}
                                            alt="Twitter"
                                        />
                                    </Link>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <Stack direction="column" spacing={4}>
                                    <Typography
                                        variant="header01"
                                        textAlign="left"
                                    >
                                        Discover Events Nearby!
                                    </Typography>
                                    <Typography
                                        variant="body01"
                                        className={styles.description}
                                    >
                                        Nrby can help you find nearby
                                        recommended events for you and your
                                        friends! Finding something to do has
                                        never been this easy!
                                    </Typography>
                                    <Box>
                                        <Grid
                                            container
                                            spacing={4}
                                            alignSelf="center"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                lg={2}
                                                alignContent="center"
                                            >
                                                <img
                                                    src="/images/nrby-pointer.png"
                                                    style={{
                                                        borderRadius: "30px",
                                                        display: "block",
                                                        marginLeft: "auto",
                                                        marginRight: "auto",
                                                    }}
                                                    alt="logo"
                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={5}>
                                                <Button
                                                    fullWidth={true}
                                                    component={RouterLink}
                                                    to="https://apps.apple.com/us/app/nrby-io/id1525909483"
                                                    color="primary"
                                                    variant="bordered"
                                                    style={{
                                                        height: "60px",
                                                        marginTop: "auto",
                                                        marginBottom: "auto",
                                                        padding:
                                                            "19px, 16px, 19px, 16px",
                                                    }}
                                                >
                                                    <Typography variant="subheader03">
                                                        Download for iOS &nbsp;
                                                        &nbsp;
                                                        <img
                                                            src="/images/ios-logo.png"
                                                            style={{
                                                                marginTop:
                                                                    "-2px",
                                                            }}
                                                            alt="iOS"
                                                        />
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} lg={5}>
                                                <Button
                                                    fullWidth={true}
                                                    component={RouterLink}
                                                    to="https://play.google.com/store/apps/details?id=com.ByNearBy&hl=en_US&gl=US&pli=1"
                                                    color="primary"
                                                    variant="bordered"
                                                    style={{
                                                        height: "60px",
                                                        marginTop: "auto",
                                                        marginBottom: "auto",
                                                        padding:
                                                            "19px, 16px, 19px, 16px",
                                                    }}
                                                >
                                                    <Typography variant="subheader03">
                                                        Download for Android
                                                        &nbsp; &nbsp;
                                                        <img
                                                            src="/images/android-logo.png"
                                                            style={{
                                                                marginTop:
                                                                    "-2px",
                                                            }}
                                                            alt="Android"
                                                        />
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Stack>
                            </Grid>
                            <Grid item>
                                <img src="/images/homepage-phone.png" alt="Phone"/>
                            </Grid>
                        </Grid>

                        <Stack
                            spacing={{ xs: 1, sm: 4 }}
                            mt={{ xs: 2, sm: 4 }}
                            className={styles.subheadersBlock}
                        ></Stack>
                    </CardContent>
                </Box>
            </Card>
        </MainLayout>
    );
};

export default HomePage;
