import { Components } from '@mui/material/styles/components';

export const MuiAppBar: Components = {
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: "#000",
        backgroundImage: 'none'
      },
    },
  },
};