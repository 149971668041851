import {
    Box,
    Button,
    Card,
    Checkbox,
    FormControlLabel,
    Grid,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { CSSProperties, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { EventPublicDetailsInfoModel } from "../../data/Models/EventPublicDetailsInfoModel";
import { theme } from "../../theme/theme";
import DefaultAvatarIcon from "../Icons/DefaultAvatarIcon";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

const contactInformationStyles: CSSProperties = {
    marginTop: "30px",
    backgroundColor: theme.palette.nrbyColors.primaryBgColor,
    justifyContent: "center",
    display: "flex",
    padding: "15px",
};

interface ContactInformationStep {
    event: EventPublicDetailsInfoModel | undefined;
    gotoTicketSelectionStep: (
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string
    ) => void;
}

const ContactInformationStep = ({
    event,
    gotoTicketSelectionStep,
}: ContactInformationStep) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [confirmEmail, setConfirmEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
    const [saveContactInfo, setSaveContactInfo] = useState(true);
    const [
        saveContactInfoCookie,
        setSaveContactInfoCookie,
        removeSaveContactInfoCookie,
    ] = useCookies(["saveContactInfo"]);
    const [savedContactInfo, setSavedContactInfo, removeSavedContactInfo] =
        useCookies(["savedFirstName", "savedLastName", "savedEmail", "savedPhoneNumber"]);

    const navigate = useNavigate();
    
    useEffect(() => {
        if (saveContactInfoCookie){
            setFirstName(savedContactInfo.savedFirstName);
            setLastName(savedContactInfo.savedLastName);
            setEmail(savedContactInfo.savedEmail);
            setConfirmEmail(savedContactInfo.savedEmail);
            setPhoneNumber(String(savedContactInfo.savedPhoneNumber ?? ""));
            setSaveContactInfo(true);
            setIsEmailValid(true);
        }
    },[])

    const HandleFirstNameChange = (e: any) => setFirstName(e.target.value);
    const HandleLastNameChange = (e: any) => setLastName(e.target.value);
    const HandleEmailChange = (e: any) => {
        setEmail(e.target.value);
        CheckEmailAddress(e.target.value, confirmEmail);
    };

    const isValidPhoneNumberRegex =
        /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    const HandlePhoneNumberChange = (e: any) => {
        let newValue = e.target.value;
        let valid = newValue && isValidPhoneNumberRegex.test(newValue); //isValidPhoneNumber(e.target.value, "US");
        setIsPhoneNumberValid(valid);
        setPhoneNumber(e.target.value);
    };

    const HandleConfirmEmailChange = (e: any) => {
        setConfirmEmail(e.target.value);
        CheckEmailAddress(email, e.target.value);
    };
    
    const HandleSubmit = () => {
        if (saveContactInfo) {
            setSaveContactInfoCookie("saveContactInfo", "1");
            setSavedContactInfo("savedFirstName", firstName);
            setSavedContactInfo("savedLastName", lastName);
            setSavedContactInfo("savedEmail", email);
            setSavedContactInfo("savedPhoneNumber", phoneNumber);
        } else {
            removeSaveContactInfoCookie("saveContactInfo");
            removeSavedContactInfo("savedFirstName");
            removeSavedContactInfo("savedLastName");
            removeSavedContactInfo("savedEmail");
            removeSavedContactInfo("savedPhoneNumber");
        }
        gotoTicketSelectionStep(firstName, lastName, email, phoneNumber);
    };

    const HandleCancel = () => {
        navigate("/event?id=" + event?.id);
    };

    const HangleSaveContactInfoChange = (event: React.SyntheticEvent, checked: boolean) => {
        setSaveContactInfo(checked);
    }

    const isValidEmailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const CheckEmailAddress = (email: string, confirmEmail: string) => {
        let isValid = isValidEmailRegex.test(email) && email === confirmEmail;
        setIsEmailValid(isValid);
    };

    let canSubmit =
        firstName &&
        lastName &&
        email &&
        confirmEmail &&
        phoneNumber &&
        email === confirmEmail &&
        isEmailValid &&
        isPhoneNumberValid;

    return (
        <Card style={contactInformationStyles}>
            <Grid container spacing={2}>
                <Grid item xs={12} justifyContent="center">
                    <Stack direction="row" justifyContent="center" spacing={1}>
                        <DefaultAvatarIcon />
                        <Typography variant="subheader01">
                            Contact Information
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="body02" ml={2}>
                        First Name (* required)
                    </Typography>
                    <TextField
                        fullWidth
                        variant="standard"
                        error={firstName === ""}
                        value={firstName}
                        onChange={HandleFirstNameChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="body02" ml={2}>
                        Last Name (* required)
                    </Typography>
                    <TextField
                        fullWidth
                        variant="standard"
                        error={lastName === ""}
                        value={lastName}
                        onChange={HandleLastNameChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="body02" ml={2}>
                        Email Address (* required)
                    </Typography>
                    <TextField
                        fullWidth
                        variant="standard"
                        error={!isEmailValid}
                        value={email}
                        onChange={HandleEmailChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="body02" ml={2}>
                        Confirm Email Address (* required)
                    </Typography>
                    <TextField
                        fullWidth
                        variant="standard"
                        error={!isEmailValid}
                        value={confirmEmail}
                        onChange={HandleConfirmEmailChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="body02" ml={2}>
                        Phone Number (* required)
                    </Typography>
                    <TextField
                        fullWidth
                        variant="standard"
                        error={phoneNumber === "" || !isPhoneNumberValid}
                        value={phoneNumber}
                        onChange={HandlePhoneNumberChange}
                    />
                </Grid>
                <Box width="100%" />
                <Grid item xs={12} md={6}>
                    <FormControlLabel
                        control={<Checkbox checked={saveContactInfo} defaultChecked={true}/>}
                        label="Save Contact Information"
                        onChange={HangleSaveContactInfoChange}
                    />
                </Grid>
                <Box width="100%" />

                <Grid item xs={6}>
                    <Stack direction="row" justifyContent="flex-end">
                        <Button
                            color="primary"
                            variant="contained"
                            style={{
                                height: "55px",
                                textAlign: "center",
                            }}
                            sx={{
                                width: { xs: "100%", md: "auto" },
                                fontSize: { xs: "18px", md: "23px" },
                            }}
                            onClick={HandleCancel}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        color="tangerine"
                        variant="contained"
                        style={{
                            height: "55px",
                            textAlign: "center",
                            whiteSpace: "nowrap",
                        }}
                        sx={{
                            width: { xs: "100%", md: "auto" },
                            fontSize: { xs: "18px", md: "23px" },
                        }}
                        disabled={!canSubmit}
                        onClick={HandleSubmit}
                    >
                        Select Tickets
                    </Button>
                </Grid>
            </Grid>
        </Card>
    );
};

export default ContactInformationStep;
