import { Link, Tooltip } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import logoImage from "../../../../assets/images/logo-nrby.png";

const NrbyLogo = () => {
    return (
        <Tooltip title={"Nrby"} arrow>
            <Link component={RouterLink} to="/">
                <img src={logoImage} alt="Nrby" style={{ height: "55px" }} />
            </Link>
        </Tooltip>
    );
};

export default NrbyLogo;
