import MenuIcon from "@mui/icons-material/Menu";
import { IconButton, Menu, MenuItem } from "@mui/material";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";

function MainMenuMobile() {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
        null
    );
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
        null
    );

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <React.Fragment>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
            >
                <MenuIcon />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                    display: { xs: "block", md: "none" },
                }}
            >
                <MenuItem key="createEvents" component={RouterLink} to="/signup">
                    Create Events
                </MenuItem>
                <MenuItem
                    key="viewEvents"
                    component={RouterLink}
                    to="/events"
                >
                    View Events
                </MenuItem>
                <MenuItem key="about" component={RouterLink} to="/aboutus">
                    About
                </MenuItem>
                <MenuItem key="blog" component={RouterLink} to="https://nrby.io/blog/">
                    News
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}

export default MainMenuMobile;
