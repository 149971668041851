import {
    Box,
    CardContent,
    CardMedia,
    Chip,
    Grid,
    Link,
    Stack,
    Typography,
} from "@mui/material";
import { CSSProperties } from "react";
import { Link as RouterLink } from "react-router-dom";
import defaultImage from "../../../assets/images/default-event-image.png";
import { DateFormatter } from "../../../data/Formatters/DateFormatter";
import { EventPublicInfoModel } from "../../../data/Models/EventPublicInfoModel";
import { theme } from "../../../theme/theme";
import {
    EventStatusCode,
    EventStatusCodeToString,
} from "../../../types/models/enums";
import EventDateIcon from "../../Icons/DateTime/EventDateIcon";
import EventTimeIcon from "../../Icons/DateTime/EventTimeIcon";
import ShareIcon from "../../Icons/ShareIcon";
import Share from "../../Share/Share";
import styles from "./FeaturedEventCard.module.scss";

const featuredCard: CSSProperties = {
    backgroundColor: theme.palette.nrbyColors.invertedBgColor,
    color: theme.palette.nrbyColors.invertedColor,
    position: "relative",

    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
};

const eventBackgroundImage: CSSProperties = {
    width: "auto",
    height: "100px",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
};

const invertedCard: CSSProperties = {
    backgroundColor: theme.palette.nrbyColors.primaryBgColor,
    color: theme.palette.nrbyColors.primaryColor,
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
    maxWidth: "400px",
    flexGrow: 1,
};

const salesEndedPanelStyle: CSSProperties = {
    width: "100%",
    height: "100%",
    position: "absolute",
    borderRadius: "20px",
    zIndex: "10",
    backgroundColor: "#40404080",
    textAlign: "center",
    paddingTop: "20px",
    marginRight: "20px",
};

interface FeaturedEventProps {
    event: EventPublicInfoModel;
}

const FeaturedEventCard = (props: FeaturedEventProps) => {
    let eventData = props.event;
    let mainImage = defaultImage;
    if (eventData && eventData.images && eventData.images[0])
        mainImage = eventData.images[0];

    let shareData = {
        title: props.event.name,
        text: props.event.description,
        url: `${process.env.REACT_APP_BASE_URL}/event?id=${props.event.id}`,
    };

    let statusText: string | undefined = undefined;
    if (eventData && eventData.status != EventStatusCode.OnSale)
        statusText = EventStatusCodeToString(eventData.status);

    return (
        <Grid item xs={12} sm={6} md={4} style={featuredCard}>
            <Link
                component={RouterLink}
                to={"/event?id=" + props.event.id}
                style={{ height: "100%", textDecorationLine: "none" }}
            >
                <Stack style={{ height: "100%", position: "relative" }}>
                    {statusText && (
                        <div style={salesEndedPanelStyle}>
                            <Typography variant="subheader01">
                                {statusText}
                            </Typography>
                        </div>
                    )}
                    <CardMedia
                        sx={{}}
                        style={eventBackgroundImage}
                        image={mainImage}
                        title={props.event.name}
                    />
                    <CardContent
                        style={invertedCard}
                        component={Stack}
                        sx={{ height: "100%" }}
                    >
                        <Stack direction="row" justifyContent="space-between">
                            <Typography component="div" variant="subheader02">
                                {props.event.name}
                            </Typography>

                            <Stack>
                                <Typography variant="smallCaption">
                                    <EventDateIcon />
                                    &nbsp;&nbsp;
                                    {DateFormatter(
                                        props.event.startDateTimeLocal
                                    )}
                                </Typography>
                                <Typography
                                    variant="smallCaption"
                                    style={{ whiteSpace: "nowrap" }}
                                >
                                    <EventTimeIcon />
                                    &nbsp;&nbsp;
                                    {props.event.startTimeLocal}
                                </Typography>
                            </Stack>
                        </Stack>
                        <Typography
                            component="div"
                            variant="body02"
                            mt={2}
                            className={styles.featuredEventCard}
                        >
                            {props.event.description}
                        </Typography>
                    </CardContent>
                    <Chip
                        label={props.event.region}
                        size="small"
                        variant="outlined"
                        sx={{
                            position: "absolute",
                            top: "10px",
                            left: "10px",
                        }}
                    />
                </Stack>
            </Link>
            <Box
                sx={{
                    position: "absolute",
                    top: "25px",
                    right: "15px",
                }}
                className={styles.buttonsContainer}
            >
                <Share shareData={shareData}>
                    <ShareIcon width="40" height="40" />
                </Share>
            </Box>
        </Grid>
    );
};

export default FeaturedEventCard;
