import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Stack } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { MainLayout } from "../components/Layouts/MainLayout";
import { SendAuthenticateRequest, SignInRequest } from "../data/Repositories/UsersRepository";

export default function LoginPage() {
    const navigate = useNavigate();

    const HandleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let email = data.get("email")?.toString() ?? "";
        let password = data.get("password")?.toString() ?? "";

        var response = await SendAuthenticateRequest(email, password);
        // TODO: error check
        if (response.data.token){ 
            localStorage.setItem("accessToken", response.data.token);
            navigate("/");
        }
    };

    return (
        <MainLayout>
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box
                    component="form"
                    onSubmit={HandleSubmit}
                    noValidate
                    sx={{ mt: 1 }}
                >
                    <Stack spacing={2}>
                        <Stack>
                            <Typography variant="body02" ml={2}>
                                Email Address
                            </Typography>
                            <TextField
                                fullWidth
                                variant="standard"
                                name="email"
                            />
                        </Stack>
                        <Stack>
                            <Typography variant="body02" ml={2}>
                                Password
                            </Typography>
                            <TextField
                                fullWidth
                                variant="standard"
                                name="password"
                                type="password"
                            />
                        </Stack>
                    </Stack>
                    {/* <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    /> */}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign In
                    </Button>
                    {/* <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid> */}
                </Box>
            </Box>
        </MainLayout>
    );
}
