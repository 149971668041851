import { Box, Grid, Stack, Typography } from "@mui/material";
import { CSSProperties } from "react";
import { EventPublicDetailsInfoModel } from "../../../data/Models/EventPublicDetailsInfoModel";
import { OrderEventTicketsResponse } from "../../../data/Models/Responses/OrderEventTicketsResponse";
import { theme } from "../../../theme/theme";

interface OrderPreviewInfoProps {
    event: EventPublicDetailsInfoModel | undefined;
    orderPreview: OrderEventTicketsResponse | undefined;
}

const parameterNameStyle: CSSProperties = {
    textAlign: "right",
    verticalAlign: "bottom",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
};

const selectionsBlockStyle: CSSProperties = {
    padding: "16px",
    borderBottomColor: theme.palette.nrbyColors.darkGray,
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",

    borderTopColor: theme.palette.nrbyColors.darkGray,
    borderTopStyle: "solid",
    borderTopWidth: "1px",

    marginTop: "25px",
};

const OrderPreviewInfo = ({ event, orderPreview }: OrderPreviewInfoProps) => {
    return (
        <Box>
            <Stack direction="column" style={selectionsBlockStyle}>
                <Typography variant="subheader03">Your selection(s)</Typography>
                <Grid container columnSpacing={2} rowSpacing={0}>
                    <Grid item xs={8}>
                        <Typography
                            variant="smallCaption"
                            style={parameterNameStyle}
                        >
                            Base Price:
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="largeBody01">
                            ${orderPreview?.basePriceTotal.toFixed(2)}
                        </Typography>
                    </Grid>

                    <Grid item xs={8}>
                        <Typography
                            variant="smallCaption"
                            style={parameterNameStyle}
                        >
                            Coupon Code Discount:
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="largeBody01">
                            ($
                            {orderPreview?.couponCodeDiscountAmount.toFixed(2)})
                        </Typography>
                    </Grid>

                    <Grid item xs={8}>
                        <Typography
                            variant="smallCaption"
                            style={parameterNameStyle}
                        >
                            Service Fee:
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="largeBody01">
                            ${orderPreview?.serviceFeeTotal.toFixed(2)}
                        </Typography>
                    </Grid>

                    <Grid item xs={8}>
                        <Typography
                            variant="smallCaption"
                            style={parameterNameStyle}
                        >
                            Sales Tax:
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="largeBody01">
                            ${orderPreview?.salesTaxTotal.toFixed(2)}
                        </Typography>
                    </Grid>

                    <Grid item xs={8}>
                        <Typography
                            variant="smallCaption"
                            style={parameterNameStyle}
                        >
                            Processing Fee:
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="largeBody01">
                            ${orderPreview?.processingFeeTotal.toFixed(2)}
                        </Typography>
                    </Grid>

                    {/* <Grid item xs={8}>
                        <Typography
                            variant="smallCaption"
                            style={parameterNameStyle}
                        >
                            Total:
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="largeBody01">
                            ${orderPreview?.totalPrice.toFixed(2)}
                        </Typography>
                    </Grid> */}

                    <Grid item xs={12}>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            sx={{ mt: 3 }}
                            spacing={2}
                        >
                            <Typography
                                variant="subheader02"
                                style={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                    marginBottom: "2px",
                                    justifyContent: "flex-end",
                                }}
                            >
                                TOTAL:
                            </Typography>
                            <Typography variant="header02">
                                ${orderPreview?.totalPrice.toFixed(2)}
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>
        </Box>
    );
};

export default OrderPreviewInfo;
