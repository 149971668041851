import {
    Box, Card, CardMedia, Typography
} from "@mui/material";
import { CSSProperties } from "react";
import TopImage from "../assets/images/homepage-top.jpg";
import { MainLayout } from "../components/Layouts/MainLayout";


const mainContent: CSSProperties = {
    position: "absolute",
    color: "white",
    top: "40%",
    left: "50%",
    transform: "translateX(-50%)",
    width: "100%",
    textAlign: "center",
    verticalAlign: "middle",
    marginTop: "auto",
    marginBottom: "auto",
};

const NotFoundPage = () => {
    return (
        <MainLayout>
            <Card>
                <Box style={{ position: "relative" }}>
                    <CardMedia style={{}} component="img" image={TopImage} />
                    <Box style={mainContent}>
                        <Typography variant="header01" style={{fontSize: "10vw"}}>
                            Page Not Found
                        </Typography>
                    </Box>
                </Box>
            </Card>
        </MainLayout>
    );
};

export default NotFoundPage;
