import { Grid, Link, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useLayoutEffect, useState } from "react";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "../../../theme/Calendar.css";
import { theme } from "../../../theme/theme";
import { FilterByDate } from "../../../types/models/enums";

const filterItems = [
    { id: FilterByDate.NoFilter, name: "All Events" },
    { id: FilterByDate.Today, name: "Today" },
    { id: FilterByDate.Tomorrow, name: "Tomorrow" },
    { id: FilterByDate.ThisWeekend, name: "This Weekend" },
    { id: FilterByDate.NextWeek, name: "Next Week" },
    { id: FilterByDate.ExactDay, name: "Select Date" },
];

export interface DateFilterProps {
    onFilterChange?: (
        filter: FilterByDate,
        exactDate: Date | undefined
    ) => void;
    value: FilterByDate;
    initialDate: moment.Moment;
}

const DateFilter = (props: DateFilterProps) => {
    const [selectedFilter, setSelectedFilter] = useState<FilterByDate>(
        FilterByDate.NoFilter
    );

    const [selectedDate, setSelectedDate] = useState<Date>();
    const [datePickerVisible, setDatePickerVisible] = useState<boolean>(false);

    useEffect(() => {
        setSelectedDate(props.initialDate.toDate());
    }, [props.initialDate]);

    useLayoutEffect(() => {
        setSelectedFilter(props.value);
    }, [props.value]);

    const handleClick = (newSelectedFilter: FilterByDate) => {
        if (newSelectedFilter !== selectedFilter) {
            setSelectedFilter(newSelectedFilter);

            if (props.onFilterChange) {
                props.onFilterChange(newSelectedFilter, selectedDate);
            }

            if (newSelectedFilter === FilterByDate.ExactDay)
                setDatePickerVisible(true);
            else setDatePickerVisible(false);
        } else if (newSelectedFilter == FilterByDate.ExactDay) {
            setDatePickerVisible(true);
        }
    };

    const HandleCalendarChange = (value: any) => {
        let newSelectedDate = value as Date;
        setDatePickerVisible(false);
        setSelectedDate(newSelectedDate);

        if (props.onFilterChange) {
            props.onFilterChange(selectedFilter, newSelectedDate);
        }
    };

    const HandleCalendarClose = () => {
        setDatePickerVisible(false);
    };

    return (
        <Grid
            container
            spacing={3}
            sx={{ justifyContent: { xs: "center", sm: "left" } }}
        >
            {filterItems.map((item: any) => (
                <Grid item key={item.id}>
                    {selectedFilter === FilterByDate.ExactDay &&
                        item.id === FilterByDate.ExactDay && (
                            <DatePicker
                                onChange={HandleCalendarChange}
                                onCalendarClose={HandleCalendarClose}
                                value={selectedDate}
                                isOpen={datePickerVisible}
                                locale="en-US"
                            />
                        )}
                    <Link
                        component="button"
                        onClick={() => handleClick(item.id)}
                        key={item.id}
                        style={{ textDecorationLine: "none" }}
                    >
                        <Typography
                            variant="subheader02"
                            style={{
                                color:
                                    selectedFilter === item.id
                                        ? theme.palette.nrbyColors.tangerine
                                        : theme.palette.nrbyColors.primaryColor,
                            }}
                        >
                            {!(
                                selectedFilter === FilterByDate.ExactDay &&
                                item.id === FilterByDate.ExactDay
                            ) && <span>{item.name}</span>}
                            {selectedFilter === FilterByDate.ExactDay &&
                                item.id === FilterByDate.ExactDay && (
                                    <span>
                                        {moment(selectedDate).format("ddd M/D")}
                                    </span>
                                )}
                        </Typography>
                    </Link>
                </Grid>
            ))}
        </Grid>
    );
};

export default DateFilter;
