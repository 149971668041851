import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import FeaturedEventsPage from "./pages/FeaturedEventsPage";
import AllEventsPage from "./pages/AllEventsPage";
import EventPage from "./pages/EventPage";
import PurchaseTicketPage from "./pages/PurchaseTicketPage";
import LoginPage from "./pages/LoginPage";
import NotFoundPage from "./pages/NotFoundPage";
import OrderConfirmationPage from "./pages/OrderConfirmationPage";
import PrivacyPage from "./pages/PrivacyPage";
import TermsPage from "./pages/TermsPage";
import SignupPage from "./pages/SignupPage";
import AboutUsPage from "./pages/AboutUsPage";
import ContactUsPage from "./pages/ContactUsPage";
import { Helmet } from "react-helmet";
import React from "react";

function App() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Nrby Events</title>
            </Helmet>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<HomePage />}></Route>
                    <Route
                        path="/featured"
                        element={<FeaturedEventsPage />}
                    ></Route>
                    <Route path="/events" element={<AllEventsPage />}></Route>
                    <Route path="/event" element={<EventPage />}></Route>
                    <Route
                        path="/purchaseticket"
                        element={<PurchaseTicketPage />}
                    />
                    <Route
                        path="/orderconfirmation"
                        element={<OrderConfirmationPage />}
                    />
                    <Route path="/login" element={<LoginPage />}></Route>
                    <Route path="/notfound" element={<NotFoundPage />} />
                    <Route path="/terms" element={<TermsPage />} />
                    <Route path="/privacy" element={<PrivacyPage />} />
                    <Route path="/signup" element={<SignupPage />} />
                    <Route path="/aboutus" element={<AboutUsPage />} />
                    <Route path="/contactus" element={<ContactUsPage />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </BrowserRouter>
        </React.Fragment>
    );
}

export default App;
