import axios, { AxiosResponse } from "axios";
import { GetTicketOptionsResponse } from "../Models/Responses/GetTicketOptionsResponse";

export const getTicketOptions = async (eventId: string, orderId: string): 
        Promise<AxiosResponse<GetTicketOptionsResponse>> => {
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            return error.response;
        }
    );

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    var response = await axios.get(`${baseAPIUrl}/api/TicketOptions?eventId=${eventId}&orderId=${orderId}`);
    return response;
};
