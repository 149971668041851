import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Link,
    Stack,
    Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { MainLayout } from "../components/Layouts/MainLayout";
import { theme } from "../theme/theme";
import styles from "./AboutUsPage.module.scss";
import { Helmet } from "react-helmet";

const AboutUsPage = () => {
    return (
        <MainLayout>
            <Helmet>
                <title>About Us - Nrby Events</title>
            </Helmet>
            <Card sx={{ padding: { xs: 0 }, marginTop: { xs: "30px" } }}>
                <Box style={{ position: "relative" }}>
                    <Box
                        component="div"
                        style={{
                            height: "100%",
                            position: "absolute",
                            backgroundImage: `url('/images/about-us/about-us-bg.png')`,
                            backgroundSize: "cover",
                            opacity: "0.25",
                            width: "100%",
                            zIndex: 0,
                            overflow: "hidden",
                        }}
                    >
                        &nbsp;
                    </Box>
                    <CardContent
                        className={styles.mainContent}
                        sx={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: { xl: "1250px", xs: "100%" },
                            maxWidth: { lg: "1250px" },
                            padding: { xs: "20px", lg: "20px" },
                        }}
                    >
                        <Grid
                            container
                            spacing={3}
                            sx={{
                                paddingTop: { xs: "0", md: "120px" },
                                paddingBottom: { xs: "0", md: "100px" },
                            }}
                        >
                            <Grid item xs={12} md={8} lg={5}>
                                <Stack direction="column" spacing={4}>
                                    <Typography
                                        variant="header01"
                                        textAlign="left"
                                    >
                                        About us
                                    </Typography>
                                    <Typography
                                        variant="header02"
                                        textAlign="left"
                                    >
                                        You are not just a fee.
                                    </Typography>
                                    <Typography
                                        variant="header02"
                                        textAlign="left"
                                    >
                                        You are not just a ticket.
                                    </Typography>

                                    <Typography
                                        variant="largeBody01"
                                        textAlign="left"
                                        style={{ marginBottom: "40px" }}
                                    >
                                        You are what makes an event worthwhile
                                        in the first place. Nrby Events is
                                        democratizing access to events - for
                                        both attendees and event creators. 
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Box>
                <Stack style={{ paddingTop: "40px" }} id="learnMore">
                    <Box sx={{ padding: { xs: "20px", lg: "0" } }}>
                        <Stack
                            alignItems="center"
                            sx={{
                                width: {
                                    xs: "100%",
                                    md: "75%",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    marginBottom: "50px",
                                },
                            }}
                        >
                            <Typography
                                variant="header02"
                                style={{
                                    marginTop: "30px",
                                    marginBottom: "30px",
                                    textAlign: "center",
                                }}
                            >
                                We help event creators host amazing events.
                            </Typography>

                            <Grid
                                container
                                rowSpacing={10}
                                spacing={5}
                                style={{ marginTop: "10px" }}
                            >
                                <Grid item xs={12} md={6}>
                                    <Stack direction="column" spacing={3}>
                                        <Stack
                                            direction="row"
                                            spacing={3}
                                            alignItems="end"
                                        >
                                            <img
                                                src="images/about-us/date-icon.png"
                                                style={{ width: "44px" }}
                                                alt="Feature"
                                            />
                                            <Typography
                                                variant="subheader02"
                                                style={{
                                                    color: theme.palette
                                                        .nrbyColors.tangerine,
                                                }}
                                            >
                                                Host Superior Events
                                            </Typography>
                                        </Stack>
                                        <Typography variant="largeBody01">
                                            With Nrby Events, you can host
                                            superior events. Our platform's
                                            integrated analytics and artificial
                                            intelligence provide you with the
                                            tools to determine the optimal time
                                            and location for your events.
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack direction="column" spacing={3}>
                                        <Stack
                                            direction="row"
                                            spacing={3}
                                            alignItems="end"
                                        >
                                            <img
                                                src="images/about-us/hand-icon.png"
                                                style={{ width: "44px" }}
                                                alt="Feature"
                                            />
                                            <Typography
                                                variant="subheader02"
                                                style={{
                                                    color: theme.palette
                                                        .nrbyColors.tangerine,
                                                }}
                                            >
                                                Maximize Event Revenue
                                            </Typography>
                                        </Stack>
                                        <Typography variant="largeBody01">
                                            With Nrby Events, you can increase
                                            your event revenue. Our ticketing
                                            platform and sponsorship tools
                                            enable you to maximize your
                                            earnings.
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack direction="column" spacing={3}>
                                        <Stack
                                            direction="row"
                                            spacing={3}
                                            alignItems="end"
                                        >
                                            <img
                                                src="images/about-us/ticket-icon.png"
                                                style={{ width: "44px" }}
                                                alt="Feature"
                                            />
                                            <Typography
                                                variant="subheader02"
                                                style={{
                                                    color: theme.palette
                                                        .nrbyColors.tangerine,
                                                }}
                                            >
                                                Affordable Ticketing Fees
                                            </Typography>
                                        </Stack>
                                        <Typography variant="largeBody01">
                                            With Nrby Events, your customers
                                            will appreciate our flat $2
                                            ticketing fee. Attending an event
                                            shouldn't break the bank.
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack direction="column" spacing={3}>
                                        <Stack
                                            direction="row"
                                            spacing={3}
                                            alignItems="end"
                                        >
                                            <img
                                                src="images/about-us/connected-icon.png"
                                                style={{ width: "44px" }}
                                                alt="Feature"
                                            />
                                            <Typography
                                                variant="subheader02"
                                                style={{
                                                    color: theme.palette
                                                        .nrbyColors.tangerine,
                                                }}
                                            >
                                                Stay Connected with Customers
                                            </Typography>
                                        </Stack>
                                        <Typography variant="largeBody01">
                                            With Nrby Events, you can stay
                                            connected with your customers for
                                            future events. Our platform offers a
                                            simple and direct way to
                                            communicate, helping you keep them
                                            informed about upcoming events and
                                            increase sales.
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Box>

                    <Box>
                        <Grid
                            container
                            style={{
                                backgroundColor:
                                    theme.palette.nrbyColors.tangerine,
                                position: "relative",
                            }}
                            sx={{ marginTop: { xs: "0", md: "140px" } }}
                        >
                            <Grid
                                item
                                xs={12}
                                md={8}
                                style={{
                                    backgroundColor:
                                        theme.palette.nrbyColors.gray,
                                }}
                                sx={{
                                    marginTop: { xs: "0", md: "-40px" },
                                    padding: { xs: "15px", md: "60px" },
                                    paddingTop: { xs: "45px", md: "100px" },
                                    paddingBottom: { xs: "45px", md: "100px" },
                                }}
                            >
                                <Stack
                                    direction="column"
                                    spacing={10}
                                    alignItems="center"
                                >
                                    <Typography
                                        variant="header02"
                                        style={{
                                            textAlign: "center",
                                            color: theme.palette.nrbyColors
                                                .invertedColor,
                                        }}
                                    >
                                        Enhanced Event Discovery with Nrby
                                        Events
                                    </Typography>
                                    <Typography
                                        variant="largeBody01"
                                        style={{
                                            color: theme.palette.nrbyColors
                                                .invertedColor,
                                            width: "75%",
                                        }}
                                    >
                                        Nrby Events facilitate discovering the
                                        finest local attractions.They evolve
                                        alongside your preferences, suggesting
                                        events tailored to your tastes through
                                        artificial intelligence.
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid
                                item
                                xs={0}
                                md={4}
                                sx={{ display: { xs: "none", md: "initial" } }}
                            >
                                <img
                                    alt="map"
                                    src="images/about-us/tickets-map.png"
                                    style={{
                                        marginTop: "-113px",
                                        position: "absolute",
                                        right: "2px",
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    padding: { xs: "20px" },
                                    paddingTop: { xs: "20px", md: "80px" },
                                    paddingBottom: { xs: "20px", md: "60px" },
                                }}
                            >
                                <Stack
                                    direction="column"
                                    spacing={10}
                                    style={{ width: "100%" }}
                                    sx={{
                                        paddingLeft: {
                                            sm: "20px",
                                            md: "40px",
                                            lg: "60px",
                                        },
                                        paddingRight: {
                                            sm: "20px",
                                            md: "40px",
                                            lg: "60px",
                                        },
                                    }}
                                >
                                    <Typography
                                        variant="header02"
                                        style={{
                                            color: theme.palette.nrbyColors
                                                .primaryColor,
                                        }}
                                    >
                                        Exploring Partnership Opportunities:
                                        Reach Out to Discover More
                                    </Typography>
                                    <Grid
                                        container
                                        spacing={0}
                                        rowSpacing={3}
                                        sx={{
                                            marginTop: {
                                                xs: "20px !important",
                                                md: "40px",
                                                lg: "40px",
                                            },
                                        }}
                                    >
                                        {/* <Grid item xs={0} md={1}></Grid> */}
                                        <Grid
                                            item
                                            sx={{ flexGrow: 1 }}
                                            xs={12}
                                            md={10}
                                        >
                                            <Typography variant="largeBody01">
                                                Interested in collaborating with
                                                our team or delving deeper into
                                                what we offer? Whether you're
                                                seeking partnership
                                                opportunities or simply curious
                                                to explore further, we welcome
                                                your inquiries and are eager to
                                                provide you with detailed
                                                information. Don't hesitate to
                                                reach out to us; we're here to
                                                assist you every step of the way
                                                on your journey to discovering
                                                the possibilities with us.
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={2}
                                            sx={{
                                                paddingLeft: {
                                                    xs: "0px",
                                                    md: "20px",
                                                },
                                            }}
                                        >
                                            <Link
                                                component={RouterLink}
                                                to="/contactus"
                                                style={{
                                                    marginLeft: "auto",
                                                    marginRight: "auto",
                                                }}
                                            >
                                                <Button
                                                    variant="large"
                                                    fullWidth={true}
                                                >
                                                    <Typography variant="subheader01">
                                                        Contact us
                                                    </Typography>
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <Grid
                            sx={{
                                padding: { xs: "20px" },
                                paddingTop: { xs: "20px", md: "60px" },
                            }}
                            style={{
                                paddingBottom: "80px",
                            }}
                            spacing={7}
                            justifyContent="center"
                            container
                        >
                            <Grid item xs={12} md={10}>
                                <Typography
                                    variant="header02"
                                    style={{
                                        textAlign: "center",
                                    }}
                                    sx={{
                                        marginBottom: {
                                            xs: "20px",
                                            md: "60px",
                                        },
                                    }}
                                >
                                    Our story, bringing Communities Together
                                </Typography>
                                <Typography variant="largeBody01">
                                    Nrby Events was established in 2020 amidst
                                    the pandemic, a time of physical separation
                                    between individuals and venues. Our aim was
                                    to foster connections by facilitating
                                    gatherings and experiences aligned with
                                    people's passions. Leveraging artificial
                                    intelligence, we guide you in discovering
                                    activities tailored to your preferences,
                                    alleviating the apprehension of missing out
                                    on ideal events. Committed to this vision,
                                    we developed a comprehensive toolkit
                                    empowering event organizers to curate
                                    exceptional experiences. Nrby was crafted to
                                    seamlessly connect individuals with events
                                    they adore, utilizing AI to align with their
                                    interests. Our events also identify which of
                                    your friends are likely to share your
                                    enjoyment, simplifying group attendance.
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* <Grid
                            container
                            spacing={3}
                            sx={{ padding: { xs: "20px", md: "40px" } }}
                            style={{
                                paddingBottom: "80px",
                            }}
                        >
                            <Grid item xs={12} sm={6} md={3}>
                                <Stack spacing={2}>
                                    <img
                                        src="/images/about-us/adam.png"
                                        alt="ADAM HAMMAD"
                                        style={avatarStyle}
                                    />
                                    <Typography variant="header02">
                                        ADAM HAMMAD
                                    </Typography>
                                    <Typography
                                        variant="subheader01"
                                        style={{
                                            color: theme.palette.nrbyColors
                                                .tangerine,
                                        }}
                                    >
                                        Co-CEO
                                    </Typography>
                                    <Typography
                                        variant="body01"
                                        style={briefTextStyle}
                                    >
                                        Experienced and tenacious operator,
                                        having ran three profitable
                                        multi-million dollar business
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <Stack spacing={2}>
                                    <img
                                        src="/images/about-us/rob.png"
                                        alt="ROB FLESSNER"
                                        style={avatarStyle}
                                    />
                                    <Typography variant="header02">
                                        ROB FLESSNER
                                    </Typography>
                                    <Typography
                                        variant="subheader01"
                                        style={{
                                            color: theme.palette.nrbyColors
                                                .tangerine,
                                        }}
                                    >
                                        Co-CEO
                                    </Typography>
                                    <Typography
                                        variant="body01"
                                        style={briefTextStyle}
                                    >
                                        Award winning and seasoned product
                                        focused startup founder and CEO, scaling
                                        numerous products from conception
                                        through market adoption
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <Stack spacing={2}>
                                    <img
                                        src="/images/about-us/tony.png"
                                        alt="TONY LAMARTINA"
                                        style={avatarStyle}
                                    />
                                    <Typography variant="header02">
                                        TONY LAMARTINA
                                    </Typography>
                                    <Typography
                                        variant="subheader01"
                                        style={{
                                            color: theme.palette.nrbyColors
                                                .tangerine,
                                        }}
                                    >
                                        Head of Back End Development
                                    </Typography>
                                    <Typography
                                        variant="body01"
                                        style={briefTextStyle}
                                    >
                                        Data scientist with extensive back end
                                        development experience
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <Stack spacing={2}>
                                    <img
                                        src="/images/about-us/kelly.png"
                                        alt="KELLY PANGBURN"
                                        style={avatarStyle}
                                    />
                                    <Typography variant="header02">
                                        KELLY PANGBURN
                                    </Typography>
                                    <Typography
                                        variant="subheader01"
                                        style={{
                                            color: theme.palette.nrbyColors
                                                .tangerine,
                                        }}
                                    >
                                        Head of Front End Development
                                    </Typography>
                                    <Typography
                                        variant="body01"
                                        style={briefTextStyle}
                                    >
                                        Over 20 year of experience in
                                        development with numerous products built
                                        to market adoption
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid> */}
                    </Box>
                    <Box
                        style={{
                            backgroundColor: theme.palette.nrbyColors.tangerine,
                            paddingTop: "60px",
                            paddingBottom: "80px",
                        }}
                        sx={{ padding: { xs: "20px", lg: "0" } }}
                    >
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="center"
                            justifyItems="center"
                            rowSpacing={3}
                        >
                            <Grid item xs={0} md={1}></Grid>
                            <Grid item xs={12} md={9}>
                                <Stack
                                    style={{
                                        maxWidth: "1100px",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                    }}
                                    spacing={7}
                                    sx={{
                                        marginTop: { xs: "20px", md: "60px" },
                                        marginBottom: {
                                            xs: "20px",
                                            md: "60px",
                                        },
                                    }}
                                >
                                    <Typography
                                        variant="header02"
                                        style={{
                                            color: theme.palette.nrbyColors
                                                .primaryColor,
                                        }}
                                    >
                                        See our latest news and blog articles
                                    </Typography>
                                    <Typography
                                        variant="headliner"
                                        style={{ textTransform: "none" }}
                                    >
                                        Explore insights, stories, and updates
                                        on our blog, where we share the latest
                                        trends, tips, and inspiration. Dive into
                                        a world of enriching content crafted to
                                        elevate your event experiences and
                                        deepen your connection with Nrby Events.
                                        Stay informed, engaged, and inspired as
                                        you journey through our blog. Ready to
                                        delve in? Click below to discover more.
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={2}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Link
                                    component={RouterLink}
                                    to="https://nrby.io/blog/"
                                >
                                    <Button variant="large">
                                        <Typography variant="subheader01">
                                            Explore Our Blog
                                        </Typography>
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Stack>
            </Card>
        </MainLayout>
    );
};

export default AboutUsPage;
