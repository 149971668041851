import { FC } from "react";

const EventDateIcon: FC = () => {
    return (
        <svg
            style={{ marginTop: "auto", marginBottom: "auto" }}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.7326 0H8.53359V1.59982C8.53359 1.89437 8.29481 2.13315 8.00026 2.13315C7.70571 2.13315 7.46693 1.89437 7.46693 1.59982V0H4.26602V1.59982C4.26602 1.89437 4.02723 2.13315 3.73268 2.13315C3.43813 2.13315 3.19935 1.89437 3.19935 1.59982V0H2C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H12.7992V1.59982C12.7992 1.89437 12.5604 2.13315 12.2659 2.13315C11.9713 2.13315 11.7326 1.89437 11.7326 1.59982V0ZM2.13262 11.6668C2.13262 11.1145 2.58033 10.6668 3.13262 10.6668H4.33262C4.8849 10.6668 5.33262 11.1145 5.33262 11.6668V12.8668C5.33262 13.419 4.8849 13.8668 4.33262 13.8668H3.13262C2.58033 13.8668 2.13262 13.419 2.13262 12.8668V11.6668ZM3.13271 5.33317C2.58043 5.33317 2.13271 5.78089 2.13271 6.33317V7.53317C2.13271 8.08545 2.58043 8.53317 3.13272 8.53317H4.33272C4.885 8.53317 5.33272 8.08545 5.33272 7.53317V6.33317C5.33272 5.78089 4.885 5.33317 4.33271 5.33317H3.13271ZM6.39834 6.33317C6.39834 5.78089 6.84606 5.33317 7.39834 5.33317H8.59834C9.15062 5.33317 9.59834 5.78089 9.59834 6.33317V7.53317C9.59834 8.08545 9.15062 8.53317 8.59834 8.53317H7.39834C6.84606 8.53317 6.39834 8.08545 6.39834 7.53317V6.33317ZM11.6659 5.33317C11.1136 5.33317 10.6659 5.78089 10.6659 6.33317V7.53317C10.6659 8.08545 11.1136 8.53317 11.6659 8.53317H12.8659C13.4182 8.53317 13.8659 8.08545 13.8659 7.53317V6.33317C13.8659 5.78089 13.4182 5.33317 12.8659 5.33317H11.6659ZM6.39834 11.6672C6.39834 11.1149 6.84606 10.6672 7.39834 10.6672H8.59834C9.15062 10.6672 9.59834 11.1149 9.59834 11.6672V12.8672C9.59834 13.4194 9.15062 13.8672 8.59834 13.8672H7.39834C6.84606 13.8672 6.39834 13.4194 6.39834 12.8672V11.6672Z"
                fill="#F6F6F6"
            />
        </svg>
    );
};

export default EventDateIcon;
