import { Components } from "@mui/material/styles/components";

export const MuiPagination: Components = {
    MuiPagination: {
        styleOverrides: {
            root: {
                fontSize: "20px",
                "& button.MuiPaginationItem-text": {
                    fontSize: "20px",
                },
                "& .MuiPaginationItem-root.Mui-selected": {
                    backgroundColor: "black",
                    color: "#E53F5C",
                },
            },
        },
        variants: [
            {
                props: { variant: "inverted" },
                style: {
                    fontSize: "20px",
                    color: "black",
                    textTransform: "none",
                    "& .MuiPaginationItem-root": {
                        fontSize: "20px",
                        color: "black",
                    },
                    "& button.MuiPaginationItem-text": {
                        fontSize: "20px",
                    },
                    "& .MuiPaginationItem-root.Mui-selected": {
                        backgroundColor: "white",
                        color: "#E53F5C",
                    },
                },
            },
        ]
    },
};
