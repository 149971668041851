const scrollWithOffset = (id: string) => {
    setTimeout(() => {
        const menus = document.getElementsByTagName("header");
        const menu = menus ? menus[0] : undefined;
        const element = document.getElementById(id);
        if (menu && element) {
            window.scrollTo({
                top: element.offsetTop - menu!.offsetHeight,
                behavior: "smooth",
            });
        }
    }, 50);
};

export default scrollWithOffset;
