import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Button, Stack, Typography } from "@mui/material";
import * as React from "react";
import { theme } from "../../../theme/theme";

const ticketsCountBoxStyle: React.CSSProperties = {
    backgroundColor: theme.palette.nrbyColors.invertedBgColor,
    color: theme.palette.nrbyColors.invertedColor,
    borderRadius: "10px",
    height: "60px",
    marginTop: 4,
    marginBottom: 4,
    padding: "10px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: theme.palette.nrbyColors.darkGray,
    transition: "color 0.3s, background-color 0.3s",
};

const ticketsCountBoxStyleActive: React.CSSProperties = {
    backgroundColor: theme.palette.nrbyColors.tangerine,
    color: theme.palette.nrbyColors.primaryColor,
    borderRadius: "10px",
    height: "60px",
    marginTop: 4,
    marginBottom: 4,
    padding: "10px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: theme.palette.nrbyColors.darkGray,
    transition: "color 0.3s, background-color 0.3s",
};

const ticketsCountBoxStyleDisabled: React.CSSProperties = {
    backgroundColor: theme.palette.nrbyColors.invertedBgColor,
    opacity: "25%",
    color: theme.palette.nrbyColors.invertedColor,
    borderRadius: "10px",
    height: "60px",
    marginTop: 4,
    marginBottom: 4,
    padding: "10px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: theme.palette.nrbyColors.darkGray,
};

const minusButtonStyle: React.CSSProperties = {
    backgroundColor: theme.palette.nrbyColors.gray,
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.nrbyColors.darkGray,
    height: "40px",
    width: "40px",
    borderRadius: "10px",
    paddingLeft: 0,
    paddingRight: 0,
    color: theme.palette.nrbyColors.invertedColor,
    display: "inline-block",
    minWidth: "initial",
};

const plusButtonStyle: React.CSSProperties = {
    backgroundColor: theme.palette.nrbyColors.gray,
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.nrbyColors.darkGray,
    height: "40px",
    width: "40px",
    borderRadius: "10px",
    color: theme.palette.nrbyColors.invertedColor,
    flexGrow: 0,
    minWidth: "initial",
};

interface TicketsCountProps {
    maxTickets: number;
    disabledEditor: boolean;
    onChange: (tickets: number) => void;
}

const TicketsCountBox = ({
    maxTickets,
    disabledEditor,
    onChange,
}: TicketsCountProps) => {
    const [ticketsCount, setTicketsCount] = React.useState(0);

    const HandlePlusClick = () => {
        var newValue = ticketsCount + 1;
        onChange(newValue);
        if (ticketsCount < maxTickets) setTicketsCount(newValue);
    };

    const HandleMinusClick = () => {
        var newValue = ticketsCount - 1;
        onChange(newValue);
        if (ticketsCount > 0) setTicketsCount(newValue);
    };

    return (
        <Box
            style={
                maxTickets === 0
                    ? ticketsCountBoxStyleDisabled
                    : ticketsCount > 0
                    ? ticketsCountBoxStyleActive
                    : ticketsCountBoxStyle
            }
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Box>
                    <Button
                        style={minusButtonStyle}
                        onClick={HandleMinusClick}
                        disabled={disabledEditor}
                    >
                        <RemoveIcon />
                    </Button>
                </Box>
                <Typography variant="body01">
                    {ticketsCount}&nbsp;
                    {ticketsCount <= 1 && (
                        <React.Fragment>Ticket</React.Fragment>
                    )}
                    {ticketsCount > 1 && (
                        <React.Fragment>Ticket</React.Fragment>
                    )}
                </Typography>
                <Button
                    style={plusButtonStyle}
                    onClick={HandlePlusClick}
                    disabled={disabledEditor}
                >
                    <AddIcon />
                </Button>
            </Stack>
        </Box>
    );
};
export default TicketsCountBox;
