import { Stack, Typography } from "@mui/material";
import { CSSProperties } from "react";
import { EventPublicDetailsInfoModel } from "../../data/Models/EventPublicDetailsInfoModel";
import { PreviewOrderEventTicketsRequest } from "../../data/Models/Requests/PreviewOrderEventTicketsRequest";
import { OrderEventTicketsResponse } from "../../data/Models/Responses/OrderEventTicketsResponse";
import { theme } from "../../theme/theme";
import StripePaymentForm from "./StripePaymentElement/StripePaymentForm";

const paymentInfoStyles: CSSProperties = {
    backgroundColor: theme.palette.nrbyColors.faintGray,
    color: theme.palette.nrbyColors.invertedColor,
    justifyContent: "center",
    display: "flex",
    padding: "16px",
    paddingTop: "0",
};

interface PaymentMethodStepProps {
    event: EventPublicDetailsInfoModel | undefined;
    orderPreview: OrderEventTicketsResponse;
    order: PreviewOrderEventTicketsRequest | undefined;
}

const PaymentMethodStep = ({
    event,
    orderPreview,
    order,
}: PaymentMethodStepProps) => {
    return (
        <Stack direction="column" style={paymentInfoStyles} spacing={2}>
            <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="subheader02">Payment Method</Typography>
            </Stack>
            <StripePaymentForm
                event={event}
                orderPreview={orderPreview}
                order={order}
            />
        </Stack>
    );
};

export default PaymentMethodStep;
