import { Card, CardMedia, Link, Stack, Typography } from "@mui/material";
import { MainLayout } from "../components/Layouts/MainLayout";
import TopImage from "../assets/images/homepage-top.jpg";
import styles from "./FeaturedEventsPage.module.scss";
import FeaturedEvents from "../components/FeaturedEvents/FeaturedEvents";

const FeaturedEventsPage = () => {
    return (
        <MainLayout>
            <Card>
                <div style={{ position: "relative" }}>
                    <CardMedia style={{}} component="img" image={TopImage} />
                    <div className={styles.mainContent}>
                        <Typography variant="header01" sx={{fontSize: {
                                            xs: "70px",
                                            sm: "80px",
                                        }}}>
                            Discover What's Happening!
                        </Typography>
                        <Stack
                            spacing={{ xs: 1, sm: 4 }}
                            mt={{ xs: 2, sm: 4 }}
                            className={styles.subheadersBlock}
                        >
                            <Typography
                                variant="header02"
                                className={styles.header02}
                            >
                                Browse All
                                <span className={styles.nrbyText}> Nrby </span>
                                Upcoming Events
                            </Typography>
                            <Typography
                                variant="body01"
                                className={styles.description}
                            >
                                Welcome to our comprehensive directory of
                                upcoming events! Whether you're looking for
                                something specific or just want to explore and
                                discover something new, you've come to the right
                                place. Browse our full list of events to find
                                something that interests you, from festivals and
                                concerts to conferences and exhibitions.
                            </Typography>
                        </Stack>
                    </div>
                </div>
            </Card>
            <FeaturedEvents viewAllBtnVisible={true}/>
        </MainLayout>
    );
};

export default FeaturedEventsPage;
