import {
    Box,
    Card,
    CardContent,
    CardMedia,
    Chip,
    Grid,
    Link,
    Stack,
    Typography,
} from "@mui/material";
import { CSSProperties } from "react";
import { Link as RouterLink } from "react-router-dom";
import defaultImage from "../../../assets/images/default-event-image.png";
import { DateFormatter } from "../../../data/Formatters/DateFormatter";
import { EventPublicInfoModel } from "../../../data/Models/EventPublicInfoModel";
import { theme } from "../../../theme/theme";
import { EventStatusCode, EventStatusCodeToString } from "../../../types/models/enums";
import CategoryInvertedIcon from "../../Icons/Categories/CategoryInvertedIcon";
import EventDateInvertedIcon from "../../Icons/DateTime/EventDateInvertedIcon";
import EventTimeInvertedIcon from "../../Icons/DateTime/EventTimeInvertedIcon";
import styles from "./EventCard.module.scss";

const eventCard: CSSProperties = {
    backgroundColor: theme.palette.nrbyColors.primaryBgColor,
    color: theme.palette.nrbyColors.primaryColor,
    position: "relative",
    overflow: "visible",

    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
};
const eventImage: CSSProperties = {
    height: 100,
    width: "auto",
    maxWidth: "400px",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
};
const invertedCard: CSSProperties = {
    backgroundColor: theme.palette.nrbyColors.invertedBgColor,
    color: theme.palette.nrbyColors.invertedColor,
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
    maxWidth: "400px",
    flexGrow: 1,
};

const descriptionStyle: CSSProperties = {
    maxHeight: "3.5em",
};

interface EventCardProps {
    event: EventPublicInfoModel;
}

const salesEndedPanelStyle: CSSProperties = {
    width: "100%",
    height: "100%",
    position: "absolute",
    borderRadius: "20px",
    zIndex: "10",
    backgroundColor: "#40404080",
    textAlign: "center",
    paddingTop: "20px",
    marginRight: "20px",
};

const EventCard = (props: EventCardProps) => {
    let eventData = props.event;
    let mainImage = defaultImage;
    if (eventData && eventData.images && eventData.images[0])
        mainImage = eventData.images[0];

    let statusText: string | undefined = undefined;
    if (eventData && eventData.status != EventStatusCode.OnSale)
        statusText = EventStatusCodeToString(eventData.status);

    return (
        <Grid item xs={12} sm={6} md={4} lg={3} style={eventCard}>
            <Link
                component={RouterLink}
                to={"/event?id=" + props.event.id}
                style={{ height: "100%" }}
            >
                <Stack style={{ height: "100%", position: "relative" }}>
                    {statusText && (
                        <div style={salesEndedPanelStyle}>
                            <Typography variant="subheader01">
                                {statusText}
                            </Typography>
                        </div>
                    )}
                    <CardMedia
                        sx={{}}
                        style={eventImage}
                        image={mainImage}
                        title={props.event.name}
                    />
                    <CardContent style={invertedCard} component={Stack}>
                        <Stack direction="column" sx={{ height: "100%" }}>
                            <Typography
                                component="div"
                                variant="body02"
                                style={{
                                    fontWeight: 700,
                                    color: theme.palette.nrbyColors
                                        .invertedColor,
                                }}
                            >
                                {props.event.name}
                            </Typography>

                            <Typography
                                component="div"
                                variant="body02"
                                mt={1}
                                className={styles.eventCardDescription}
                            >
                                {props.event.description}
                            </Typography>
                        </Stack>

                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            style={{ flexGrow: 1, alignItems: "flex-end" }}
                            mt={1}
                        >
                            <Box ml={2} mt={1} style={{ display: "flex" }}>
                                <CategoryInvertedIcon
                                    {...{
                                        categoryIconFilename:
                                            props.event.categoryIconFilename,
                                    }}
                                />
                            </Box>
                            <Stack>
                                <Typography variant="smallCaption">
                                    <EventDateInvertedIcon />
                                    &nbsp;&nbsp;
                                    {DateFormatter(
                                        props.event.startDateTimeLocal
                                    )}
                                </Typography>
                                <Typography
                                    variant="smallCaption"
                                    style={{ whiteSpace: "nowrap" }}
                                >
                                    <EventTimeInvertedIcon />
                                    &nbsp;&nbsp;
                                    {props.event.startTimeLocal}
                                </Typography>
                            </Stack>
                        </Stack>
                    </CardContent>
                    <Chip
                        label={props.event.region}
                        size="small"
                        variant="outlined"
                        sx={{
                            position: "absolute",
                            top: "10px",
                            left: "10px",
                        }}
                    />
                </Stack>
            </Link>
        </Grid>
    );
};

export default EventCard;
