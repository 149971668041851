import { AgeRestrictionCode } from "../../types/models/enums";

const AgeRestriction = (ageRestriction: AgeRestrictionCode) => {
    switch (ageRestriction) {
        case AgeRestrictionCode.None:
            return <span>All Ages</span>;
        case AgeRestrictionCode.LegalAdult:
            return <span>18+</span>;
        case AgeRestrictionCode.TwentyOnePlus:
            return <span>21+</span>;
    }
};

export default AgeRestriction;
