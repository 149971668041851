import { FC } from "react";

const EventTimeIcon: FC = () => {
    return (
        <svg
            style={{ marginTop: "auto", marginBottom: "auto" }}
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM6.5 2C6.5 1.72386 6.27614 1.5 6 1.5C5.72386 1.5 5.5 1.72386 5.5 2V6C5.5 6.27614 5.72386 6.5 6 6.5H9C9.27614 6.5 9.5 6.27614 9.5 6C9.5 5.72386 9.27614 5.5 9 5.5H6.5V2Z"
                fill="black"
            />
        </svg>
    );
};

export default EventTimeIcon;
