import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import TopImage from "../assets/images/homepage-top.jpg";
import BrowseEvents from "../components/BrowseEvents/BrowseEvents";
import FeaturedEvents from "../components/FeaturedEvents/FeaturedEvents";
import { MainLayout } from "../components/Layouts/MainLayout";
import styles from "./AllEventsPage.module.scss";

const AllEventsPage = () => {
    return (
        <MainLayout>
            <Card sx={{ padding: { xs: 1 } }}>
                <div style={{ position: "relative" }}>
                    {/* <CardMedia style={{}} component="img" image={TopImage} /> */}
                    <Box
                        component="div"
                        style={{
                            height: "100%",
                            position: "absolute",
                            backgroundImage: `url('${TopImage}')`,
                            backgroundSize: "cover",
                            filter: "brightness(250%)",
                            width: "100%",
                            zIndex: 0,
                            overflow: "hidden",
                        }}
                    >
                        &nbsp;
                    </Box>
                    <CardContent
                        className={styles.mainContent}
                        sx={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: { xl: "1250px", xs: "100%" },
                            maxWidth: { lg: "1250px" },
                            padding: { xs: "20px", lg: "0" },
                        }}
                    >
                        <Stack
                            spacing={{ xs: 1, sm: 4 }}
                            alignItems="center"
                            sx={{
                                marginBottom: { xs: 2, sm: 4, lg: 8 },
                            }}
                            className={styles.subheadersBlock}
                        >
                            <Typography
                                variant="header01"
                                textAlign="center"
                                sx={{
                                    paddingTop: { xs: 2, sm: 4, lg: 16 },
                                    fontSize: {
                                        xs: "64px",
                                        sm: "80px",
                                    },
                                }}
                            >
                                Discover What's Happening!
                            </Typography>
                            <Typography
                                variant="header02"
                                className={styles.header02}
                                sx={{
                                    maxWidth: { lg: "874px" },
                                }}
                            >
                                Browse All
                                <span className={styles.nrbyText}> Nrby </span>
                                Upcoming Events
                            </Typography>
                            <Typography
                                variant="body01"
                                sx={{
                                    maxWidth: { lg: "874px" },
                                }}
                                className={styles.description}
                            >
                                Welcome to our comprehensive directory of
                                upcoming events! Whether you're looking for
                                something specific or just want to explore and
                                discover something new, you've come to the right
                                place. Browse our full list of events to find
                                something that interests you, from festivals and
                                concerts to conferences and exhibitions.
                            </Typography>
                            {/* <Stack alignItems="center">
                                <Typography variant="subheader02" mb={1}>
                                    Browse Now
                                </Typography>
                                <svg
                                    width="27"
                                    height="13"
                                    viewBox="0 0 27 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1.5 1L13.5 11L25.5 1"
                                        stroke="white"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                    />
                                </svg>
                            </Stack> */}
                        </Stack>
                    </CardContent>
                </div>
            </Card>
            <FeaturedEvents viewAllBtnVisible={false} />
            <BrowseEvents />
            {/* <RelatabilitySection /> */}
        </MainLayout>
    );
};

export default AllEventsPage;
