import { Box, Card, CardMedia, Typography } from "@mui/material";
import { CSSProperties } from "react";
import TopImage from "../assets/images/homepage-top.jpg";
import { MainLayout } from "../components/Layouts/MainLayout";

const mainContent: CSSProperties = {
    marginTop: "80px",
    maxWidth: "1150px",
    marginLeft: "auto",
    marginRight: "auto",
    // position: "absolute",
    // color: "white",
    // top: "40%",
    // left: "50%",
    // transform: "translateX(-50%)",
    // width: "100%",
    // textAlign: "left",
    // verticalAlign: "middle",
    // marginTop: "auto",
    // marginBottom: "auto",
};

const TermsPage = () => {
    return (
        <MainLayout>
            <Card>
                <Box style={{ position: "relative" }}>
                    {/* <CardMedia style={{}} component="img" image={TopImage} /> */}
                    <Box style={mainContent}>
                        <Typography variant="header02">
                            Nrby.io Terms and Conditions
                        </Typography>
                        <Typography variant="subheader02">
                            LAST UPDATED: MARCH 24, 2022
                        </Typography>
                        <Typography variant="body1">
                            These Terms and Conditions (“Agreement”) are entered
                            into between Nrby.io, Inc. with a place of business
                            at 1161 Wayzata Blvd E. Suite #172 Wayzata, MN
                            55391(“NRBY”), and the application user (“User”).
                            Nrby provides a software application (“App”) that
                            connects Users with nearby activities and events
                            (“Events”). This Agreement includes and incorporates
                            these Terms and Conditions and contains, among other
                            things, warranty disclaimers, liability limitations
                            and use limitations. There shall be no force or
                            effect to any different terms of any related
                            purchase order or similar form even if signed by the
                            parties after the date hereof.
                        </Typography>
                        <Typography variant="body1">
                            1.0 SOFTWARE SERVICES AND SUPPORT
                        </Typography>
                        <Typography variant="body1">
                            1.1 Subject to the terms of this Agreement, Nrby
                            will use commercially reasonable efforts to provide
                            the App to Users. As part of the registration
                            process, Users will create a user name and password
                            for their Nrby account, and may provide additional
                            information governed by the Nrby Privacy and Cookie
                            Policy. Nrby reserves the right to refuse
                            registration of, or cancel passwords it deems
                            inappropriate.
                        </Typography>
                        <Typography variant="body1">
                            1.2 Nrby will provide Users with reasonable business
                            support for any paid services during the Terms of
                            this Agreement. Users are encouraged to report
                            issues via e-mail to .
                        </Typography>
                        <Typography variant="body1">
                            2.0 RESTRICTIONS AND RESPONSIBILITIES
                        </Typography>
                        <Typography variant="body1">
                            2.1 Users will not, directly or indirectly: reverse
                            engineer, decompile, disassemble or otherwise
                            attempt to discover the source code, object code or
                            underlying structure, ideas, know-how or algorithms
                            relevant to the Services or any software,
                            documentation or data related to the Services
                            (“Software”); modify, translate, or create
                            derivative works based on the Services or any
                            Software (except to the extent expressly permitted
                            by Nrby or authorized within the Services); use the
                            Services in any manner not expressly permitted by
                            Nrby.
                        </Typography>
                        <Typography variant="body1">
                            2.2 Further, Users may not remove or export from the
                            United States or allow the export or re-export of
                            the Services, Software or anything related thereto,
                            or any direct product thereof in violation of any
                            restrictions, laws or regulations of the United
                            States Department of Commerce, the United States
                            Department of Treasury Office of Foreign Assets
                            Control, or any other United States or foreign
                            agency or authority. As defined in FAR section
                            2.101, the Software and documentation are
                            “commercial items” and according to DFAR section
                            252.2277014(a)(1) and (5) are deemed to be
                            “commercial computer software” and “commercial
                            computer software documentation.” Consistent with
                            DFAR section 227.7202 and FAR section 12.212, any
                            use modification, reproduction, release,
                            performance, display, or disclosure of such
                            commercial software or commercial software
                            documentation by the U.S. Government will be
                            governed solely by the terms of this Agreement and
                            will be prohibited except to the extent expressly
                            permitted by the terms of this Agreement.
                        </Typography>
                        <Typography variant="body1">
                            2.3 User represents, covenants, and warrants that
                            User will use the App only in compliance with Nrby’s
                            standard published policies then in effect and all
                            applicable laws and regulations. User hereby agrees
                            to indemnify and hold harmless Nrby against any
                            damages, losses, liabilities, settlements and
                            expenses (including without limitation costs and
                            attorneys’ fees) in connection with any claim or
                            action that arises from an alleged violation of the
                            foregoing or otherwise from User’s use of Services.
                        </Typography>
                        <Typography variant="body1">
                            2.4 Although Nrby has no obligation to monitor
                            User’s use of the Services, Nrby may do so and may
                            prohibit any use of the Services it believes may be
                            (or alleged to be) in violation of the foregoing.
                        </Typography>
                        <Typography variant="body1">
                            2.5 User shall be responsible for their own
                            equipment and faculties while using the App or
                            attending any Event. Nrby is not responsible for
                            User safety, privacy, or other liability for a User
                            attending an Event recommended by or discovered on
                            Nrby. User shall also be responsible for maintaining
                            the security of their account, passwords, files, and
                            records in relation to their use of the App –
                            whether that is with or without User’s knowledge or
                            consent.
                        </Typography>
                        <Typography variant="body1">
                            3.0 CONFIDENTIALITY; PROPRIETARY RIGHTS{" "}
                        </Typography>
                        <Typography variant="body1">
                            3.1 The User understands that the Nrby has disclosed
                            or may disclose business, technical or financial
                            information relating to the Disclosing Party’s
                            business (hereinafter referred to as “Proprietary
                            Information” of the Disclosing Party). Proprietary
                            Information of Nrby includes non-public information
                            regarding features, functionality and performance of
                            the Service. Proprietary Information of User
                            includes non-public data provided by User to Nrby to
                            enable the usage and provisions of the App (“User
                            Data”). The Receiving Party agrees: (i) to take
                            reasonable precautions to protect such Proprietary
                            Information, and (ii) not to use (except in
                            performance of the App or as otherwise permitted
                            herein) or divulge to any third person any such
                            Proprietary Information. The Disclosing Party agrees
                            that the foregoing shall not apply with respect to
                            any information after five (5) years following the
                            disclosure thereof or any information that the
                            Receiving Party can document (a) is or becomes
                            generally available to the public, or (b) was in its
                            possession or known by it prior to receipt from the
                            Disclosing Party, or (c) was rightfully disclosed to
                            it without restriction by a third party, or (d) was
                            independently developed without use of any
                            Proprietary Information of the Disclosing Party or
                            (e) is required to be disclosed by law.
                        </Typography>
                        <Typography variant="body1">
                            3.2 Nrby shall own all right, title and interest in
                            and to the User Data, as well as any data that is
                            based on or derived from the User Data and provided
                            to User as part of the App and Services. Nrby shall
                            own and retain all right, title and interest in and
                            to (a) the Services and Software, all improvements,
                            enhancements or modifications thereto, (b) any
                            software, applications, inventions or other
                            technology developed in connection with
                            Implementation Services or support, and (c) all
                            intellectual property rights related to any of the
                            foregoing.
                        </Typography>
                        <Typography variant="body1">
                            4.0 TERM AND TERMINATION
                        </Typography>
                        <Typography variant="body1">
                            4.1 Subject to earlier termination as provided
                            below, this Agreement is for the Initial Service
                            Term as specified in the Order Form, and shall be
                            automatically renewed for additional periods of the
                            same duration as the Initial Service Term
                            (collectively, the “Term”), unless either party
                            requests termination at least thirty (30) days prior
                            to the end of the then-current term.
                        </Typography>
                        <Typography variant="body1">
                            4.2 Nrby may terminate or amend these Terms and
                            Conditions without cause at any time.
                        </Typography>
                        <Typography variant="body1">
                            4.3 All sections of this Agreement which by their
                            nature should survive termination will survive
                            termination, including, without limitation,
                            confidentiality obligations, warranty disclaimers,
                            and limitations of liability.
                        </Typography>
                        <Typography variant="body1">
                            5.0 WARRANTY AND DISCLAIMER
                        </Typography>
                        <Typography variant="body1">
                            Nrby shall use reasonable efforts consistent with
                            prevailing industry standards to maintain the
                            Services in a manner which minimizes errors and
                            interruptions in the Services. Services may be
                            temporarily unavailable for scheduled maintenance or
                            for unscheduled emergency maintenance, either by
                            Nrby or by third-party providers, or because of
                            other causes beyond Nrby’s reasonable control, but
                            Nrby shall use reasonable efforts to provide advance
                            notice in writing or by e-mail of any scheduled
                            service disruption. HOWEVER, NRBY DOES NOT WARRANT
                            THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR
                            FREE; NOR DOES IT MAKE ANY WARRANTY AS TO THE
                            RESULTS THAT MAY BE OBTAINED FROM USE OF THE
                            SERVICES. EXCEPT AS EXPRESSLY SET FORTH IN THIS
                            SECTION, THE SERVICES AND IMPLEMENTATION SERVICES
                            ARE PROVIDED “AS IS” AND NRBY DISCLAIMS ALL
                            WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT
                            LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY
                            AND FITNESS FOR A PARTICULAR PURPOSE AND
                            NON-INFRINGEMENT.
                        </Typography>
                        <Typography variant="body1">
                            6.0 LIMITATION OF LIABILITY
                        </Typography>
                        <Typography variant="body1">
                            NOTWITHSTANDING ANYTHING TO THE CONTRARY, EXCEPT FOR
                            BODILY INJURY OF A PERSON, NRBY AND ITS SUPPLIERS
                            (INCLUDING BUT NOT LIMITED TO ALL EQUIPMENT AND
                            TECHNOLOGY SUPPLIERS), OFFICERS, AFFILIATES,
                            REPRESENTATIVES, CONTRACTORS AND EMPLOYEES SHALL NOT
                            BE RESPONSIBLE OR LIABLE UNDER ANY CONTRACT,
                            NEGLIGENCE, STRICT LIABILITY OR OTHER THEORY: (A)
                            FOR ERROR OR INTERRUPTION OF USE OR FOR LOSS OR
                            INACCURACY OR CORRUPTION OF DATA OR COST OF
                            PROCUREMENT OF SUBSTITUTE GOODS, SERVICES OR
                            TECHNOLOGY OR LOSS OF BUSINESS; (B) FOR ANY
                            INDIRECT, EXEMPLARY, INCIDENTAL, SPECIAL OR
                            CONSEQUENTIAL DAMAGES; (C) FOR ANY MATTER BEYOND
                            NRBY’S REASONABLE CONTROL; OR (D) FOR ANY AMOUNTS
                            THAT, TOGETHER WITH AMOUNTS ASSOCIATED WITH ALL
                            OTHER CLAIMS, EXCEED THE FEES PAID BY USER TO NRBY
                            FOR THE SERVICES UNDER THIS AGREEMENT IN THE 12
                            MONTHS PRIOR TO THE ACT THAT GAVE RISE TO THE
                            LIABILITY, IN EACH CASE, WHETHER OR NOT NRBY HAS
                            BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                        </Typography>
                        <Typography variant="body1">
                            7.0 MISCELLANEOUS
                        </Typography>
                        <Typography variant="body1">
                            If any provision of this Agreement is found to be
                            unenforceable or invalid, that provision will be
                            limited or eliminated to the minimum extent
                            necessary so that this Agreement will otherwise
                            remain in full force and effect and enforceable.
                            This Agreement is not assignable, transferable or
                            sublicensable by User except with Nrby’s prior
                            written consent. Nrby may transfer and assign any of
                            its rights and obligations under this Agreement
                            without consent. This Agreement is the complete and
                            exclusive statement of the mutual understanding of
                            the parties and supersedes and cancels all previous
                            written and oral agreements, communications and
                            other understandings relating to the subject matter
                            of this Agreement, and that all waivers and
                            modifications must be in a writing signed by both
                            parties, except as otherwise provided herein. No
                            agency, partnership, joint venture, or employment is
                            created as a result of this Agreement and User does
                            not have any authority of any kind to bind Nrby in
                            any respect whatsoever. Al notices under this
                            Agreement will be in writing and will be deemed to
                            have been duly given when received, if personally
                            delivered; when receipt is electronically confirmed,
                            if transmitted by facsimile or e-mail; the day after
                            it is sent, if sent for next day delivery by
                            recognized overnight delivery service; and upon
                            receipt, if sent by certified or registered mail,
                            return receipt requested. This Agreement shall be
                            governed by the laws of the State of Minnesota
                            without regard to its conflict of laws provisions.
                            Any such action must be brought in the state or
                            federal courts in Minnesota, and all parties hereto
                            expressly consent to personal jurisdiction in
                            Minnesota.
                        </Typography>
                        <Typography variant="body1">Contact Us</Typography>
                        <Typography variant="body1">
                            If you have any questions about these Terms and
                            Conditions, You can contact us:
                        </Typography>
                        <Typography variant="body1">By email:</Typography>
                        <Typography variant="body1">
                            By mail: 1161 Wayzata Blvd E., Suite 172, Wayzata,
                            MN 55391
                        </Typography>
                    </Box>
                </Box>
            </Card>
        </MainLayout>
    );
};

export default TermsPage;
