const moment = require('moment');

export const DateFormatter =  (date: Date | undefined): string => {
    if (!date)
        return "N/A";
    return moment(date).calendar(null, {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'ddd MM/DD'
    });
};