import axios, { AxiosResponse } from "axios";
import { RegionModel } from "../Models/RegionModel";

export const getRegions = async (): Promise<AxiosResponse<RegionModel[]>> => {
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            return error.response;
        }
    );

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    var response = await axios.get(`${baseAPIUrl}/api/Region/getActiveRegions`);
    return response;
};
