import { Breadcrumbs, Link, Typography } from "@mui/material";
import { theme } from "../../theme/theme";
import { Link as RouterLink } from "react-router-dom";

const EventBreadcrumbs = ({
    name,
    url,
    detailsText,
}: {
    name: string;
    url: string | undefined;
    detailsText: string | undefined;
}) => {
    return (
        <Breadcrumbs aria-label="breadcrumb">
            <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="/events"
            >
                <Typography variant="headliner">All Events</Typography>
            </Link>
            {!url && (
                <Typography
                    variant="headliner"
                    style={{ color: theme.palette.nrbyColors.tangerine }}
                >
                    {name}
                </Typography>
            )}
            {url && (
                <Link
                    underline="hover"
                    color="inherit"
                    component={RouterLink}
                    to={url}
                >
                    <Typography variant="headliner">{name}</Typography>
                </Link>
            )}
            {detailsText && (
                <Typography
                    variant="headliner"
                    style={{ color: theme.palette.nrbyColors.tangerine }}
                >
                    {detailsText}
                </Typography>
            )}
        </Breadcrumbs>
    );
};

export default EventBreadcrumbs;
