import axios, { AxiosResponse } from "axios";
import { parseISO } from "date-fns";
import { SortBy } from "../../types/models/enums";
import { GetEventDetailsResponse } from "../Models/Responses/GetEventDetailsResponse";
import { GetEventsResponse } from "../Models/Responses/GetEventsResponse";
const moment = require("moment");

export const getFeaturedEvents = async (
    regionId: string,
    page: number | undefined
): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            return error.response;
        }
    );

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    var response = await axios.get(
        `${baseAPIUrl}/api/Events/Featured?regionId=${regionId}&page=${page}`
    );
    return response;
};

export const searchEvents = async (
    regionId: string,
    searchTerm: string,
    categoryId: string,
    sortBy: SortBy,
    minDate: string | undefined,
    maxDate: string | undefined,
    page: number
): Promise<AxiosResponse<GetEventsResponse>> => {
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            return error.response;
        }
    );

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    var response = await axios.get(
        `${baseAPIUrl}/api/Events/list?` +
            `regionId=${regionId}&searchTerm=${encodeURI(
                searchTerm
            )}&categoryId=${categoryId}&sortBy=${sortBy}&minDate=${minDate}&maxDate=${maxDate}&page=${page}`
    );
    return response;
};

export const getEventDetails = async (
    eventId: string
): Promise<AxiosResponse<GetEventDetailsResponse>> => {
    axios.interceptors.response.use(
        (originalResponse) => {
            if (originalResponse && originalResponse.data)
                handleDates(originalResponse.data);
            return originalResponse;
        },
        (error) => {
            return error.response;
        }
    );

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    var response = await axios.get(
        `${baseAPIUrl}/api/Events/${eventId}/details`
    );
    return response;
};

// based on https://stackoverflow.com/questions/65692061/casting-dates-properly-from-an-api-response-in-typescript
// added "Z?" at the end of regex
const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z?$/;

function isIsoDateString(value: any): boolean {
    return value && typeof value === "string" && isoDateFormat.test(value);
}

export function handleDates(body: any) {
    if (body === null || body === undefined || typeof body !== "object")
        return body;

    for (const key of Object.keys(body)) {
        const value = body[key];
        if (isIsoDateString(value)) body[key] = parseISO(value);
        else if (typeof value === "object") handleDates(value);
    }
}
