import {
    Button,
    Card,
    CircularProgress,
    Grid,
    MenuItem,
    Pagination,
    Select,
    SelectChangeEvent,
    Stack,
    Typography
} from "@mui/material";
import {
    CSSProperties,
    ReactNode,
    useEffect, useState
} from "react";
import { useCookies } from "react-cookie";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { EventPublicInfoModel } from "../../data/Models/EventPublicInfoModel";
import { RegionModel } from "../../data/Models/RegionModel";
import { getFeaturedEvents } from "../../data/Repositories/EventRepository";
import { getRegions } from "../../data/Repositories/LocationsRepository";
import { theme } from "../../theme/theme";
import FeaturedEventCard from "./FeaturedEventCard/FeaturedEventCard";

const invertedCard: CSSProperties = {
    backgroundColor: theme.palette.nrbyColors.invertedBgColor,
    color: theme.palette.nrbyColors.invertedColor,
};

const invertedCardContent: CSSProperties = {
    maxWidth: "1100px",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: theme.palette.nrbyColors.invertedBgColor,
    color: theme.palette.nrbyColors.invertedColor,
};

interface IFeaturedEventsProps {
    viewAllBtnVisible: boolean;
}

const FeaturedEvents = (props: IFeaturedEventsProps) => {
    const [featuredEvents, setFeaturedEvents] = useState([]);
    const [regions, setRegions] = useState<RegionModel[]>([]);
    const [regionId, setRegionId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pagesCount, setPagesCount] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const [
        defaultRegionIdCookie,
        setDefaultRegionIdCookie,
        removeDefaultRegionIdCookie,
    ] = useCookies(["defaultRegionFeaturedSection"]);

    useEffect(() => {
        setIsLoading(true);

        let startRegionId = searchParams.get("featuredRegionId") ?? "";
        if (!startRegionId || startRegionId === "") {
            let defaultRegionId = defaultRegionIdCookie.defaultRegionFeaturedSection;
            if (defaultRegionId) startRegionId = defaultRegionId;
        }
        setRegionId(startRegionId);

        let startPage: number = searchParams.get("featuredPage")
            ? Number(searchParams.get("featuredPage"))
            : 1;
        setCurrentPage(startPage);

        getRegions().then((response) => {
            if (response && response.data) {
                setRegions(response.data);
                TriggerRegionChange(startRegionId);
            }
        });
    }, []);

    useEffect(() => {
        if (regions.length > 0) {
            LoadEvents(regionId, currentPage);
        }
    }, [
        regions,
        regionId,
        currentPage,
    ]);

    const TriggerRegionChange = (newRegionId: string) => {
        setRegionId(newRegionId);
        searchParams.set("featuredRegionId", newRegionId);
        setSearchParams(searchParams);

        setCurrentPage(1);
        searchParams.set("featuredPage", "1");
        setSearchParams(searchParams);

        if (newRegionId && newRegionId !== "")
            setDefaultRegionIdCookie("defaultRegionFeaturedSection", newRegionId);
        else removeDefaultRegionIdCookie("defaultRegionFeaturedSection");
    };

    const HandleRegionChange = (
        event: SelectChangeEvent<unknown>,
        child: ReactNode
    ) => {
        var newRegionId = event.target.value as string;
        TriggerRegionChange(newRegionId);
    };

    const HandlePageChange = (
        event: React.ChangeEvent<unknown>,
        page: number
    ) => {
        setCurrentPage(page);
        searchParams.set("featuredPage", page.toString());
        setSearchParams(searchParams);
        LoadEvents(regionId, page);
    };

    const LoadEvents = (regionId: string, page: number) => {
        getFeaturedEvents(regionId, page).then((response) => {
            if (response && response.data && response.data.success) {
                setFeaturedEvents(response.data.events);
                setPagesCount(response.data.totalPages);
                setIsLoading(false);
            }
        });
    };

    return (
        <Card style={invertedCard} elevation={0} sx={{ padding: { xs: 2 } }}>
            <Card
                style={invertedCardContent}
                elevation={0}
                sx={{
                    marginTop: { xs: 2, md: 4, lg: 10 },
                    marginBottom: { xs: 2, md: 4, lg: 10 },
                }}
            >
                <Grid container justifyContent="space-between">
                    <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="header02" color="secondary">
                            Featured Events
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Select
                            displayEmpty
                            id="select-location-dd"
                            style={{
                                marginTop: "auto",
                                marginBottom: "auto",
                                paddingRight: "0",
                            }}
                            variant="standard"
                            sx={{
                                width: { xs: "100%", sm: "200px" },
                            }}
                            value={regionId}
                            onChange={HandleRegionChange}
                        >
                            <MenuItem value="">Select Location</MenuItem>
                            {regions.map((item: RegionModel) => (
                                <MenuItem value={item.id} key={item.id}>
                                    {item.value}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    {props.viewAllBtnVisible && (
                        <Grid item xs={12} sm={6} md={4}>
                            <Button
                                component={RouterLink}
                                to="/events"
                                variant="inverted"
                                style={{
                                    height: "36px",
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                    textAlign: "center",
                                }}
                                sx={{
                                    width: { xs: "100%", sm: "150px" },
                                }}
                            >
                                <Typography variant="subheader01">
                                    View All
                                </Typography>
                            </Button>
                        </Grid>
                    )}
                </Grid>

                {/* <Stack direction="row" justifyContent="space-between" flexWrap="wrap"> 
                    <Stack direction="row" spacing={4} flexWrap="wrap">
                        <Typography variant="header02" color="secondary">
                            Featured Events
                        </Typography>
                        <SelectLocationDropdown
                            variant="standard"
                            onChange={HandleRegionChange}
                        />
                    </Stack>

                    <Button
                        component={RouterLink}
                        to="/events"
                        variant="inverted"
                        style={{
                            height: "36px",
                            marginTop: "auto",
                            marginBottom: "auto",
                        }}
                    >
                        <Typography variant="subheader01">View All</Typography>
                    </Button>
                </Stack> */}
                <Typography
                    variant="body01"
                    ml={4}
                    mt={4}
                    mb={4}
                    component="div"
                >
                    Our feature events are carefully curated to showcase the
                    most exciting and interesting events happening right now.
                    These events are hand-picked by our team to highlight the
                    best of what's on offer, from concerts and festivals to
                    cultural exhibitions and sporting events.
                </Typography>

                <Grid
                    container
                    spacing={2}
                    alignItems="stretch"
                    justifyContent="center"
                >
                    {!isLoading &&
                        featuredEvents.map((event: EventPublicInfoModel) => (
                            <FeaturedEventCard event={event} key={event.id} />
                        ))}
                    {isLoading && (
                        <Grid item xs={12} textAlign="center">
                            <CircularProgress color="secondary" />
                        </Grid>
                    )}
                </Grid>
                {pagesCount > 0 && (
                    <Stack
                        mt={2}
                        justifyContent="right"
                        direction="row"
                        alignItems="center"
                    >
                        <Typography
                            variant="subheader02"
                            style={{
                                color: theme.palette.nrbyColors.tangerine,
                            }}
                        >
                            Page
                        </Typography>

                        <Pagination
                            count={pagesCount}
                            variant="inverted"
                            color="primary"
                            page={currentPage}
                            onChange={HandlePageChange}
                        />
                    </Stack>
                )}
            </Card>
        </Card>
    );
};

export default FeaturedEvents;
