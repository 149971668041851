import { Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const CreateEventsButton = () => {
    return (
        <Button
            color="primary"
            variant="bordered"
            style={{
                height: "33px",
                marginTop: "auto",
                marginBottom: "auto",
            }}
            component={RouterLink}
            to="/signup"
        >
            Create Events
        </Button>
    );
};

export default CreateEventsButton;
