import {
    MenuItem,
    Select,
    SelectChangeEvent
} from "@mui/material";
import { ReactNode, useLayoutEffect, useState } from "react";
import { CategoryModel } from "../../../data/Models/CategoryModel";
import { getCategories } from "../../../data/Repositories/CategoryRepository";

export interface FilterByDropdownProps {
    onFilterChange?: (categoryId: string) => void;
}

const FilterByDropdown = (props: FilterByDropdownProps) => {
    const [categories, setCategories] = useState<CategoryModel[]>();
    const [selectedFilterId, setSelectedFilterId] = useState("");

    useLayoutEffect(() => {
        getCategories().then((response) => {
            if (response && response.data && response.data.success)
                setCategories(response.data.categories);
        });
    }, []);

    const HandleChange = (
        event: SelectChangeEvent<unknown>,
        child: ReactNode
    ) => {
        setSelectedFilterId(event.target.value as string);
        if (props.onFilterChange)
            props.onFilterChange(event.target.value as string);
    };

    return (
        <Select
            displayEmpty
            fullWidth
            value={selectedFilterId}
            style={{ marginTop: "auto", marginBottom: "auto", paddingRight: "0" }}
            variant="outlined"
            onChange={HandleChange}
        >
            <MenuItem value="" key="anyCategory" style={{paddingRight: "16px"}}>
                Category
            </MenuItem>
            {categories != null &&
                categories.map((item: CategoryModel) => (
                    <MenuItem value={item.id} key={item.id}>
                        {item.name}
                    </MenuItem>
                ))}
        </Select>
    );
};

export default FilterByDropdown;
