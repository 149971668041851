import axios, { AxiosResponse } from "axios";
import { OrderEventTicketsV2Request } from "../Models/Requests/OrderEventTicketsV2Request";
import { PreviewOrderEventTicketsRequest } from "../Models/Requests/PreviewOrderEventTicketsRequest";
import { OrderEventTicketsResponse } from "../Models/Responses/OrderEventTicketsResponse";
import { TicketOption } from "../Models/TicketOption";

export const previewTicketsOrder = async (
    email: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    couponCode: string | undefined,
    tickets: TicketOption[] | undefined
): Promise<AxiosResponse<OrderEventTicketsResponse>> => {
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            return error.response;
        }
    );

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    var data: PreviewOrderEventTicketsRequest = {
        orderId: "",
        email: email,
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber,
        couponCode: couponCode,
        generalAdmissionTickets: tickets,
        assignedSeatingTickets: [],
    };

    var response = await axios.post(
        `${baseAPIUrl}/api/UserEventTicket/v2/previewOrder`,
        data
    );
    return response;
};

export const confirmPaymentSuccessful = async (
    orderId: string,
    email: string,
    firstName: string,
    lastName: string,
    phoneNumber: string
): Promise<AxiosResponse<OrderEventTicketsResponse>> => {
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            return error.response;
        }
    );

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    var data: OrderEventTicketsV2Request = {
        orderId: orderId,
        email: email,
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber,
        dateTimeSubmitted: new Date(),
    };

    var response = await axios.post(
        `${baseAPIUrl}/api/UserEventTicket/v2/order`,
        data
    );
    return response;
};