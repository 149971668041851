import { Components } from '@mui/material/styles/components';

export const MuiChip: Components = {
  MuiChip: {
    styleOverrides: {
      root: {
        backgroundColor: "#000",
        backgroundImage: 'none',
        color: 'white',
        borderColor: 'white',
      },
    },
  },
};