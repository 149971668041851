export enum RelatabilityLevel {
    Low = 0,
    Normal = 1,
    High = 2,
    VeryHigh = 3,
}

export enum SortBy {
    Date = 0, // Soonest to Farthest
    Price = 1, // Low to High
}

export enum FilterByDate {
    NoFilter = 'NoFilter',
    Today = 'Today',
    Tomorrow = 'Tomorrow',
    NextWeek = 'NextWeek',
    ThisWeekend = 'ThisWeekend',
    ExactDay = 'ExactDay'
}

export enum AgeRestrictionCode {
    None = 0,
    LegalAdult = 1,
    TwentyOnePlus = 2,
}

export enum UserAccountTypeCode {
    HostedAuth = 0,
    Facebook = 1,
    Apple = 2,
    Google = 3,
    Twitter = 4,
    TikTok = 5,
}

export enum AuthValidationCode {
    None = 0,
    EmailAddressRequired = 1,
    EmailVerificationRequired = 2,
    NoEmailConfirmationExpected = 3,
    EmailConfirmationInvalid = 4,
}

export enum PurchaseTicketStep {
    ContactInfo = 0,
    SelectTickets = 1,
    PaymentInfo = 2,
}

export enum StripePaymentIntentStatusCode {
    RequiresPaymentMethod = 0,
    RequiresConfirmation = 1,
    RequiresAction = 2,
    Processing = 3,
    PaymentSuccessful = 4,
    Failed = 5,
    Refunded = 6,
    FreeNA = 10,
    Unknown = 11,
    Expired = 12,
}

export enum EventStatusCode{
    OnSale = 0,
    Cancelled = 1,
    SalesEnded = 2,
    SoldOut = 3,
    Postponed = 4,
}

export const EventStatusCodeToString = (status: EventStatusCode)=>{
    switch (status) {
        case EventStatusCode.Cancelled:
            return "Cancelled";
        case EventStatusCode.SalesEnded:
            return "Sales Ended";
        case EventStatusCode.SoldOut:
            return "Sold Out";
        case EventStatusCode.Postponed:
            return "Postponed";
    }
    return undefined;
}
