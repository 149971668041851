import { Stack, Typography, Link } from "@mui/material";
import { DateFormatter } from "../../data/Formatters/DateFormatter";
import { EventDetailsProps } from "../../data/Props/EventDetailsProps";
import { theme } from "../../theme/theme";
import {
    AgeRestrictionCode,
    RelatabilityLevel,
} from "../../types/models/enums";
import CategoryIcon from "../Icons/Categories/CategoryIcon";
import EventDateLargeIcon from "../Icons/DateTime/EventDateLargeIcon";
import EventTimeLargeIcon from "../Icons/DateTime/EventTimeLargeIcon";
import RelatabilityIcon from "../Icons/Relatability/RelatabilityIcon";
import AgeRestriction from "./AgeRestriction";
import { Link as RouterLink } from "react-router-dom";

const EventBasicDetails = (props: EventDetailsProps) => {
    let eventData = props.event;
    return (
        <Stack spacing={2}>
            <Typography variant="header02">{eventData?.name}</Typography>
            <Stack
                direction="row"
                justifyContent="flex-start"
                spacing={2}
                alignItems="center"
            >
                {/* <RelatabilityIcon level={RelatabilityLevel.High} /> */}
                <CategoryIcon
                    categoryIconFilename={eventData?.categoryIconFilename}
                />
                <Typography variant="subheader02">
                    {eventData?.hasFreeAdmissions && <span>$Free</span>}
                    {!eventData?.hasFreeAdmissions && (
                        <span>${eventData?.ticketPriceMin}</span>
                    )}
                </Typography>
                <Typography variant="subheader02">
                    Ages:&nbsp;
                    <Typography variant="largeBody01">
                        {AgeRestriction(
                            eventData?.ageRestrictionCode ??
                                AgeRestrictionCode.None
                        )}
                    </Typography>
                </Typography>
            </Stack>
            <Stack direction="row" justifyContent="flex-start" spacing={4}>
                <Stack spacing={1}>
                    <Typography variant="subheader03">
                        Start Date/Time
                    </Typography>
                    <Typography variant="body02">
                        <EventDateLargeIcon />
                        &nbsp;
                        {DateFormatter(eventData?.startDateTimeLocal)}
                    </Typography>
                    <Typography variant="body02">
                        <EventTimeLargeIcon />
                        &nbsp;
                        {eventData?.startTimeLocal}
                    </Typography>
                </Stack>
                {eventData?.finishDateTimeUTC && (
                    <Stack spacing={1}>
                        <Typography variant="subheader03">
                            End Date/Time
                        </Typography>
                        <Typography variant="body02">
                            <EventDateLargeIcon />
                            &nbsp;
                            {DateFormatter(eventData?.finishDateTimeLocal)}
                        </Typography>
                        <Typography variant="body02">
                            <EventTimeLargeIcon />
                            &nbsp;
                            {eventData?.finishTimeLocal}
                        </Typography>
                    </Stack>
                )}
            </Stack>
            <Typography variant="subheader03">
                Venue Name:&nbsp;&nbsp;&nbsp;&nbsp;
                <Typography
                    component="span"
                    variant="subheader03"
                    style={{
                        color: theme.palette.nrbyColors.tangerine,
                        backgroundColor: "#40404080",
                        padding: "4px",
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        borderRadius: "3px"
                    }}
                >
                    {eventData?.venueName}
                </Typography>
            </Typography>

            <Typography variant="subheader03">
                Location:&nbsp;&nbsp;
                {eventData?.latitude && eventData?.longitude && (
                    <Link
                        component={RouterLink}
                        to={`https://www.google.com/maps/search/?api=1&query=${eventData?.latitude},${eventData.longitude}`}
                        target="_blank"
                        style={{ height: "100%" }}
                    >
                        <Typography component="span" variant="largeBody01">
                            {eventData?.address}, {eventData?.city}
                        </Typography>
                    </Link>
                )}
                {!eventData?.latitude && !eventData?.longitude && (
                    <Typography component="span" variant="largeBody01">
                        {eventData?.address}, {eventData?.city}
                    </Typography>
                )}
            </Typography>
        </Stack>
    );
};

export default EventBasicDetails;
