import { FC } from "react";

const EventDateIcon: FC = () => {
    return (
        <svg
            style={{ marginTop: "auto", marginBottom: "auto" }}
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.80078 0H6.39961V1.2002C6.39961 1.42111 6.22052 1.6002 5.99961 1.6002C5.7787 1.6002 5.59961 1.42111 5.59961 1.2002V0H3.20039V1.2002C3.20039 1.42111 3.0213 1.6002 2.80039 1.6002C2.57948 1.6002 2.40039 1.42111 2.40039 1.2002V0H2C0.895431 0 0 0.895431 0 2V10C0 11.1046 0.895431 12 2 12H10C11.1046 12 12 11.1046 12 10V2C12 0.895431 11.1046 0 10 0H9.60078V1.2002C9.60078 1.42111 9.42169 1.6002 9.20078 1.6002C8.97987 1.6002 8.80078 1.42111 8.80078 1.2002V0ZM1.59951 9.00019C1.59951 8.44791 2.04723 8.0002 2.59951 8.0002H2.99951C3.5518 8.0002 3.99951 8.44791 3.99951 9.0002V9.4002C3.99951 9.95248 3.5518 10.4002 2.99951 10.4002H2.59951C2.04723 10.4002 1.59951 9.95248 1.59951 9.4002V9.00019ZM2.59961 4C2.04732 4 1.59961 4.44771 1.59961 5V5.4C1.59961 5.95228 2.04732 6.4 2.59961 6.4H2.99961C3.55189 6.4 3.99961 5.95229 3.99961 5.4V5C3.99961 4.44772 3.55189 4 2.99961 4H2.59961ZM4.79883 5C4.79883 4.44771 5.24654 4 5.79883 4H6.19883C6.75111 4 7.19883 4.44772 7.19883 5V5.4C7.19883 5.95229 6.75111 6.4 6.19883 6.4H5.79883C5.24654 6.4 4.79883 5.95228 4.79883 5.4V5ZM9 4C8.44772 4 8 4.44771 8 5V5.4C8 5.95228 8.44771 6.4 9 6.4H9.4C9.95228 6.4 10.4 5.95229 10.4 5.4V5C10.4 4.44772 9.95229 4 9.4 4H9ZM4.79883 9.00049C4.79883 8.4482 5.24654 8.00049 5.79883 8.00049H6.19883C6.75111 8.00049 7.19883 8.4482 7.19883 9.00049V9.40049C7.19883 9.95277 6.75111 10.4005 6.19883 10.4005H5.79883C5.24654 10.4005 4.79883 9.95277 4.79883 9.40049V9.00049Z"
                fill="black"
            />
        </svg>
    );
};

export default EventDateIcon;
