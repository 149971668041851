import { FC } from "react";

const EventTimeIcon: FC = () => {
    return (
        <svg
            style={{ marginTop: "auto", marginBottom: "auto" }}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8.5 4C8.5 3.72386 8.27614 3.5 8 3.5C7.72386 3.5 7.5 3.72386 7.5 4V9C7.5 9.27614 7.72386 9.5 8 9.5H12C12.2761 9.5 12.5 9.27614 12.5 9C12.5 8.72386 12.2761 8.5 12 8.5H8.5V4Z"
                fill="#F6F6F6"
            />
        </svg>
    );
};

export default EventTimeIcon;
