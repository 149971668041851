import {
    Backdrop,
    Box,
    Button,
    Card,
    CardMedia,
    CircularProgress,
    Grid,
    Link,
    Stack,
    Typography,
} from "@mui/material";
import React, { CSSProperties, useLayoutEffect, useState } from "react";
import {
    Link as RouterLink,
    useNavigate,
    useSearchParams,
} from "react-router-dom";
import defaultImage from "../assets/images/default-event-image.png";
import TopImage from "../assets/images/homepage-top.jpg";
import EventBasicDetails from "../components/Event/EventBasicDetails";
import EventBreadcrumbs from "../components/Event/EventBreadcrumbs";
import ShareIcon from "../components/Icons/ShareIcon";
import { MainLayout } from "../components/Layouts/MainLayout";
import Share from "../components/Share/Share";
import { EventPublicDetailsInfoModel } from "../data/Models/EventPublicDetailsInfoModel";
import { getEventDetails } from "../data/Repositories/EventRepository";
import { theme } from "../theme/theme";
import styles from "./EventPage.module.scss";
import { Helmet } from "react-helmet";
import { EventStatusCode, EventStatusCodeToString } from "../types/models/enums";

const eventCardContentStyle: CSSProperties = {
    marginTop: "60px",
    zIndex: 3,
    backgroundColor: "transparent",
    backdropFilter: "blur(8px)",
};

const mainImageStyle: CSSProperties = {
    width: "100%",
    height: "auto",
    borderRadius: "20px",
    borderWidth: "1px",
    borderColor: theme.palette.nrbyColors.white,
    borderStyle: "solid",
};

const mainImageSalesEndedStyle: CSSProperties = {
    width: "100%",
    height: "auto",
    borderRadius: "20px",
    borderWidth: "1px",
    borderColor: theme.palette.nrbyColors.white,
    borderStyle: "solid",
    filter: "brightness(50%)",
};
const eventCardMainContentStyle: CSSProperties = {
    marginLeft: "auto",
    marginRight: "auto",
    zIndex: 3,
    // backgroundColor: "#FFFFFF30",
    padding: "20px",
    borderRadius: "20px",
};

const eventNotFoundContentStyle: CSSProperties = {
    position: "absolute",
    color: "white",
    top: "40%",
    left: "50%",
    transform: "translateX(-50%)",
    width: "100%",
    textAlign: "center",
    verticalAlign: "middle",
    marginTop: "auto",
    marginBottom: "auto",
};

const salesEndedPanelStyle: CSSProperties = {
    left: "0",
    top: "0",
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: "10",
    textAlign: "center",
    paddingTop: "20%",
};

function replaceWithP(input: string | undefined) {
    if (!input) return "";
    return "<p>" + input.replace(/\n/g, "</p><p>") + "</p>";
}

const EventPage = () => {
    const navigate = useNavigate();
    const [pageParams, setPageParams] = useSearchParams();
    const [showEventNotFound, setShowEventNotFound] = useState(false);
    const [eventData, setEventData] = useState<
        EventPublicDetailsInfoModel | undefined
    >();

    useLayoutEffect(() => {
        if (!eventData)
            getEventDetails(pageParams.get("id")!).then((response) => {
                if (response && response.data && response.data.success) {
                    setEventData(response.data.event);
                    document.title =
                        response.data.event.name + " - Nrby Events";
                } else setShowEventNotFound(true);
            });
    });

    let mainImage = defaultImage;
    if (eventData && eventData.images && eventData.images[0])
        mainImage = eventData.images[0];

    let shareData = {
        title: eventData?.name,
        text: eventData?.description,
        url: `${process.env.REACT_APP_BASE_URL}/event?id=${eventData?.id}`,
    };

    var ticketsUrl = `/purchaseticket?id=${eventData?.id}`;
    var purchaseInNewTab = false;
    if (eventData?.ticketsUrl) {
        ticketsUrl = eventData?.ticketsUrl;
        purchaseInNewTab = true;
    }

    let statusText: string | undefined = undefined;
    if (eventData && eventData.status != EventStatusCode.OnSale)
        statusText = EventStatusCodeToString(eventData.status);

    return (
        <MainLayout>
            <Helmet>
                <meta name="description" content={eventData?.description} />
                <meta name="og:title" content={eventData?.name} />
                <meta name="og:description" content={eventData?.description} />
                <meta name="og:image" content={mainImage} />
            </Helmet>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={!eventData && !showEventNotFound}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {showEventNotFound && (
                <Card>
                    <Box style={{ position: "relative" }}>
                        <CardMedia
                            style={{}}
                            component="img"
                            image={TopImage}
                        />
                        <Box style={eventNotFoundContentStyle}>
                            <Typography
                                variant="header01"
                                style={{ fontSize: "10vw" }}
                            >
                                Event Not Found
                            </Typography>
                        </Box>
                    </Box>
                </Card>
            )}
            {eventData && (
                <React.Fragment>
                    <Box style={{ position: "relative" }}>
                        <Box
                            component="div"
                            style={{
                                height: "100%",
                                position: "absolute",
                                backgroundImage: `url('${mainImage}')`,
                                backgroundSize: "cover",
                                filter: "brightness(60%)",
                                width: "100%",
                                zIndex: 0,
                                overflow: "hidden",
                            }}
                        >
                            &nbsp;
                        </Box>

                        <Stack style={eventCardContentStyle}>
                            <Box
                                sx={{
                                    marginTop: "40px",
                                    marginBottom: { xs: "0", sm: "20px" },
                                    marginLeft: "20px",
                                    zIndex: 3,
                                }}
                            >
                                <EventBreadcrumbs
                                    name={eventData?.name ?? ""}
                                    url={undefined}
                                    detailsText={undefined}
                                />
                            </Box>
                            <Stack
                                style={eventCardMainContentStyle}
                                sx={{
                                    width: { xl: "1250px", xs: "100%" },
                                    maxWidth: { lg: "1250px" },
                                    padding: { xs: "20px", lg: "0" },
                                }}
                            >
                                <Grid
                                    container
                                    alignItems="stretch"
                                    spacing={4}
                                >
                                    <Grid
                                        item
                                        lg={4}
                                        xs={12}
                                        style={{ position: "relative" }}
                                    >
                                        {statusText && (
                                            <React.Fragment>
                                                <div
                                                    style={salesEndedPanelStyle}
                                                >
                                                    <Typography variant="subheader01">
                                                        {statusText}
                                                    </Typography>
                                                </div>

                                                <Box
                                                    component="img"
                                                    src={mainImage}
                                                    alt="Nrby"
                                                    style={
                                                        mainImageSalesEndedStyle
                                                    }
                                                />
                                            </React.Fragment>
                                        )}
                                        {!statusText && (
                                            <Box
                                                component="img"
                                                src={mainImage}
                                                alt="Nrby"
                                                style={mainImageStyle}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item lg={5} xs={12}>
                                        <EventBasicDetails event={eventData} />
                                    </Grid>
                                    <Grid
                                        item
                                        lg={3}
                                        xs={12}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Stack
                                            justifyContent="space-between"
                                            style={{ height: "100%" }}
                                            spacing={2}
                                        >
                                            {eventData.status ===
                                                EventStatusCode.OnSale && (
                                                <Link
                                                    component={RouterLink}
                                                    to={ticketsUrl}
                                                    sx={{
                                                        visibility: {
                                                            xs: "hidden",
                                                            md: "visible",
                                                        },
                                                    }}
                                                    target={
                                                        purchaseInNewTab
                                                            ? "_blank"
                                                            : "_self"
                                                    }
                                                >
                                                    <Button variant="large">
                                                        <Typography variant="subheader01">
                                                            Purchase Ticket
                                                        </Typography>
                                                    </Button>
                                                </Link>
                                            )}
                                            <Stack
                                                direction="row"
                                                justifyContent="flex-end"
                                                spacing={2}
                                                className={
                                                    styles.buttonsContainer
                                                }
                                            >
                                                {/* <LikeIcon filled={false} /> */}
                                                <Share shareData={shareData}>
                                                    <Box
                                                        component="div"
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <ShareIcon
                                                            width="60"
                                                            height="60"
                                                        />
                                                    </Box>
                                                </Share>
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                </Grid>
                                <Typography variant="subheader01">
                                    Event Description
                                </Typography>
                                <Typography
                                    variant="body01"
                                    dangerouslySetInnerHTML={{
                                        __html: replaceWithP(
                                            eventData?.description
                                        ),
                                    }}
                                ></Typography>
                            </Stack>
                        </Stack>
                    </Box>
                    <Box
                        style={{
                            width: "100%",
                            position: "fixed",
                            bottom: "0",
                            marginRight: "auto",
                            backgroundColor: "#80808080",
                            padding: "15px",
                            zIndex: "10000",
                        }}
                        sx={{
                            visibility: { xs: "visible", md: "hidden" },
                        }}
                    >
                        {eventData.status === EventStatusCode.OnSale && (
                            <Link
                                component={RouterLink}
                                to={ticketsUrl}
                                target={purchaseInNewTab ? "_blank" : "_self"}
                                style={{
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    display: "block",
                                    width: "80%",
                                }}
                            >
                                <Button variant="large" fullWidth={true}>
                                    <Typography variant="subheader01">
                                        Purchase Ticket
                                    </Typography>
                                </Button>
                            </Link>
                        )}
                    </Box>
                </React.Fragment>
            )}
        </MainLayout>
    );
};

export default EventPage;
