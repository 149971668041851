import { AppBar, Box, Container, Toolbar, Stack } from "@mui/material";
import styles from "./Header.module.scss";
import MainMenu from "./MainMenu/MainMenu";
import MainMenuMobile from "./MainMenuMobile/MainMenuMobile";
import NrbyLogo from "./NrbyLogo/NrbyLogo";
import UserMenu from "./UserMenu/UserMenu";

function Header() {
    return (
        <AppBar position="fixed" className={styles.navbar}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}>
                        <NrbyLogo />
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "flex", md: "none" },
                        }}
                    >
                        <MainMenuMobile />
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "flex", md: "none" },
                            mr: 1,
                        }}
                    >
                        <NrbyLogo />
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            justifyContent: "flex-end",
                            display: { xs: "none", md: "flex" },
                        }}
                    >
                        <Stack direction="row" spacing={3}>
                            <MainMenu />
                        </Stack>
                    </Box>

                    <Box sx={{ flexGrow: 0 }} ml={3}>
                        {/* <UserMenu /> */}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Header;
