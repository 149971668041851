import { Components } from "@mui/material/styles/components";

export const MuiButton: Components = {
    MuiButton: {
        variants: [
            {
                props: { variant: "bordered" },
                style: {
                    textTransform: "none",
                    border: "1px solid white",
                    padding: "6px 22px 6px 22px",
                    borderRadius: "10px",
                    "&:hover": {
                        backgroundColor: "white",
                        color: "black",
                    },
                    "&:hover img": {
                        filter: "invert(100%) !important"
                    }
                },
            },
            {
                props: { variant: "inverted" },
                style: {
                    textTransform: "none",
                    backgroundColor: "black",
                    color: "white",
                    border: "1px solid white",
                    padding: "4px 12px 4px 12px",
                    borderRadius: "10px",
                    height: "24px",
                    display: 'flex',
                    "&:hover": {
                        backgroundColor: "white",
                        color: "black",
                        border: "1px solid black",
                    },
                    "&:hover img": {
                        filter: "invert(100%) !important"
                    }
                },
            },
            {
                props: { variant: "large" },
                style: {
                    textTransform: "none",
                    backgroundColor: "#F6F6F6",
                    color: "black",
                    border: "1px solid #F6F6F6",
                    padding: "16px",
                    borderRadius: "10px",
                    height: "60px",
                    display: 'block',
                    "&:hover": {
                        backgroundColor: "black",
                        color: "#F6F6F6",
                    },
                },
            },
            {
                props: { variant: "contained" },
                style: {
                    textTransform: "none",
                    border: "0px",
                    borderRadius: "10px",
                    display: 'block',
                    fontSize: "23px",
                },
            },
        ],
    },
};
