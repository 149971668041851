import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CSSProperties } from "react";
import { EventPublicDetailsInfoModel } from "../../../data/Models/EventPublicDetailsInfoModel";
import { PreviewOrderEventTicketsRequest } from "../../../data/Models/Requests/PreviewOrderEventTicketsRequest";
import { OrderEventTicketsResponse } from "../../../data/Models/Responses/OrderEventTicketsResponse";
import { theme } from "../../../theme/theme";
import StripePaymentElement from "./StripePaymentElement";

interface StripePaymentElementProps {
    event: EventPublicDetailsInfoModel | undefined;
    orderPreview: OrderEventTicketsResponse;
    order: PreviewOrderEventTicketsRequest | undefined;
}

const StripePaymentForm = ({
    event,
    orderPreview,
    order,
}: StripePaymentElementProps) => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY + "");
    const appearance = {
        theme: "stripe",
    };
    const options: any = {
        clientSecret: orderPreview.paymentIntentClientSecret,
        appearance,
    };
    return (
        <Elements options={options} stripe={stripePromise}>
            <StripePaymentElement
                event={event}
                orderPreview={orderPreview}
                order={order}
            />
        </Elements>
    );
};

export default StripePaymentForm;
