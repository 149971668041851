import {
    Box,
    Button,
    CircularProgress,
    Stack,
    Typography
} from "@mui/material";
import {
    PaymentElement,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import { CSSProperties, useEffect, useState } from "react";
import { EventPublicDetailsInfoModel } from "../../../data/Models/EventPublicDetailsInfoModel";
import { PreviewOrderEventTicketsRequest } from "../../../data/Models/Requests/PreviewOrderEventTicketsRequest";
import { OrderEventTicketsResponse } from "../../../data/Models/Responses/OrderEventTicketsResponse";
import { theme } from "../../../theme/theme";
import OrderPreviewInfo from "../OrderPreviewInfo/OrderPreviewInfo";

const payoutElementStyles: CSSProperties = {
    backgroundColor: theme.palette.nrbyColors.faintGray,
    color: theme.palette.nrbyColors.invertedColor,
    justifyContent: "center",
    display: "flex",
    padding: "16px",
    paddingTop: "0",
};

interface StripePaymentElementProps {
    event: EventPublicDetailsInfoModel | undefined;
    orderPreview: OrderEventTicketsResponse;
    order: PreviewOrderEventTicketsRequest | undefined;
}

const StripePaymentElement = ({
    event,
    orderPreview,
    order,
}: StripePaymentElementProps) => {
    const [message, setMessage] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<any>(false);
    const [paymentAttempts, setPaymentAttemps] = useState<any>(0);
    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = orderPreview.paymentIntentClientSecret;

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent?.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    if (paymentAttempts > 0) {
                        setMessage(
                            "Your payment was not successful, please try again."
                        );
                    }
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    const HandleSubmit = async () => {
        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        let errored = false;

        var currentDateTime = new Date();

        var orderExpirationDateTime = new Date(
            orderPreview.deletePendingOrderDateTimeUTC!
        );
        if (orderExpirationDateTime < currentDateTime) {
            setMessage(
                "Your order has expired. Please go back to the beginning and try again."
            );
            errored = true;
        } else {
            setMessage("");
        }

        if (errored) {
            setIsLoading(false);
            return;
        }

        const searchParams = new URLSearchParams();
        searchParams.append("orderId", orderPreview.orderId);
        searchParams.append("firstName", order!.firstName);
        searchParams.append("lastName", order!.lastName);
        searchParams.append("email", order!.email);
        searchParams.append("phone", order!.phoneNumber);
        searchParams.append("dateTimeSubmitted", currentDateTime.toString());

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: `${
                    process.env.REACT_APP_BASE_URL
                }/orderconfirmation?${searchParams.toString()}`,
            },
        });

        setPaymentAttemps((previous: number) => previous + 1);

        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occured.");
        }

        setIsLoading(false);
    };

    return (
        <Stack id="payment-form">
            <PaymentElement id="payment-element" />
            <OrderPreviewInfo orderPreview={orderPreview} event={event} />
            <div style={{ width: "100%", textAlign: "center" }}>
                {!isLoading && (
                    <Button
                        color="secondary"
                        variant="contained"
                        fullWidth
                        sx={{ mt: 4 }}
                        style={{
                            height: "55px",
                        }}
                        disabled={isLoading || !stripe || !elements}
                        onClick={HandleSubmit}
                    >
                        <Typography variant="subheader02">Check Out</Typography>
                    </Button>
                )}

                {isLoading && (
                    <Box alignContent="center" sx={{ mt: 4 }}>
                        <CircularProgress color="inherit" />
                    </Box>
                )}
            </div>

            {message && (
                <Box
                    id="payment-message"
                    style={{
                        color: theme.palette.nrbyColors.tangerine,
                        marginTop: "16px",
                    }}
                >
                    {message}
                </Box>
            )}
        </Stack>
    );
};

export default StripePaymentElement;
