import {
    Box, Grid,
    Stack, Typography
} from "@mui/material";
import { CSSProperties } from "react";
import defaultImage from "../../assets/images/default-event-image.png";
import { EventPublicDetailsInfoModel } from "../../data/Models/EventPublicDetailsInfoModel";
import styles from "../../pages/PurchaseTicketPage.module.scss";
import { theme } from "../../theme/theme";
import EventBasicDetails from "../Event/EventBasicDetails";
import EventBreadcrumbs from "../Event/EventBreadcrumbs";

const eventCardContentStyle: CSSProperties = {
    marginTop: "60px",
    zIndex: 3,
    backgroundColor: "transparent",
    backdropFilter: "blur(8px)",
    position: "relative",
};

const eventCardMainContentStyle: CSSProperties = {
    marginLeft: "auto",
    marginRight: "auto",
    zIndex: 3,
    // backgroundColor: "#FFFFFF30",
    padding: "20px",
    borderRadius: "20px",
};

const mainImageStyle: CSSProperties = {
    width: "100%",
    height: "auto",
    borderRadius: "20px",
    borderWidth: "1px",
    borderColor: theme.palette.nrbyColors.white,
    borderStyle: "solid",
};

const eventDescriptionStyle: CSSProperties = {
    maxHeight: "400px",
    overflow: "hidden",
    position: "relative",
};

const fullEventDescriptionStyle: CSSProperties = {};

interface EventInfoProps {
    event: EventPublicDetailsInfoModel | undefined;
    briefDescription: boolean;
}

const EventInfo = ({ event, briefDescription }: EventInfoProps) => {
    let mainImage = defaultImage;
    if (event && event.images && event.images[0]) mainImage = event.images[0];

    return (
        <Stack style={eventCardContentStyle}>
            <Box
                component="div"
                style={{
                    height: "100%",
                    position: "absolute",
                    backgroundImage: `url('${mainImage}')`,
                    backgroundSize: "cover",
                    filter: "brightness(50%)",
                    width: "100%",
                    zIndex: 0,
                    overflow: "hidden",
                }}
            >
                &nbsp;
            </Box>
            <Box
                sx={{
                    marginTop: "40px",
                    marginBottom: { xs: "0", sm: "20px" },
                    marginLeft: "20px",
                    zIndex: 3,
                }}
            >
                <EventBreadcrumbs
                    name={event?.name ?? ""}
                    url={"/event?id=" + event?.id}
                    detailsText="Purchase Ticket"
                />
            </Box>
            <Stack
                style={eventCardMainContentStyle}
                sx={{
                    // width: { xl: "1250px", xs: "100%" },
                    maxWidth: { lg: "1250px" },
                    padding: { xs: "20px", lg: "0" },
                }}
            >
                {!briefDescription && (
                    <Grid container alignItems="stretch" spacing={4}>
                        <Grid item lg={4} xs={12}>
                            <Box
                                component="img"
                                src={mainImage}
                                alt="Nrby"
                                style={mainImageStyle}
                            />
                        </Grid>
                        <Grid item lg={8} xs={12}>
                            <EventBasicDetails event={event} />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            justifyContent="center"
                            alignItems="center"
                            style={fullEventDescriptionStyle}
                        >
                            <Typography variant="subheader01">
                                Event Description
                            </Typography>
                            <Typography
                                variant="body01"
                                className={styles.eventDescriptionText}
                                dangerouslySetInnerHTML={{
                                    __html: replaceWithP(event?.description),
                                }}
                            ></Typography>
                        </Grid>
                    </Grid>
                )}
                {briefDescription && (
                    <Grid container alignItems="stretch" spacing={4}>
                        <Grid item lg={4} xs={12}>
                            <Box
                                component="img"
                                src={mainImage}
                                alt="Nrby"
                                style={mainImageStyle}
                            />
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <EventBasicDetails event={event} />
                        </Grid>
                        <Grid
                            item
                            lg={4}
                            xs={12}
                            justifyContent="center"
                            alignItems="center"
                            style={eventDescriptionStyle}
                        >
                            <Typography variant="subheader01">
                                Event Description
                            </Typography>
                            <Typography
                                variant="body01"
                                className={styles.eventDescriptionText}
                                dangerouslySetInnerHTML={{
                                    __html: replaceWithP(event?.description),
                                }}
                            ></Typography>
                        </Grid>
                    </Grid>
                )}
            </Stack>
        </Stack>
    );
};

function replaceWithP(input: string | undefined) {
    if (!input) return "";
    return "<p>" + input.replace(/\n/g, "</p><p>") + "</p>";
}

export default EventInfo;
