export const typographyOverrides = () => ({
    fontFamily: ["Roboto"].join(","),
    bigHeader: {
        fontFamily: "Roboto",
        fontSize: "120px",
        fontWeight: "200",
        lineHeight: "141px",
    },
    header01: {
        fontFamily: "Roboto",
        fontSize: "80px",
        fontWeight: "300",
        lineHeight: "93.75px",
    },
    header02: {
        fontFamily: "Roboto",
        fontSize: "40px",
        fontWeight: "300",
        lineHeight: "47px",
    },
    headliner: {
        fontFamily: "Roboto",
        fontSize: "20px",
        fontWeight: "400",
        lineHeight: "23.44px",
        textTransform: "uppercase" as const,
    },
    subheader01: {
        fontFamily: "Roboto",
        fontSize: "24px",
        fontWeight: "600",
        lineHeight: "28px",
    },
    subheader02: {
        fontFamily: "Roboto",
        fontSize: "20px",
        fontWeight: "400",
        lineHeight: "23.44px",
    },
    subheader03: {
        fontFamily: "Roboto",
        fontSize: "18px",
        fontWeight: "400",
        lineHeight: "21.09px",
    },
    largeBody01: {
        fontFamily: "Roboto",
        fontSize: "18px",
        fontWeight: "300",
        lineHeight: "26px",
    },
    body01: {
        fontFamily: "Roboto",
        fontSize: "18px",
        fontWeight: "400",
        lineHeight: "21.09px",
    },
    body02: {
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: "300",
        lineHeight: "16.41px",
    },
    smallCaption: {
        fontFamily: "Roboto",
        fontSize: "12px",
        fontWeight: "500",
        lineHeight: "14.06px",
    },
});
