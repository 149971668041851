import {
    MenuItem,
    Select,
    SelectProps
} from "@mui/material";
import { SortBy } from "../../../types/models/enums";

const SortByDropdown = (props: SelectProps) => {
    return (
        <Select
            displayEmpty
            value=""
            style={{ marginTop: "auto", marginBottom: "auto", paddingRight: "0" }}
            variant="standard"
            {...props}
        >
            <MenuItem value="">Sort By</MenuItem>
            <MenuItem value={SortBy.Date}>Time</MenuItem>
            <MenuItem value={SortBy.Price}>Price</MenuItem>
        </Select>
    );
};

export default SortByDropdown;
