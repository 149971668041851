import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { CSSProperties } from "react";
import nrbyappqr from "../../assets/images/nrbyappqr.png";
import { EventPublicDetailsInfoModel } from "../../data/Models/EventPublicDetailsInfoModel";
import { OrderEventTicketsResponse } from "../../data/Models/Responses/OrderEventTicketsResponse";
import { theme } from "../../theme/theme";
import { Link as RouterLink } from "react-router-dom";

interface TicketSelectionStepProps {
    event: EventPublicDetailsInfoModel | undefined;
    order: OrderEventTicketsResponse | undefined;
    email: string;
}

const confirmationInfoStyle: CSSProperties = {
    color: theme.palette.nrbyColors.invertedColor,
    backgroundColor: theme.palette.nrbyColors.faintGray,
    margin: "0",
    padding: "16px",
    textAlign: "center",
};

const qrCodeStyle: CSSProperties = {
    maxWidth: "309px",
};

const ConfirmedOrderDetailsInfo = ({
    event,
    order,
    email,
}: TicketSelectionStepProps) => {
    return (
        <Stack direction="column" style={confirmationInfoStyle} spacing={2}>
            <Typography
                variant="header01"
                sx={{ fontSize: { xs: "40px", md: "80px" } }}
            >
                Congratulations!
            </Typography>
            <Typography variant="header02">
                You have successfully purchased your ticket for this event.
            </Typography>
            <Typography
                variant="header02"
                sx={{ fontSize: { xs: "24px", md: "40px" } }}
            >
                Please check your email
                <br />
                <a href={"mailto:" + email}>{email}</a> <br />
                to view your ticket!
            </Typography>
            <Typography variant="subheader01">
                Your Order Number: {order?.orderNo}
            </Typography>
            {/* <Box mt={2} mb={4}>
                <img src={nrbyappqr} style={qrCodeStyle} />
            </Box> */}
            <Typography variant="header02">
                Download the Nrby Mobile App to View Ticket!
            </Typography>
            <Grid container justifyContent="center" spacing={0}>
                <Grid item xs={12} md={6} justifyContent="flex-end" style={{display: "flex"}}>
                    <Button
                        component={RouterLink}
                        to="https://play.google.com/store/apps/details?id=com.ByNearBy&hl=en_US&gl=US&pli=1"
                        color="primary"
                        variant="inverted"
                        sx={{ width: { xs: "100%", md: "220px" } }}
                        style={{
                            height: "60px",
                            marginTop: "auto",
                            marginBottom: "auto",
                            padding: "19px, 16px, 19px, 16px",
                        }}
                    >
                        <Typography variant="subheader03">
                            Android &nbsp; &nbsp;
                            <img
                                src="/images/android-logo.png"
                                style={{
                                    marginTop: "-2px",
                                }}
                            />
                        </Typography>
                    </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Button
                        fullWidth={true}
                        component={RouterLink}
                        to="https://apps.apple.com/us/app/nrby-io/id1525909483"
                        color="primary"
                        variant="inverted"
                        sx={{ width: { xs: "100%", md: "220px" } }}
                        style={{
                            height: "60px",
                            marginTop: "auto",
                            marginBottom: "auto",
                            padding: "19px, 16px, 19px, 16px",
                        }}
                    >
                        <Typography variant="subheader03">
                            iOS &nbsp; &nbsp;
                            <img
                                src="/images/ios-logo.png"
                                style={{
                                    marginTop: "-2px",
                                }}
                            />
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Stack>
    );
};

export default ConfirmedOrderDetailsInfo;
