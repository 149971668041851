import axios, { AxiosResponse } from "axios";
import { ApiAuthenticateRequest } from "../Models/Requests/ApiAuthenticateRequest";
import { AuthenticateV2Request } from "../Models/Requests/AuthenticateV2Request";
import { AuthenticateResponse } from "../Models/Responses/AuthenticateResponse";


export const SendAuthenticateRequest = async (
    email: string,
    password: string
): Promise<AxiosResponse<AuthenticateResponse>> => {
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            return error.response;
        }
    );

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    var data: ApiAuthenticateRequest = {
        email: email,
        password: password,
    };

    var response = await axios.post(
        `${baseAPIUrl}/api/Users/apiAuthenticate`,
        data
    );
    return response;
};


export const SignInRequest = async (
    email: string,
    password: string
): Promise<AxiosResponse<AuthenticateResponse>> => {
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            return error.response;
        }
    );

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    var data: AuthenticateV2Request = {
        email: email,
        password: password,
        firstName: "",
        lastName: "",
        externalAccountId: "",
        externalToken: "",
    };

    var response = await axios.post(
        `${baseAPIUrl}/api/Users/v2/authenticate`,
        data
    );
    return response;
};
