import { Card, Grid, Link, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { theme } from "../../../theme/theme";
import styles from "./Footer.module.scss";

function Footer() {
    return (
        <Card
            className={styles.footerContainer}
            style={{
                backgroundColor: theme.palette.nrbyColors.primaryBgColor,
            }}
            sx={{
                marginLeft: { xs: "20px", sm: "0" },
            }}
        >
            <Grid
                container
                spacing={0}
                className={styles.footerMenu}
                rowSpacing={6}
            >
                <Grid xs={12} sm={6} md={3} item>
                    <Stack spacing={{ xs: 2 }}>
                        <Typography
                            className={styles.footerSubheader}
                            variant="subheader01"
                        >
                            Nrby
                        </Typography>
                        <Link
                            underline="hover"
                            color="inherit"
                            component={RouterLink}
                            to="/aboutus"
                        >
                            <Typography
                                className={styles.footerItem}
                                variant="subheader02"
                            >
                                About us
                            </Typography>
                        </Link>
                        <Link
                            underline="hover"
                            color="inherit"
                            component={RouterLink}
                            to="https://nrby.io/blog/"
                        >
                            <Typography
                                className={styles.footerItem}
                                variant="subheader02"
                            >
                                News
                            </Typography>
                        </Link>

                        <Link
                            underline="hover"
                            color="inherit"
                            component={RouterLink}
                            to="https://www.nrbyevents.com/events"
                            target="_blank"
                        >
                            <Typography
                                className={styles.footerItem}
                                variant="subheader02"
                            >
                                Events
                            </Typography>
                        </Link>
                    </Stack>
                </Grid>
                <Grid xs={12} sm={6} md={3} item>
                    <Stack spacing={{ xs: 2 }}>
                        {/* <Link
                            underline="hover"
                            color="inherit"
                            component={RouterLink}
                            to="https://"
                            target="_blank"
                        >
                            
                        </Link> */}
                        <Typography
                            className={styles.footerSubheader}
                            variant="subheader01"
                        >
                            Event Creators
                        </Typography>
                        <Link
                            underline="hover"
                            color="inherit"
                            component={RouterLink}
                            to="/signup"
                            target="_blank"
                        >
                            <Typography
                                className={styles.footerItem}
                                variant="subheader02"
                            >
                                Create Events
                            </Typography>
                        </Link>

                        <Link
                            underline="hover"
                            color="inherit"
                            component={RouterLink}
                            to="https://portal.nrbyevents.com"
                            target="_blank"
                        >
                            <Typography
                                className={styles.footerItem}
                                variant="subheader02"
                            >
                                Event Creator Portal
                            </Typography>
                        </Link>

                        <Link
                            underline="hover"
                            color="inherit"
                            component={RouterLink}
                            to="https://portal.nrbyevents.com/sign-up"
                            target="_blank"
                        >
                            <Typography
                                className={styles.footerItem}
                                variant="subheader02"
                            >
                                Register
                            </Typography>
                        </Link>
                    </Stack>
                </Grid>
                <Grid xs={12} sm={6} md={3} item>
                    <Stack spacing={{ xs: 2 }}>
                        <Typography
                            className={styles.footerSubheader}
                            variant="subheader01"
                        >
                            For Mobile
                        </Typography>
                        <Link
                            underline="hover"
                            color="inherit"
                            component={RouterLink}
                            to="https://apps.apple.com/us/app/nrby-io/id1525909483"
                            target="_blank"
                        >
                            <Typography
                                className={styles.footerItem}
                                variant="subheader02"
                            >
                                App for iOS
                            </Typography>
                        </Link>
                        <Link
                            underline="hover"
                            color="inherit"
                            component={RouterLink}
                            to="https://play.google.com/store/apps/details?id=com.ByNearBy&hl=en_US&gl=US&pli=1"
                            target="_blank"
                        >
                            <Typography
                                className={styles.footerItem}
                                variant="subheader02"
                            >
                                App for Android
                            </Typography>
                        </Link>
                    </Stack>
                </Grid>
                <Grid xs={12} sm={6} md={3} item>
                    <Stack spacing={{ xs: 2 }}>
                        <Typography
                            className={styles.footerSubheader}
                            variant="subheader01"
                        >
                            Support
                        </Typography>
                        <Typography
                            className={styles.footerItem}
                            variant="subheader02"
                        >
                            FAQ
                        </Typography>
                        <Link
                            underline="hover"
                            color="inherit"
                            component={RouterLink}
                            to="/contactus"
                        >
                            <Typography
                                className={styles.footerItem}
                                variant="subheader02"
                            >
                                Contact Us
                            </Typography>
                        </Link>
                        <Link
                            underline="hover"
                            color="inherit"
                            component={RouterLink}
                            to="/terms"
                        >
                            <Typography
                                className={styles.footerItem}
                                variant="subheader02"
                            >
                                Terms
                            </Typography>
                        </Link>
                        <Link
                            underline="hover"
                            color="inherit"
                            component={RouterLink}
                            to="/privacy"
                        >
                            <Typography
                                className={styles.footerItem}
                                variant="subheader02"
                            >
                                Privacy
                            </Typography>
                        </Link>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        Copyright 2020-2024 &nbsp;
                        <Link to="http://nrby.io/" component={RouterLink}>
                            Nrby.io
                        </Link>
                        , Inc
                    </Typography>
                    <Typography>
                        Built in Minnesota, for the world to enjoy.
                    </Typography>
                    <Typography>All rights reserved.</Typography>
                </Grid>
            </Grid>
        </Card>
    );
}

export default Footer;
