import { Components } from "@mui/material/styles/components";

export const MuiTextField: Components = {
    MuiTextField: {
        variants: [
            {
                props: { variant: "standard" },
                style: {
                    textTransform: "none",
                    color: "#565656",
                    padding: "6px 22px 6px 22px",
                    borderRadius: "10px",
                    height: "41px",
                    backgroundColor: "#fff",
                    "&:hover:before": {
                        border: "0 !important",
                    },
                    "&::before":{
                        border: 0
                    },
                    "&::after":{
                        border: 0
                    },
                    "& .MuiInputBase-input":{
                        border: "0 !important",
                        color: "#565656",
                    }
                },
            },
            {
                props: { variant: "outlined" },
                style: {
                    textTransform: "none",
                    color: "#565656",
                    
                    
                    borderRadius: "10px",
                    height: "51px",
                    backgroundColor: "#fff",
                    "&:hover:before": {
                        border: "0 !important",
                    },
                    "&::before":{
                        border: 0
                    },
                    "&::after":{
                        border: 0
                    },
                    "& .MuiInputBase-input":{
                        border: "0 !important",
                        color: "#565656",
                        padding: "6px 22px 6px 22px",
                        height: "30px"
                    }
                },
            },
        ],
    },
};
