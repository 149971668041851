interface CategoryIconProps {
    categoryIconFilename: string | undefined;
}

const CategoryIcon = (props: CategoryIconProps) => (
    <img
        src={
            "/images/categories/inverted/" +
            (props.categoryIconFilename ?? "default.svg")
        }
        alt="Event category"
        style={{
            maxWidth: "45px",
            width: "40px",
            display: "inline-block",
            alignSelf: "flex-end",
        }}
    />
);

export default CategoryIcon;
