import { Button } from "@mui/material";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import CreateEventsButton from "../../../Controls/CreateEventsButton/CreateEventsButton";

function MainMenu() {
    return (
        <React.Fragment>
            <CreateEventsButton />
            <Button
                color="primary"
                sx={{ my: 2, display: "block" }}
                component={RouterLink}
                to="/events"
            >
                View Events
            </Button>
            <Button
                color="primary"
                sx={{ my: 2, display: "block" }}
                component={RouterLink}
                to="/aboutus"
            >
                About
            </Button>
            <Button
                color="primary"
                sx={{ my: 2, display: "block" }}
                component={RouterLink}
                to="https://nrby.io/blog/"
            >
                News
            </Button>
        </React.Fragment>
    );
}

export default MainMenu;
