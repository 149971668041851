import { Components } from "@mui/material/styles/components";

export const MuiSelect: Components = {
    MuiSelect: {
        variants: [
            {
                props: { variant: "standard" },
                style: {
                    textTransform: "none",
                    backgroundColor: "black",
                    padding: "8px 16px 8px 16px",
                    borderRadius: "20px",
                    border: "1px solid white",
                    height: "32px",
                    "&:hover:before": {
                        border: "0 !important",
                    },
                    "&::before": {
                        border: 0,
                    },
                    "&::after": {
                        border: 0,
                    },
                    "& .MuiSelect-nativeInput": {
                        border: "0 !important",
                    },
                },
            },
            {
                props: { variant: "outlined" },
                style: {
                    textTransform: "none",
                    backgroundColor: "white",
                    color: "black",
                    padding: "8px 16px 8px 16px",
                    borderRadius: "20px",
                    borderWidth: "0",
                    height: "32px",
                    "&:hover:before": {
                        border: "0 !important",
                    },
                    "&::before": {
                        border: 0,
                    },
                    "&::after": {
                        border: 0,
                    },
                    "& .MuiSelect-nativeInput": {
                        border: "0 !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                    },
                    "& .MuiSelect-icon":{
                        color: 'black',
                    }
                },
            },
        ],
    },
};
