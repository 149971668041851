import { CommonProps } from "@mui/material/OverridableComponent";
import { FC, SVGProps } from "react";

const ShareIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            style={{
                marginTop: "auto",
                marginBottom: "auto",
                backgroundColor: "#F6F6F6",
                borderRadius: "50%",
            }}
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M0 2C0 0.895431 0.895431 0 2 0L10 0L18 0C19.1046 0 20 0.895431 20 2V16C20 17.1046 19.1046 18 18 18H2C0.895431 18 0 17.1046 0 16V2Z"
                fill="#F6F6F6"
            />
            <path
                d="M9.64645 2.64645C9.84171 2.45118 10.1583 2.45118 10.3536 2.64645L13.5355 5.82843C13.7308 6.02369 13.7308 6.34027 13.5355 6.53553C13.3403 6.7308 13.0237 6.7308 12.8284 6.53553L10 3.70711L7.17157 6.53553C6.97631 6.7308 6.65973 6.7308 6.46447 6.53553C6.2692 6.34027 6.2692 6.02369 6.46447 5.82843L9.64645 2.64645ZM10.5 11C10.5 11.2761 10.2761 11.5 10 11.5C9.72386 11.5 9.5 11.2761 9.5 11H10.5ZM10.5 3V11H9.5V3H10.5Z"
                fill="black"
            />
            <path
                d="M17 8V12C17 13.1046 16.1046 14 15 14H5C3.89543 14 3 13.1046 3 12V8"
                stroke="black"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default ShareIcon;
