import {
    Box, Card, CardMedia, Typography
} from "@mui/material";
import { CSSProperties } from "react";
import TopImage from "../assets/images/homepage-top.jpg";
import { MainLayout } from "../components/Layouts/MainLayout";


const mainContent: CSSProperties = {
    position: "absolute",
    color: "white",
    top: "40%",
    left: "50%",
    transform: "translateX(-50%)",
    width: "100%",
    textAlign: "center",
    verticalAlign: "middle",
    marginTop: "auto",
    marginBottom: "auto",
};

const ContactUsPage = () => {
    return (
        <MainLayout>
            <Card>
                <Box style={{ position: "relative" }} mt={10}>
                    <Typography variant="bigHeader">
                        Contact Us
                    </Typography>
                </Box>
            </Card>
        </MainLayout>
    );
};

export default ContactUsPage;
