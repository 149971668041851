import axios, { AxiosResponse } from "axios";
import { CategoriesResponse } from "../Models/Responses/CategoriesResponse";

export const getCategories = async (): Promise<AxiosResponse<CategoriesResponse>> => {
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            return error.response;
        }
    );

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    var response = await axios.get(`${baseAPIUrl}/api/category/list`);
    return response;
};
