import { type FC } from "react";

const ShareController: FC<Props> = ({
    children,
    shareData,
    onInteraction,
    onSuccess,
    onError,
    onNonNativeShare,
    disabled,
}) => {
    const handleOnClick = async () => {
        onInteraction?.();
        if (navigator?.share) {
            try {
                await navigator.share(shareData);
                onSuccess?.();
            } catch (err) {
                onError?.(err);
            }
        } else {
            onNonNativeShare?.();
        }
    };

    return (
        <button onClick={handleOnClick} type="button" disabled={disabled} style={{padding:0, margin:0, border: 0}}>
            {children}
        </button>
    );
};

interface Props {
    children: React.ReactNode;
    shareData: ShareData;
    onSuccess?: () => void;
    onError?: (error?: unknown) => void;
    onNonNativeShare?: () => void;
    onInteraction?: () => void;
    disabled?: boolean;
}

export default ShareController;
