import { CustomPalette } from "../types/mui";

export const darkColors: CustomPalette = {
    nrbyColors: {
        black: "#000",

        white: "#fff",

        tangerine: "#E53F5C",
        tangerine50: "#E53F5C80",

        faintGray: "#f6f6f6",

        darkGray: "#565656",
        darkGray50: "#56565680",

        gray: "#ededed",

        orange: "#EA4D47",
        orange50: "#EA4D4780",
        orange25: "#EA4D4740",

        purple: "#6051BD",
        purple50: "#6051BD80",
        purple25: "#6051BD40",

        green: "#78D48E",
        green50: "#78D48E80",
        green25: "#78D48E40",

        primaryColor: '#fff',
        primaryBgColor: "#000",
        invertedBgColor: "#FFF",
        invertedColor: '#000',

    },
};
