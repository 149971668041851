import {
    Backdrop,
    Box,
    CircularProgress,
    Grid,
    Stack,
    Typography,
} from "@mui/material";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MainLayout } from "../components/Layouts/MainLayout";
import ConfirmedOrderDetailsInfo from "../components/OrderConfimation/ConfirmedOrderDetailsInfo";
import EventInfo from "../components/Purchase/EventInfo";
import { EventPublicDetailsInfoModel } from "../data/Models/EventPublicDetailsInfoModel";
import { OrderEventTicketsResponse } from "../data/Models/Responses/OrderEventTicketsResponse";
import { getEventDetails } from "../data/Repositories/EventRepository";
import { confirmPaymentSuccessful } from "../data/Repositories/UserEventTicketRepository";
import { theme } from "../theme/theme";
import scrollWithOffset from "../types/scrollWithOffset";

const OrderConfirmationPage = () => {
    const navigate = useNavigate();
    const [pageParams, setPageParams] = useSearchParams();
    const [serverError, setServerError] = useState<string | undefined>();
    const [eventData, setEventData] = useState<
        EventPublicDetailsInfoModel | undefined
    >();

    const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
    const [orderConfirmationData, setOrderConfirmationData] =
        useState<OrderEventTicketsResponse>();
    const [orderEmail, setOrderEmail] = useState<string | undefined>();

    useLayoutEffect(() => {
        let orderId = pageParams.get("orderId");
        let email = pageParams.get("email");
        let firstName = pageParams.get("firstName");
        let lastName = pageParams.get("lastName");
        let phoneNumber = pageParams.get("phone");

        setOrderEmail(email || undefined);
        if (!orderId) navigate("/notFound");

        if (!showLoadingIndicator && !eventData) {
            ConfirmPaymentSuccess(
                orderId!,
                email!,
                firstName!,
                lastName!,
                phoneNumber!
            );
        }
    }, []);

    const ConfirmPaymentSuccess = async (
        orderId: string,
        email: string,
        firstName: string,
        lastName: string,
        phoneNumber: string
    ) => {
        setShowLoadingIndicator(true);
        confirmPaymentSuccessful(
            orderId,
            email,
            firstName,
            lastName,
            phoneNumber
        ).then((response) => {
            if (response && response.data && response.data.success) {
                let eventId = response.data.eventId;
                getEventDetails(eventId).then((eventResponse) => {
                    if (
                        eventResponse &&
                        eventResponse.data &&
                        eventResponse.data.success
                    ) {
                        setServerError(undefined);
                        setEventData(eventResponse.data.event);
                        setOrderConfirmationData(response.data);
                        setShowLoadingIndicator(false);
                        scrollWithOffset("congratText");
                    } else navigate("/notFound");
                });
            } else {
                setServerError(response.data.errorMsg);
                setEventData(undefined);
                setShowLoadingIndicator(false);
                scrollWithOffset("mainContent");
            }
        });
    };

    return (
        <MainLayout>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={showLoadingIndicator && !eventData}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Box
                style={{ position: "relative", paddingBottom: "20px" }}
                id="mainContent"
            >
                <Grid container>
                    <Grid item xs>
                        <Stack
                            style={{
                                position: "relative",
                                paddingBottom: "20px",
                            }}
                            direction="column"
                        >
                            {eventData && (
                                <EventInfo
                                    event={eventData}
                                    briefDescription={true}
                                />
                            )}
                            {!eventData && (
                                <Grid item xs={12} textAlign="center">
                                    <CircularProgress color="secondary" />
                                </Grid>
                            )}
                            <div id="congratText">
                                {!serverError && !showLoadingIndicator && (
                                    <ConfirmedOrderDetailsInfo
                                        event={eventData}
                                        order={orderConfirmationData}
                                        email={orderEmail!}
                                    />
                                )}

                                {showLoadingIndicator && eventData && (
                                    <Grid
                                        item
                                        xs={12}
                                        textAlign="center"
                                        mt={2}
                                    >
                                        <CircularProgress color="primary" />
                                    </Grid>
                                )}
                                {serverError && (
                                    <Box style={{}}>
                                        <Typography
                                            variant="header02"
                                            style={{
                                                color: theme.palette.nrbyColors
                                                    .tangerine,
                                                textAlign: "center",
                                                paddingTop: "40px",
                                            }}
                                        >
                                            {serverError}
                                        </Typography>
                                    </Box>
                                )}
                            </div>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </MainLayout>
    );
};

export default OrderConfirmationPage;
